import './TiktokMemeDetailsPage.scss';
import { useTranslation } from 'react-i18next';
import { assets } from '../../../assets/assets';
import { app } from '../../../data/Controllers/AppController';
import { useEffect, useState } from 'react';
import { UIEvents } from '../../../data/Controllers/UIController/UITypes';
import { Page } from '../Page';
import { MemesEvents } from '../../../data/Controllers/Memes/MemesController';
import { useManyAppUpdates } from '../../../data/hooks';
import { MemeDetailsTabs } from './MemeDetailsTabs/MemeDetailsTabs';
import { displayFormattedMarketCapValue } from '../../../replicant/utils/numbers';
import { HP } from '../../../replicant/lib/HighPrecision';
import { MemeImage } from '../../shared/MemeImage/MemeImage';
import { getDexGraduationPct } from '../../../data/memeUtils';

export const TiktokMemeDetailsPage = () => {
  const { t } = useTranslation();
  const { visible } = app.views.TiktokMemeDetailsPage;
  const { memes } = app;

  useManyAppUpdates({
    id: 'TiktokMemeDetailsPage',
    events: [
      { listener: app.views.TiktokMemeDetailsPage.attachEventListener() },
      {
        listener: app.ui.attachEventListener(UIEvents.OnBalanceUpdate),
        dep: visible,
      },
      {
        listener: app.memes.attachEventListener(
          MemesEvents.OnCurrentTokenUpdate,
        ),
        dep: visible,
      },
    ],
  });

  const [tabUpdateCounter, setTabUpdateCounter] = useState(0);
  const [isGraduated, setIsGraduated] = useState(false);
  const meme = memes.currentMeme.meme;

  // market cap
  const marketCapValue = HP(meme?.marketCapUsd).toNumber();
  const marketCapDisplayValue = displayFormattedMarketCapValue(marketCapValue);

  useEffect(() => {
    if (visible) {
      // If for some reason there's no data set, close itself
      if (!memes.currentMeme.meme) {
        console.error(
          '>>> TiktokMemeDetailsPage - No currentMeme.token. Closing myself',
        );
        app.ui.drawer.close();
        return;
      }

      // todo carles: update these analytics
      app.track('memeoffchainToken_detail_view', {
        memeoffchainToken_name: memes.currentMeme.meme.name,
        memeoffchainTokenID: memes.currentMeme.meme.id,
        current_point_price: memes.currentMeme.meme.pointPrice,
        current_token_price: memes.currentMeme.meme.tokenPrice,
        current_owned: memes.currentMeme.meme.creatorId === app.state.id,
        total_holders: memes.currentMeme.meme.holderCount,
        source: memes.market.filter,
      });
    }
  }, [visible, memes.currentMeme.meme?.id]);

  useEffect(() => {
    if (meme) {
      getDexGraduationPct(meme).then((pct) => setIsGraduated(pct >= 100));
    }
  }, [meme]);

  const onShare = () => {
    if (meme) {
      app.memes.sendGift(meme, 'meme_details');
    }
  };

  if (!meme) {
    return null;
  }

  const onTabChange = () => {
    setTabUpdateCounter(tabUpdateCounter + 1);
  };

  return (
    <Page id="meme-details" visible={visible}>
      <div className="meme-details-layout trading-details">
        <div className="meme-details-header">
          {/* --- TICKER + NAME */}
          <div className="meme-details-header-title">
            <div className="meme-details-header-title-label blue">
              ${meme.ticker}
            </div>
            <div className="meme-details-header-title-label">•</div>
            <div className="meme-details-header-title-label">{meme.name}</div>
          </div>

          {/* IMAGE */}
          <div className="meme-details-header-image-row">
            {/* creator image */}
            <MemeImage
              src={meme.image}
              size={56}
              badgeSrc={isGraduated ? assets.icon_graduation_rocket : undefined}
            />

            {/* share button */}
            <div className="btn meme-details-header-share" onClick={onShare}>
              <img src={assets.icon_sidebar_share} />
            </div>
          </div>

          {/* MARKET CAP */}
          <div className="meme-details-header-mcap">
            <div className="meme-details-header-mcap-value">
              {marketCapDisplayValue}
            </div>
            <div className="meme-details-header-mcap-label">
              {t('token_details_header_market_cap_text')}
            </div>
          </div>
        </div>

        {/* --- */}

        {/* tabs */}
        <MemeDetailsTabs onTabChange={onTabChange} />
      </div>
    </Page>
  );
};
