import { t } from 'i18next';
import { Trans, useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { assets } from '../../../../assets/assets';
import { app, useAppUpdates } from '../../../../data/Controllers/AppController';
import {
  copyToClipboard,
  getMemeIconReactImg,
  getOnchainTransactionLabel,
  getTransactionLabel,
  msToAgo,
} from '../../../../data/utils';
import {
  canClaimDailyTokens,
  canClaimGraduationPoints,
  getGraduationClaimAmount,
  getPendingTxs,
  getWalletHoldings,
  Meme,
} from '../../../../replicant/features/tradingMeme/tradingMeme.getters';
import {
  OffchainTx,
  OnchainTx,
  TxType,
} from '../../../../replicant/features/tradingMeme/tradingMeme.schema';
import {
  largeIntegerToLetter,
  largeNumberToLetter,
} from '../../../../replicant/utils/numbers';
import { ProgressBar } from '../../../shared/ProgressBar/ProgressBar';
import { FeedMemeFooter } from '../../TiktokPage/TiktokFeedMeme/FeedMemeFooter';
import { TradingChart } from '../../TradingTokenPage/Charts/TradingChart';
import { MemeDetailsSocialBtn } from './MemeDetailsSocialBtn';
import { HP } from '../../../../replicant/lib/HighPrecision';
import { resolveProfilePicture } from '../../../../replicant/features/game/game.getters';
import { CurrencyType } from '../../../../replicant/features/tradingMeme/types';
import { getCoinIconReactImg } from '../../../../data/utils';
import {
  isAndroid,
  isIOS,
  isMobileEmulatedByBrowser,
} from '../../../../data/device';
import { CreatorImage } from '../../../shared/CreatorImage/CreatorImage';
import {
  getDexGraduationPct,
  getOnchainMemeVolume,
} from '../../../../data/memeUtils';
import { onchainCurveConfig } from '../../../../replicant/features/tradingMeme/tradingMeme.ruleset';
import { HOUR_IN_MS } from '../../../../replicant/utils/time';
import { fromNano } from '@ton/core';
import { shortenString } from '../../../../replicant/utils/strings';
import { MemeImage } from '../../../shared/MemeImage/MemeImage';
import { UnconfirmedTx } from '../../../../replicant/features/game/player.schema';
import { MemesEvents } from '../../../../data/Controllers/Memes/MemesController';

// -----------------------------------------------------------------------------
// These methods are used in order to set special responsive dimensions
// for MemeDetailsPage only

function getGraphHeightRelativeToScreen() {
  const layoutOffset = -40;

  // possible fix for android extra 16px (?)
  const marginBottom = isAndroid() ? 0 : isMobileEmulatedByBrowser() ? -16 : 16;

  const isEmu = isMobileEmulatedByBrowser();
  const SE = isEmu ? 594 : 667;
  const Mini = isEmu ? 700 : 812;

  if (window.innerHeight <= SE) {
    console.warn('>>> SE');
    return 86 + marginBottom + layoutOffset; // SE
  }

  if (window.innerHeight <= Mini) {
    console.warn('>>> Mini');
    return 180 + marginBottom + layoutOffset; // Mini
  }

  console.warn('>>> XR');
  return 192 + marginBottom + layoutOffset; // XR
}

// ---
function getSectionHeightRelativeToScreen(margin: number) {
  const layoutOffset = 16;

  // telegram top safe area
  var style = getComputedStyle(document.body);
  const safeTop = style.getPropertyValue('--safe-top') || '0px';

  const top = 194; // hardcoded top position of meme-details-section
  const footer = 72; // height of bottom footer
  const extra = 0 + (isIOS() ? 18 : 0); // ios needs an extra offset of 18px for some reason
  const substract = top + footer + extra + margin + layoutOffset;

  const sectionHeight = `calc(100svh - ${safeTop} - ${substract}px)`;
  return sectionHeight;
}
// ---

// -----------------------------------------------------------------------------
// #region MarketStats

export const MemeDetailsMarketStats = () => {
  if (!app.memes.currentMeme.meme) {
    return null;
  }

  const { meme } = app.memes.currentMeme;

  const graphHeight = getGraphHeightRelativeToScreen();
  // console.warn('>>> graph height', window.innerHeight, '->', graphHeight);

  const sectionHeight = getSectionHeightRelativeToScreen(16);
  // console.warn('>>> sectionHeight', sectionHeight);

  return (
    <div
      className="meme-details-section marketStats"
      style={{ height: sectionHeight }}
    >
      <TradingChart meme={meme} height={graphHeight} />
      <ProgressArea meme={meme} />
    </div>
  );
};

const MemeValues = ({ meme }: { meme: Meme }) => {
  const { pointSupply, tokenSupply } = meme;

  return (
    <div className="item-mcap">
      <div className="item-mcap-section">
        <div className="item-mcap-value">
          {largeIntegerToLetter(HP(pointSupply).toNumber())}
        </div>
        <div className="item-mcap-label">
          {t('market_stats_tab_meme_points_owned', { ticker: meme.ticker })}
        </div>
      </div>
      <div className="item-mcap-separator"></div>
      <div className="item-mcap-section">
        <div className="item-mcap-value">
          {largeNumberToLetter(HP(tokenSupply).toNumber())}
        </div>
        <div className="item-mcap-label">
          {t('market_stats_tab_meme_token_owned', { ticker: meme.ticker })}
        </div>
      </div>
    </div>
  );
};

const ProgressArea = ({ meme }: { meme: Meme }) => {
  const { marketCap } = meme.overview;

  useAppUpdates({
    id: 'ProfileItem/Claim',
    listener: app.memes.trading.attachEventListener(
      MemesEvents.TokenClaimUpdate,
    ),
  });

  // -----------------------------------------------
  // dex progress calculation

  const isMemeGraduated = Boolean(meme.isGraduated || meme.dexContractAddress);

  const [dexProgress, setDexProgress] = useState(0);
  const [dexProgressColor, setDexProgressColor] = useState('white');
  const [isDexProgressCompleted, setIsDexProgressCompleted] = useState(false);

  const completDexProgression = () => {
    setDexProgress(100);
    setDexProgressColor('#4d90fe');
    setIsDexProgressCompleted(true);
  };

  useEffect(() => {
    if (isMemeGraduated) {
      completDexProgression();
    } else {
      getDexGraduationPct(meme).then((graduationPct) => {
        if (graduationPct >= 100) {
          completDexProgression();
        } else {
          setDexProgress(graduationPct);
        }
      });
    }
  }, [meme.id]);

  // -----------------------------------------------

  // progress completed state

  const onTapInfoDex = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    app.ui.drawer.show({ id: 'drawerInfoDex', hideClose: true });
  };

  const onClaim = () => {
    // claim tokens and display claim success drawer
    app.memes.trading.claimTokens(meme.id, meme.name, meme.ticker);
  };

  const offchainHoldings = app.state.trading.offchainTokens;
  const offchainHolding = offchainHoldings[meme.id];
  const isMemeGraduationComplete = meme.isGraduated;

  const getClaimableAmount = () => {
    if (canClaimGraduationPoints(app.now(), offchainHolding)) {
      const claimableAmount = offchainHolding?.claimableGradTokens;
      if (HP(claimableAmount).gt(0)) {
        return {
          claimableAmount,
          pointAmount: offchainHolding?.convertedGradPoints,
          claimFromPoints: true,
        };
      }
    }

    if (canClaimDailyTokens(app.now(), offchainHolding)) {
      const claimableAmount = offchainHolding?.claimableDailyTokens;
      if (HP(claimableAmount).gt(0)) {
        return {
          claimableAmount,
          pointAmount: offchainHolding?.convertedDailyPoints,
          claimFromPoints: true,
        };
      }
    }

    const graduationClaimAmount = app.memes.trading.getGraduationClaimAmount(
      isMemeGraduationComplete,
      meme.id,
    );

    return {
      claimableAmount: HP(graduationClaimAmount),
      pointAmount: '0',
      claimFromPoints: false,
    };
  };

  const { claimableAmount, pointAmount, claimFromPoints } =
    getClaimableAmount();
  if (HP(claimableAmount).gt(0)) {
    return (
      <div className={`item-progress-area claim-state`}>
        <MemeValues meme={meme} />

        {/* one line message */}
        <div className="item-progress-label grey w80 s13">
          {t('market_stats_tab_flavor_text_earn_points')}
        </div>

        {/* claim button */}
        <div className="btn btn-normal btn-claim" onClick={onClaim}>
          {claimFromPoints && (
            <Trans
              i18nKey="market_stats_tab_claim_for_points_button"
              components={{
                bold: <strong />,
                memeIcon: getMemeIconReactImg(meme.image),
              }}
              values={{
                ticker: meme.ticker,
                tokenAmount: largeNumberToLetter(
                  HP(claimableAmount).toNumber(),
                  4,
                ),
                pointAmount: largeIntegerToLetter(
                  HP(pointAmount).toNumber(),
                  4,
                ),
              }}
            />
          )}

          {!claimFromPoints && (
            <Trans
              i18nKey="market_stats_tab_claim_button"
              components={{
                bold: <strong />,
              }}
              values={{
                ticker: meme.ticker,
                tokenAmount: largeNumberToLetter(
                  HP(claimableAmount).toNumber(),
                  4,
                ),
              }}
            />
          )}
        </div>

        {/* buy/sell buttons */}
        <FeedMemeFooter onTapPurchaseButton={app.ui.onTapPurchaseButton} />
      </div>
    );
  }

  const graduateAmount = app.ton.getTonToUSDSync(
    onchainCurveConfig.tonGraduationAmount.toString(),
  );

  return (
    <div className="item-progress-area">
      <MemeValues meme={meme} />

      <div className="item-progress-title">
        <div className="item-progress-label grey s13">
          {t('market_stats_tab_progressbar_text')}
        </div>
        <div className="item-progress-label flavor">
          <Trans
            i18nKey={
              isDexProgressCompleted
                ? 'market_stats_tab_dex_listed'
                : 'market_stats_tab_dex_listing_text'
            }
            components={{
              bold: <strong />,
              em: <em />,
            }}
            values={{ dexProgressPercent: Math.round(dexProgress) }}
          />
        </div>
      </div>

      <ProgressBar
        color={dexProgressColor}
        height={10}
        progress={dexProgress}
      />

      <div className="item-progress-footer">
        {!isMemeGraduationComplete && (
          <div className="item-progress-label flavor mcap">
            <Trans
              i18nKey="market_stats_tab_flavor_text_graduates"
              components={{
                bold: <strong />,
                em: <em />,
              }}
              values={{
                graduateAmount: largeIntegerToLetter(graduateAmount),
              }}
            />
            <div className="btn info-dex" onClick={onTapInfoDex}>
              <img src={assets.icon_info_dex} />
            </div>
          </div>
        )}
      </div>

      {/* buy/sell buttons */}
      <FeedMemeFooter onTapPurchaseButton={app.ui.onTapPurchaseButton} />
    </div>
  );
};

// -----------------------------------------------------------------------------
// #region About

export const MemeDetailsAbout = () => {
  const meme = app.memes.currentMeme.meme;
  if (!meme) {
    return null;
  }

  const {
    description,
    telegramChannelLink,
    telegramChatLink,
    twitterLink,
    websiteLink,
  } = meme.description;

  const isCreator = app.playerId === meme.creatorId;

  const sectionHeight = getSectionHeightRelativeToScreen(0);

  // todo: we will need to restore and re-skin TradingEditLinksPage in carles/create branch
  const onEditInfo = () => {
    if (isCreator) {
      app.nav.goTo('TradingEditLinksPage');
    }
  };

  const now = app.now();
  const h24Ago = now - 24 * HOUR_IN_MS;
  const volumeTon = getOnchainMemeVolume(meme, h24Ago);
  const volumeUsd = app.ton.getTonToUSDSync(volumeTon.toString());
  const volumeUsdStr = largeNumberToLetter(volumeUsd, 4, '$', 2);

  const supplyBigInt = BigInt(meme.tokenSupply);
  const liquidityTon = fromNano(
    app.ton.getTokenToTON(supplyBigInt, supplyBigInt, 'sell'),
  );
  const liquidityUsd = app.ton.getTonToUSDSync(liquidityTon);
  const liquidityStr = largeNumberToLetter(liquidityUsd, 4, '$', 2);

  const buyCount = meme.buyCount;
  const sellCount = meme.sellCount;
  const txCount = buyCount + sellCount;

  const dexContractAddress = meme.dexContractAddress ?? '-';
  const jettonContractAddress = meme.jettonContractAddress ?? '-';

  const hasPoolAddress = !!meme.poolContractAddress;
  const poolContractAddress = meme.poolContractAddress ?? '';
  const displayPoolContractAddress = hasPoolAddress
    ? `https://app.ston.fi/pools/${shortenString(poolContractAddress, 4)}`
    : '-';

  const firstBuyer = meme.firstBuyer;

  const buyPct =
    txCount > 0
      ? `${largeNumberToLetter((100 * buyCount) / txCount, 3)}%`
      : '-';
  const sellPct =
    txCount > 0
      ? `${largeNumberToLetter((100 * sellCount) / txCount, 3)}%`
      : '-';

  return (
    <div
      className="meme-details-section about"
      style={{ height: sectionHeight }}
    >
      <div className="about-section-scroller">
        {/* description */}
        <div className="about-section">
          {/* title + edit info */}
          <div
            className="btn about-section edit-info-container"
            onClick={onEditInfo}
          >
            <div className="about-section-title">
              {t('about_tab_user_description_title')}
            </div>
            {isCreator && (
              <div className="edit-info">
                <img src={assets.pencil} />
                <div className="about-section-title">
                  {t('about_tab_socials_edit_info')}
                </div>
              </div>
            )}
          </div>
          <div className="about-section-text">{description}</div>
        </div>

        {/* social */}
        <div className="about-section">
          <div className="about-section-title">
            {t('about_tab_socials_title')}
          </div>

          <div className="about-social-buttons">
            <MemeDetailsSocialBtn
              className=""
              icon={assets.paperplane}
              title={t('trading_token_tabs_content_channel')}
              url={telegramChannelLink}
            />
            <MemeDetailsSocialBtn
              className=""
              icon={assets.paperplane}
              title={t('trading_token_tabs_content_chat')}
              url={telegramChatLink}
            />
            <MemeDetailsSocialBtn
              className=""
              icon={assets.twitter}
              title={t('trading_token_tabs_content_twitter')}
              url={twitterLink}
            />
            <MemeDetailsSocialBtn
              className=""
              icon={assets.website}
              title={t('trading_token_tabs_content_website')}
              url={websiteLink}
            />
          </div>
        </div>

        {/* statistics */}
        <div className="about-section">
          <div className="about-section-title">
            {t('about_tab_statistics_title')}
          </div>
          <StatRow
            icon={assets.stat_volume}
            title={t('about_tab_list_24hr_volume')}
            value={volumeUsdStr}
          />
          <StatRow
            icon={assets.stat_liq}
            title={t('about_tab_list_liquidity')}
            value={liquidityStr}
          />
          <StatRow
            icon={assets.stat_txns}
            title={t('about_tab_list_transactions')}
            value={largeIntegerToLetter(txCount, 3)}
          />
          <StatRow
            icon={assets.stat_contract}
            title={t('about_tab_list_pre_dex_contract')}
            value={shortenString(jettonContractAddress, 4)}
            copyValue={jettonContractAddress}
          />
          <StatRow
            icon={assets.stat_contract}
            title={t('about_tab_list_dex_contract')}
            value={shortenString(dexContractAddress, 4)}
            copyValue={dexContractAddress.length > 1 ? dexContractAddress : ''}
          />
          <StatRow
            icon={assets.stats_poolUrl}
            title={t('about_tab_list_pool_url')}
            value={displayPoolContractAddress}
            copyValue={hasPoolAddress ? poolContractAddress : ''}
          />
          <StatRow
            icon={assets.stat_deployer}
            title={t('about_tab_list_deployer')}
            value={firstBuyer.userName ?? '-'}
            auth={
              firstBuyer.userId
                ? resolveProfilePicture(firstBuyer.userId, firstBuyer.userImage)
                : ''
            }
          />
          <StatRow
            icon={assets.stat_buys}
            title={t('about_tab_list_buys')}
            value={buyPct}
          />
          <StatRow
            icon={assets.stat_sells}
            title={t('about_tab_list_sells')}
            value={sellPct}
          />
          <StatRow
            icon={assets.stat_holders}
            title={t('about_tab_list_holders')}
            value={meme.holderCount.toString()}
          />
        </div>
      </div>
    </div>
  );
};

interface StatRowProps {
  icon: string;
  title: string;
  value: string;
  auth?: string;
  copyValue?: string; // if passed, when tapping on this item we will copy the passed value toclipboard
}

const StatRow = ({ icon, title, value, auth, copyValue }: StatRowProps) => {
  if (!app.memes.currentMeme.meme) {
    return null;
  }

  const { t } = useTranslation();

  const [showSuccess, setShowSuccess] = useState(false);

  const onCopyLink = () => {
    if (!copyValue) return;

    copyToClipboard(copyValue);

    // diplay 'copied!' tooltip for a second
    setTimeout(() => {
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 900);
    }, 250);
  };

  return (
    <div className="about-stats-row">
      <div className="about-stats-row-content">
        <img src={icon} />
        <div className="label grey">{title}</div>
      </div>
      <div className="about-stats-row-content" onClick={onCopyLink}>
        {auth && <CreatorImage src={auth} size={16} />}
        <div className={`label ${copyValue ? 'underline' : ''}`}>{value}</div>
      </div>

      {/* copied success message */}
      {showSuccess && (
        <div className="copy-success">
          <div className="copy-success-icon">
            <img src={assets.copy_success} />
          </div>
          <div className="copy-success-text">{t('mfa_copied')}</div>
        </div>
      )}
    </div>
  );
};

// -----------------------------------------------------------------------------
// #region Transactions

// -------------------------------------------
// Transaction List

export const MemeDetailsTransactions = () => {
  if (!app.memes.currentMeme.meme) {
    return null;
  }

  const { meme } = app.memes.currentMeme;

  const sectionHeight = getSectionHeightRelativeToScreen(0);

  const [currencyType, setCurrencyType] = useState<CurrencyType>('tokens');
  const onCurrencyFilterClick = (tab: CurrencyType) => {
    setCurrencyType(tab);
  };

  const isOffchain = currencyType === 'points';

  const [pendingTxs, setPendingTxs] = useState(
    isOffchain ? [] : getPendingTxs(app.state, meme.id),
  );

  useEffect(() => {
    if (isOffchain) {
      setPendingTxs([]);
      return;
    }

    setPendingTxs(getPendingTxs(app.state, meme.id));

    const intervalId = setInterval(() => {
      const txs = getPendingTxs(app.state, meme.id);
      setPendingTxs(txs);

      if (txs.length === 0) {
        clearInterval(intervalId);
      }
      // poll every 1 second
    }, 1000);

    // cleanup on component unmount
    return () => clearInterval(intervalId);
  }, [isOffchain, meme.id, pendingTxs.length]);

  return (
    <div
      className="meme-details-section transactions"
      style={{ height: sectionHeight }}
    >
      <TransactionCurrencyFilter onTapCurrencyToggle={onCurrencyFilterClick} />

      <div className="meme-details-transactions-list">
        {pendingTxs.length > 0 &&
          pendingTxs.map((pendingTx, index) => {
            return (
              <PendingTxItem key={pendingTx.txHash} pendingTx={pendingTx} />
            );
          })}
      </div>

      <div className="meme-details-transactions-list">
        {isOffchain
          ? meme.offchainTxs
              .slice()
              .reverse()
              .map((tx, i) => (
                <OffchainTransactionItem
                  key={'tx_' + tx.createdAt + `_${i}`}
                  tx={tx}
                />
              ))
          : meme.onchainTxs
              .slice()
              .reverse()
              .filter(
                (tx) =>
                  !tx.txType.includes('claim') && !tx.txType.includes('deploy'),
              )
              .map((tx, i) => (
                <OnchainTransactionItem
                  key={'tx_' + tx.createdAt + `_${i}`}
                  tx={tx}
                />
              ))}
      </div>
    </div>
  );
};

const TransactionCurrencyFilter = ({
  onTapCurrencyToggle,
}: {
  onTapCurrencyToggle: (type: CurrencyType) => void;
}) => {
  const { meme } = app.memes.currentMeme;

  const [currencyType, setCurrencyType] = useState<CurrencyType>('tokens');

  const onTap = (type: CurrencyType) => {
    setCurrencyType(type);
    onTapCurrencyToggle(type);
  };

  return (
    <div className="transactions-currency-filter">
      {/* filter title */}
      <div className="transactions-currency-filter-title">
        {t('transaction_tab_filter_transaction_title')}
      </div>

      {/* filter toggle buttons */}
      <div className="transactions-currency-toggle-buttons">
        {/* left - points */}
        <div
          className={`btn transactions-currency-toggle-button left ${
            currencyType === 'points' ? 'selected' : ''
          }`}
          onClick={() => onTap('points')}
        >
          {/* <img src={assets.tab_icon_coin} /> */}
          <MemeImage src={meme?.image || ''} size={16} />
          <div className="transactions-currency-toggle-label">
            {t('transaction_tab_filter_button_points')}
          </div>
        </div>
        {/* right - tokens */}
        <div
          className={`btn transactions-currency-toggle-button right ${
            currencyType === 'tokens' ? 'selected' : ''
          }`}
          onClick={() => onTap('tokens')}
        >
          <img src={assets.tab_icon_token} />
          <div className="transactions-currency-toggle-label">
            {t('transaction_tab_filter_button_tokens')}
          </div>
        </div>
      </div>
    </div>
  );
};

// -------------------------------------------
// Transaction Item

interface PropsOffchainTransactionItem {
  tx: OffchainTx;
  onClick?: (userId: string) => void;
}

// -----------------------------------------------------------------------------
// #region Offchain Tr

export const OffchainTransactionItem = ({
  tx,
  onClick,
}: PropsOffchainTransactionItem) => {
  if (!app.memes.currentMeme.meme) {
    return null;
  }

  const pointAmount = tx.pointAmount;
  const amountStr = largeIntegerToLetter(HP(pointAmount).toNumber(), 4);

  const txType = tx.txType;
  const txPrice = largeIntegerToLetter(HP(tx.currencyAmount).toNumber());
  const timeAgo = app.now() - tx.createdAt;
  const userName = tx.userName;

  // const txCurrency = tx.currency; // todo: this seems to be gone now from tx object

  const userPic = resolveProfilePicture(tx.userId, tx.userImage);

  const tickerName = app.memes.currentMeme.meme.ticker;
  const memeImage = app.memes.currentMeme.meme.image;

  const goToProfile = () => {
    app.nav.goToProfile(tx.userId);
    onClick && onClick(tx.userId);
  };

  const txLabel = getTransactionLabel(tx);

  const leftIsCoins = txLabel === 'BUY' || txLabel === 'GIFT';
  const leftIsPoints = txLabel === 'SELL';
  const leftIsNone = txLabel === 'EARNED';

  const rightIsPoints = txLabel !== 'SELL';
  const rightIsCoins = txLabel === 'SELL';

  return (
    <div className="meme-details-transaction-item" onClick={goToProfile}>
      <div className="transaction-left">
        <div className="image">
          <img src={userPic} />
        </div>

        <div className="info">
          <div className="name">{userName}</div>
          <div className="bottom">
            <div className={`type ${txType?.toLowerCase()}`}>{txLabel}</div>
            {leftIsCoins && (
              <>
                <div className="icon">
                  <MemeImage src={assets.coin} size={13} />
                </div>
                <div className="value">{txPrice} COINS</div>
              </>
            )}
            {leftIsPoints && (
              <>
                <div className="icon">
                  <MemeImage src={memeImage} size={13} />
                </div>
                <div className="value">{txPrice} POINTS</div>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="transaction-right">
        {rightIsPoints && (
          <div className="value">
            <div className="value-label">{amountStr}</div>
            <div className="value-label">${tickerName}</div>
          </div>
        )}

        {rightIsCoins && (
          <div className="value">
            <div className="value-label">{amountStr}</div>
            <div className="value-label">COINS</div>
          </div>
        )}

        <div className="time">{msToAgo(timeAgo)}</div>
      </div>
    </div>
  );
};

// -----------------------------------------------------------------------------
// #region Onchain Tr

interface PropsOnchainTransactionItem {
  tx: OnchainTx;
  onClick?: (userId: string) => void;
}

export const OnchainTransactionItem = ({
  tx,
  onClick,
}: PropsOnchainTransactionItem) => {
  if (!app.memes.currentMeme.meme) {
    return null;
  }

  const tokenAmount = tx.tokenAmount;
  const amountStr = largeNumberToLetter(HP(tokenAmount).toNumber(), 4);

  const currencyAmount = HP(tx.currencyAmount);
  // const txCurrency = 'ton';
  const timeAgo = app.now() - tx.createdAt;
  const userName = tx.userName;

  const userPic = resolveProfilePicture(tx.userId, tx.userImage);
  const tickerName = app.memes.currentMeme.meme.ticker;

  const goToProfile = () => {
    app.nav.goToProfile(tx.userId);
    onClick && onClick(tx.userId);
  };

  const txType = tx.txType;
  const txLabel = getOnchainTransactionLabel(txType);
  const costSomething = currencyAmount.gt(0);

  return (
    <div className="meme-details-transaction-item" onClick={goToProfile}>
      <div className="transaction-left">
        <div className="image">
          <img src={userPic} />
        </div>

        <div className="info">
          <div className="name">{userName}</div>
          <div className="bottom">
            <div className={`type ${txLabel.toLowerCase()}`}>
              {txLabel.toUpperCase()}
            </div>
            {costSomething && (
              <div className="icon">
                <img src={assets.icon_ton} />
              </div>
            )}
            {costSomething && (
              <div className="value">
                {largeNumberToLetter(currencyAmount.toNumber())}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="transaction-right">
        <div className="value">
          <div className="value-label">{amountStr}</div>
          <div className="value-label">${tickerName}</div>
        </div>

        <div className="time">{msToAgo(timeAgo)}</div>
      </div>
    </div>
  );
};

// -----------------------------------------------------------------------------
// #region Pending Tr

interface PropsPendingTxItemOpts {
  pendingTx: UnconfirmedTx;
}

export const PendingTxItem = ({ pendingTx }: PropsPendingTxItemOpts) => {
  if (!app.memes.currentMeme.meme) {
    return null;
  }

  const getTxType = () => {
    const txType = pendingTx.txType;
    if (txType === 'buy' || txType === 'dexBuy') {
      return 'buy';
    }

    if (txType === 'sell' || txType === 'dexSell') {
      return 'sell';
    }

    if (txType === 'pointClaim' || txType === 'graduationClaim') {
      return 'claim';
    }
  };

  const txType = getTxType();
  if (!txType) {
    return;
  }

  const user = app.profile.current;
  if (!user) {
    return;
  }

  const timeAgo = app.now() - pendingTx.createdAt;
  const userName = app.state.profile.name;
  const userPic = user.picture;

  const goToProfile = () => {
    app.nav.goToProfile(user.id);
  };

  // const txLabel = getTransactionLabel(tx);

  return (
    <div className="meme-details-transaction-item" onClick={goToProfile}>
      <div className="transaction-left">
        <div className="image">
          <img src={userPic} />
        </div>

        <div className="info">
          <div className="name">{userName}</div>
          <div className="bottom">
            <div className={`type ${txType?.toLowerCase()}`}>
              {txType.toUpperCase()}
            </div>
          </div>
        </div>
      </div>

      <div className="transaction-right">
        <div className="value">
          <div className="value-label">{t('profile_tx_pending')}</div>
          <div className="pending-spinner"></div>
        </div>

        <div className="time">{msToAgo(timeAgo)}</div>
      </div>
    </div>
  );
};

// -----------------------------------------------------------------------------
// #region Holders

// interface PropsHolder {
//   data: TradingTokenHolder;
// }

// export const TradingTokenHoldersItem = ({ data }: PropsHolder) => {
//   const tickerName = app.memes.currentMeme.token?.ticker ?? '#';

//   const goToProfile = () => {
//     app.nav.goToProfile(data.userId);
//   };

//   const pointAmount = largeIntegerToLetter(
//     data.offchainTokenCount.toNumber(),
//     4,
//     false,
//   );

//   return (
//     <div className="meme-details-holder-item" onClick={goToProfile}>
//       <div className="holder-left">
//         <div className="image">
//           <img src={data.image} />
//         </div>

//         <div className="info">
//           <div className="top">
//             {data.type === 'creator' && (
//               <div className="creator">{`[${data.type}]`}</div>
//             )}
//             <div className="name">{data.name}</div>
//           </div>
//           <div className="offchainTokens">{`${pointAmount} ${tickerName}`}</div>
//         </div>
//       </div>

//       <div className="holder-right">
//         <div className="offchainTokens">~ {data.offchainTokenMarketShare}</div>
//       </div>
//     </div>
//   );
// };

// -----------------------------------------------------------------------------
// #region PriceArea

// interface MemeDetailsPriceAreaProps {
//   showChart?: boolean;
// }

// export const MemeDetailsPriceArea = ({
//   showChart,
// }: MemeDetailsPriceAreaProps) => {
//   useAppUpdates({
//     id: 'MemeDetailsPriceArea',
//     listener: app.memes.attachEventListener(MemesEvents.TradingOnTxUpdate),
//   });

//   useAppUpdates({
//     id: 'OnFooterExpanded',
//     listener: app.ui.attachEventListener(UIEvents.OnFooterExpanded),
//   });

//   const curvePrice = getCurvePrice(
//     highPrecision(app.memes.currentMeme.token?.supply ?? 0),
//   );
//   return (
//     <div
//       className="trading-price-area-container"
//       style={{ paddingBottom: app.ui.footerExpanded ? '270px' : '35px' }}
//     >
//       <div className="trading-price-area">
//         <div className="price-area-left">
//           <div className="current">
//             {/* todo(Cai): hook up timer */}
//             <div className="label">{t('trading_token_current_price')}</div>
//             <div className="price">
//               <div className="icon">
//                 <img src={assets.coin} />
//               </div>
//               <div className="value">
//                 {curvePrice ? displayPrice(curvePrice.toNumber(), 4) : '-'}
//               </div>
//               {/* <div className="percent green">{`${formatPrice(
//                 app.memes.currentMeme.token?.changePerHour || 0,
//               )}%`}</div> */}
//             </div>
//           </div>

//           {/* <div className="change">
//             <div className="label">{'1h Chg%'}</div>
//             <div className="value green">{`${formatPrice(
//               data?.changePerHour || 0,
//             )}%`}</div>
//           </div> */}
//         </div>

//         <div className="price-area-right">
//           <div
//             className="btn image"
//             onClick={() => {
//               app.ui.drawer.show({
//                 id: 'drawerTradingComingSoon',
//                 hideClose: true,
//               });
//             }}
//           >
//             <img src={assets.trading_price_area_icon} />
//           </div>
//         </div>
//       </div>

//       {/* trading chart */}
//       {showChart && app.memes.currentMeme.token && (
//         <TradingChart meme={app.memes.currentMeme.meme} height={250} />
//       )}
//     </div>
//   );
// };

// -----------------------------------------------------------------------------
