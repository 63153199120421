import { SB } from '@play-co/replicant';
import {
  createScheduledActions,
  extractScheduledActionHandlers,
} from '../../createScheduledActions';

import {
  finalizeGraduationClaim,
  finalizePointClaim,
  // savePortfolioPricePoint,
  runTxConfirmationAndReschedule,
} from './tradingMeme.modifiers';
import { onchainUserProfileSchema } from './tradingMeme.schema';
// import { longestPortofolioUpdateInterval } from './tradingMeme.ruleset';

const schema = {
  // savePortfolioPricePoint: SB.object({ delay: SB.number() }),
  runTxConfirmation: SB.unknown(),
  finalizePointClaim: SB.object({
    memeId: SB.string(),
    memeName: SB.string(),
    ticker: SB.string(),
    tokenAmount: SB.string(),
    success: SB.boolean(),
  }),
  finalizeGraduationClaim: SB.object({
    memeId: SB.string(),
    memeName: SB.string(),
    ticker: SB.string(),
    walletAddress: SB.string(),
    tokenAmount: SB.string(),
    success: SB.boolean(),
  }),
};

const actions = createScheduledActions(schema)({
  // savePortfolioPricePoint: async (state, { delay }: { delay: number }, api) => {
  //   await savePortfolioPricePoint(state, api);

  //   // doubling the delay of next price point
  //   delay *= 2;
  //   if (delay > longestPortofolioUpdateInterval) {
  //     // stop updating the portfolio
  //     return;
  //   }

  //   api.scheduledActions.schedule.savePortfolioPricePoint({
  //     args: { delay },
  //     notificationId: 'savePortfolioPricePoint',
  //     delayInMS: delay,
  //   });
  // },

  runTxConfirmation: async (state, _, api) => {
    runTxConfirmationAndReschedule(state, api);
  },

  finalizePointClaim: async (state, payload, api) => {
    finalizePointClaim(state, api, payload);
  },

  finalizeGraduationClaim: async (state, payload, api) => {
    finalizeGraduationClaim(state, api, payload);
  },
});

export const offchainMemeScheduledActions = {
  schema,
  actions: extractScheduledActionHandlers(actions),
};
