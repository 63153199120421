export const floorToSignificantDigits = (num: number, digits: number) => {
  if (num === 0) return 0;

  const isNegative = num < 0;
  const absNum = Math.abs(num);
  const d = Math.ceil(Math.log10(absNum));
  const power = d - digits;
  const magnitude = Math.pow(10, power);

  const shifted = Math.floor(absNum / magnitude);
  const result = shifted * magnitude;

  return isNegative ? -result : result;
};

export const formatPrice = (value: number | undefined, decimals?: number) => {
  if (value === undefined) return '';

  return value.toLocaleString('en-US', {
    minimumFractionDigits: decimals ?? 0,
    maximumFractionDigits: decimals ?? 8,
  });
};

export function numberToShortString(num: number) {
  if (num < 1000) {
    return num.toString();
  } else if (num < 1000000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'k';
  } else {
    return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
  }
}

const units: [number, string][] = [
  [1_000, 'K'],
  [1_000_000, 'M'],
  [1_000_000_000, 'B'],
  [1_000_000_000_000, 'T'],
  [1_000_000_000_000_000, 'Q'],
  [1_000_000_000_000_000_000, 'Qa'],
  [1_000_000_000_000_000_000_000, 'Sx'],
  [1_000_000_000_000_000_000_000_000, 'Sp'],
];

export const largeNumberToLetter = (
  value: number,
  significantDigits: number = 3,
  prefix?: string,
  maxDecimals?: number,
): string => {
  const negative = value < 0;
  if (negative) {
    value = -value;
  }

  if (value === 0) {
    return prefix ? `${prefix}0` : '0';
  }

  let letter = '';
  let magnitude = 1;

  for (const [unitValue, unitLetter] of units) {
    if (value < unitValue) {
      break;
    }
    letter = unitLetter;
    magnitude = unitValue;
  }

  const scaledValue = value / magnitude;

  let formattedResult;
  if (maxDecimals !== undefined && value < 1000 && !Number.isInteger(value)) {
    formattedResult = scaledValue.toFixed(maxDecimals);
  } else {
    const decimalPlaces =
      maxDecimals ??
      Math.max(0, significantDigits - Math.floor(Math.log10(scaledValue)) - 1);
    formattedResult = Number(scaledValue.toFixed(decimalPlaces)).toString();
  }

  if (prefix) {
    formattedResult = `${prefix}${formattedResult}`;
  }

  if (negative) {
    formattedResult = `-${formattedResult}`;
  }

  return `${formattedResult}${letter}`;
};

export const largeIntegerToLetter = (
  value: number,
  significantDigits?: number,
  prefix?: string,
): string => {
  return largeNumberToLetter(Math.round(value), significantDigits, prefix);
};

export function roundDecimals(value: number, significantDigits: number = 3) {
  if (value === 0) {
    return '0';
  }

  const absValue = Math.abs(value);
  const exponent = Math.floor(Math.log10(absValue)) - significantDigits;

  const decimalPlaces = Math.max(0, -exponent);
  return value.toFixed(decimalPlaces);
}

export const displayPrice = (
  price: number,
  significantDigits?: number,
): string => {
  return largeIntegerToLetter(price, significantDigits);
};

export const displayFormattedMarketCapValue = (value: number): string => {
  return value > 0 ? largeNumberToLetter(value, 4, '$', 2) : '$0.00';
};

export function clampScore(score: number) {
  return Math.max(Math.min(Math.round(score), Number.MAX_SAFE_INTEGER), 0);
}

export function ceiling(num: number, significance: number) {
  return Math.ceil(num / significance) * significance;
}

export function getPrettyDecimalsUpTo(num: number, decimals: number) {
  const pretty = num.toFixed(decimals);
  let zeros = 0;
  for (let i = pretty.length - 1; i >= 0; i--) {
    if (pretty[i] !== '0') {
      break;
    }
    zeros++;
  }
  return pretty.substring(0, pretty.length - zeros);
}

export const prettyDecimals = {
  strict4: (num: number) => num.toFixed(4),
  strict8: (num: number) => num.toFixed(8),
  upTo4: (num: number) => getPrettyDecimalsUpTo(num, 4),
  upTo8: (num: number) => getPrettyDecimalsUpTo(num, 8),
};

export function getRoi(investment: number, valuation: number) {
  if (Math.abs(investment) < 1e-7) {
    return 0;
  }

  return (100 * valuation) / investment - 100;
}

export function booleanRoll(oddsOfTrue: number) {
  return Math.random() < oddsOfTrue;
}

export function generateNonce() {
  return Math.random().toString(36).substring(2);
}
