import './DrawerMemeGiftBack.scss';
import { assets } from '../../../assets/assets';
import { app } from '../../../data/Controllers/AppController';
import { useTranslation } from 'react-i18next';
import { getBuyTxEstimate } from '../../../replicant/features/tradingMeme/tradingMeme.getters';
import { largeIntegerToLetter } from '../../../replicant/utils/numbers';
import { memeGiftRuleset } from '../../../replicant/features/tradingMeme/tradingMeme.ruleset';
import { useEffect } from 'react';

import { HP } from '../../../replicant/lib/HighPrecision';
import { GiftImage } from '../../shared/GiftImage/GiftImage';
import { MemeImage } from '../../shared/MemeImage/MemeImage';

export const DrawerMemeGiftBack = () => {
  const { t } = useTranslation();

  // note: everything feels cleaner here without confetti
  // useEffect(() => {
  //   app.ui.confetti.show(); // app.ui.confetti.setData({ duration: 5000 }).show();
  // }, []);

  const userMemeGift = app.memes.userMemeGift;

  // enable this to test this drawer locally with mockup data
  // const userMemeGift = {
  //   token: app.memes.currentMeme.meme,
  //   alreadyClaimed: false,
  //   consolation: 1600,
  //   expired: false,
  //   points: '2400000',
  //   senderId: 'aaa',
  //   subFeature: 'aaa',
  //   senderName: 'SENDERNAME',
  // };

  if (!userMemeGift) {
    return null;
  }

  const {
    // token,
    alreadyClaimed,
    consolation,
    expired,
    points,
    senderId,
    subFeature,
  } = userMemeGift;

  const meme = userMemeGift.token;

  if (!meme) {
    return null;
  }

  const giftPoints = getBuyTxEstimate({
    currentSupply: HP(meme.pointSupply),
    currencyAmount: HP(memeGiftRuleset.giftReward),
  });

  const onSendGift = async () => {
    await app.memes.sendGift(meme, 'meme_gift_back');

    app.ui.drawer.close();
  };

  const text = {
    title: t('user_meme_gift_received'),
    senderName: t('user_meme_gift_from', {
      friendName: userMemeGift.senderName,
    }),
    receiveReward: largeIntegerToLetter(HP(points).toNumber(), 3),
    sendReward: largeIntegerToLetter(giftPoints.toNumber(), 3),
  };

  if (alreadyClaimed || expired) {
    text.title = alreadyClaimed
      ? t('user_meme_gift_already_claimed')
      : t('user_meme_gift_expired');
    text.receiveReward = '';
    text.senderName = expired ? t('user_meme_gift_expired_subtitle') : '';
  }

  if (consolation > 0) {
    text.receiveReward = largeIntegerToLetter(HP(points).toNumber(), 3);
  }

  const gotSomething = consolation > 0 || HP(points).gt(0);

  return (
    <div className="drawer-details gift-back">
      <TrackOnReady
        event={gotSomething ? 'GiftClaimSuccess' : 'GiftFailure'}
        data={{
          memeId: meme.id,
          MemeTicker: meme.ticker,
          pointAmount: points,
          coinAmount: consolation.toString(),
          senderID: senderId,
          Page_location: 'reshareGift',
          subFeature,
        }}
      />
      <div className="drawer-layout gift-back">
        <div className="drawer-header gift-back-header">
          {/* meme image */}
          <div className="drawer-header-icon">
            <GiftImage src={meme.image} size={152} />
          </div>

          {/* title and description */}
          <div className="gift-back-titlesender">
            <div className="gift-back-title">{text.title}</div>
            {text.senderName && (
              <div className="gift-back-sender">{text.senderName}</div>
            )}
          </div>

          {/* meme info */}
          <div className="gift-back-meme-info">
            <div className="row">
              <MemeImage src={meme.image} size={24} />
              <div className="value">{text.receiveReward}</div>
              <div className="ticker">${meme.ticker}</div>
            </div>
            <div className="currency">Points</div>
          </div>

          {/* swap info */}
          <div className="gift-back-swap-info">
            <div className="swap-container">
              <div className="swap-label">{t('share_meme_gift_give')}</div>
              <div className="swap-value">{text.sendReward} Points</div>
            </div>
            <img src={assets.icon_swap} width={40} height={40} />
            <div className="swap-container">
              <div className="swap-label">{t('share_meme_gift_get')}</div>
              <div className="swap-value">{text.receiveReward} Points</div>
            </div>
          </div>
        </div>
      </div>

      {/* share button */}
      <div className="footer-buttons">
        <div className="btn btn-normal gift-back-button" onClick={onSendGift}>
          {t('generic_share')}
          {/* {gotSomething
            ? t('pup_gift_received_button')
            : t('pup_send_another_gift')} */}
        </div>
      </div>
    </div>
  );
};

interface TrackOpts {
  event: 'GiftClaimSuccess' | 'GiftFailure';
  data: {
    memeId?: string;
    MemeTicker?: string;
    pointAmount?: string;
    coinAmount?: string;
    senderID?: string;
    Page_location: 'ftueGift' | 'reshareGift' | 'sideBar';
    subFeature?: string;
  };
}

const TrackOnReady = (opts: TrackOpts) => {
  useEffect(() => {
    app.track(opts.event, opts.data);
  }, []);
  return null;
};
