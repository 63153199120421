import { useTranslation } from 'react-i18next';
import { app, useAppUpdates } from '../../../../data/Controllers/AppController';
import { getUIStateClassName } from '../../../../data/utils';
import { TradingSearchResult } from '../../../../replicant/features/tradingMeme/tradingMeme.properties';
import {
  formatPrice,
  largeIntegerToLetter,
  largeNumberToLetter,
} from '../../../../replicant/utils/numbers';
import { ProgressBarCircle } from '../../../shared/ProgressBarCircle/ProgressBarCircle';
import { ProfileTabV2 } from '../ProfilePage';
import './ProfileItem.scss';
import { FarmingSearchResult } from '../../../../data/Controllers/ProfileController';
import { TradingCategories } from '../../TradingPage/TradingPage';
import { MemeImage } from '../../../shared/MemeImage/MemeImage';
import { useEffect, useState } from 'react';
import { getDexGraduationPct } from '../../../../data/memeUtils';
import { HP } from '../../../../replicant/lib/HighPrecision';
import {
  canClaimDailyTokens,
  canClaimGraduationPoints,
  getConvertedPoints,
} from '../../../../replicant/features/tradingMeme/tradingMeme.getters';
import { assets } from '../../../../assets/assets';
import { MemesEvents } from '../../../../data/Controllers/Memes/MemesController';
import { UIEvents } from '../../../../data/Controllers/UIController/UITypes';

interface Props {
  category: ProfileTabV2;
  meme: TradingSearchResult | FarmingSearchResult;
}

// =================================================================
// #region Item
const tabCategory = {
  Created: 'Created',
  Points: 'Farming',
  Wallet: 'Owned',
} as unknown as Record<ProfileTabV2, TradingCategories>;

export const ProfileItem = ({ category, meme }: Props) => {
  const { t } = useTranslation();

  const { id: memeId, profile, dexListingTime } = meme;

  const [isGraduated, setIsGraduated] = useState(false);
  useEffect(() => {
    if (memeId) {
      app.memes.getMeme(memeId).then((meme) => {
        if (meme) {
          getDexGraduationPct(meme).then((graduationPct) => {
            setIsGraduated(graduationPct >= 100);
          });
        }
      });
    }
  }, [memeId]);

  const tickerName = profile.ticker;

  const onShowOffchainTokenDetails = async () => {
    const tradingCategory = tabCategory[category];
    app.nav.goToTiktokFeed(memeId, tradingCategory, 'profile-list');
  };

  if (!app.views.ProfilePage.visible) {
    return null;
  }

  // =================================================================
  // #region Base

  return (
    <div
      className={`btn item-profile ${getUIStateClassName(
        app.ui.getCardState(memeId),
      )}`}
      onClick={onShowOffchainTokenDetails}
    >
      <div className="item-profile-container">
        <MemeImage
          src={profile.image}
          size={48}
          badgeSrc={isGraduated ? assets.icon_graduation_rocket : undefined}
        />

        {category === 'Points' && (
          <ProfileItemContentPoints
            memeId={memeId}
            name={profile.name}
            tickerName={tickerName}
          />
        )}

        {category !== 'Points' && (
          <ProfileItemContentTokens
            memeId={memeId}
            isGraduated={dexListingTime > 0}
            name={profile.name}
            tickerName={tickerName}
          />
        )}
      </div>
    </div>
  );
};

interface ProfileItemData {
  memeId: string;
  name: string;
  isGraduated: boolean;
  tickerName: string;
}

// =================================================================
// #region Normal

const ProfileItemContentTokens = ({
  memeId,
  name,
  isGraduated,
  tickerName,
}: ProfileItemData) => {
  const { t } = useTranslation();

  useAppUpdates({
    id: 'ProfileItemContentTokens',
    listener: app.ui.attachEventListener(UIEvents.OnClaimingUpdate),
  });

  // retrieving data from profile controller
  const holdingStats = app.profile.getOnchainMemeHoldingStats(memeId);

  const tokenAmount = holdingStats?.tokenAmount || 0;
  const valuation = holdingStats?.valuation || 0;
  const roi = holdingStats?.roi || 0;
  const roiAbsoluteValue = valuation - valuation / (1 + roi);

  const color = roi === 0 ? 'white' : roi > 0 ? 'green' : 'red';
  const sign = roi === 0 ? '' : roi > 0 ? '+' : '-';

  const graduationClaimAmount = app.memes.trading.getGraduationClaimAmount(
    isGraduated,
    memeId,
  );
  const canClaim =
    app.profile.isSelf() &&
    HP(graduationClaimAmount).gt(0) &&
    !app.ui.getIsClaiming(memeId);

  const onTapClaim = async (event: any) => {
    // prevent bubbling to item
    event.preventDefault();
    event.stopPropagation();

    app.memes.trading.claimTokens(memeId, name, tickerName);
  };

  return (
    <div className="item-profile-content">
      {/* left info */}
      <div className="left">
        <div className="name-container">{name}</div>

        <div className="owned-container">
          {largeIntegerToLetter(tokenAmount, 3)} ${tickerName}
        </div>
      </div>

      <div className="right">
        {/* claim button  - 'Claim' caption */}
        {canClaim && (
          <div className={'btn btn-normal button-claim'} onClick={onTapClaim}>
            {t('tut_season_claim_reward')}
          </div>
        )}
        {!canClaim && (
          <div>
            {/* valuation */}
            <div className="price-container">
              {largeNumberToLetter(valuation, 3, '$', 2)}
            </div>

            {/* change - (make sure to remove minus sign after $ when value is negative) */}
            <div className={`change-container ${color}`}>
              {sign}${formatPrice(roiAbsoluteValue, 2).replace('-', '')}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

interface ProfileItemPointsData {
  memeId: string;
  name: string;
  tickerName: string;
}

// =================================================================
// #region Points

const ProfileItemContentPoints = ({
  memeId,
  name,
  tickerName,
}: ProfileItemPointsData) => {
  const { t } = useTranslation();

  useAppUpdates({
    id: 'ProfileItemPoints/Claim',
    listener: app.memes.trading.attachEventListener(
      MemesEvents.TokenClaimUpdate,
    ),
  });

  const onTapClaim = async (event: any) => {
    // prevent bubbling to item
    event.preventDefault();
    event.stopPropagation();

    app.memes.trading.claimTokens(memeId, name, tickerName);
  };

  // -----------------------------------------------
  // dex progress calculation

  const [dexProgress, setDexProgress] = useState(0);
  // const [completed, setCompleted] = useState(false);

  useEffect(() => {
    if (memeId) {
      app.memes.getMeme(memeId, 'fetch').then((meme) => {
        if (!meme) return;

        getDexGraduationPct(meme).then((graduationPct) => {
          setDexProgress(graduationPct);
          // setCompleted(graduationPct >= 100);
        });
      });
    }
  }, [memeId]);

  // retrieving data from profile controller
  const memeHolding = app.profile.getOffchainMemeHolding(memeId);
  const pointAmount = HP(memeHolding?.pointAmount)
    .add(getConvertedPoints(memeHolding))
    .toNumber();

  const ownHolding = app.state.trading.offchainTokens[memeId];
  const canClaimTokens =
    app.profile.isSelf() &&
    ownHolding &&
    (canClaimDailyTokens(app.now(), ownHolding) ||
      canClaimGraduationPoints(app.now(), ownHolding));

  // uncomment to debug claimable tokens
  // if (name.includes('Dex Dox')) {
  //   console.error('\n\n\nMeme:', name);
  //   console.error('ownHolding', ownHolding);
  //   console.error('ownHolding?.pointAmount', ownHolding?.pointAmount);
  //   console.error('canClaimDailyTokens(app.now(), ownHolding)', canClaimDailyTokens(app.now(), ownHolding));
  //   console.error('canClaimGraduationPoints(app.now(), ownHolding))', canClaimGraduationPoints(app.now(), ownHolding));
  //   console.error('ownHolding?.claimableGradTokens', ownHolding?.claimableGradTokens);
  //   console.error('ownHolding?.claimableDailyTokens', ownHolding?.claimableDailyTokens);
  //   console.error('ownHolding?.convertedDailyPoints', ownHolding?.convertedDailyPoints);
  //   console.error('ownHolding?.convertedGradPoints', ownHolding?.convertedGradPoints);
  //   console.error(
  //     'getConvertedPoints(ownHolding)',
  //     getConvertedPoints(ownHolding).toString(),
  //   );
  //   console.error('canClaimTokens', canClaimTokens);
  // }

  // -----------------------------------------------

  return (
    <div className="item-profile-content">
      <div className="left">
        {/* token name */}
        <div className="name-container">{name}</div>

        {/* token market cap */}
        <div className="owned-container">
          {largeIntegerToLetter(pointAmount, 3)}{' '}
          {t('trading_transaction_points')}
        </div>
      </div>

      <div className="right">
        {/* claim button  - 'Claim' caption */}
        {canClaimTokens && (
          <div
            className={`btn btn-normal button-claim ${true ? '' : 'disabled'}`}
            onClick={onTapClaim}
          >
            {t('tut_season_claim_reward')}
          </div>
        )}

        {!canClaimTokens && (
          // circular progress bar
          <div className="progress-points">
            <ProgressBarCircle
              progress={dexProgress}
              completedIcon={assets.icon_graduation_rocket}
            />
          </div>
        )}
      </div>
    </div>
  );
};
