import { Address } from '@ton/core';
import { MIN_IN_MS } from '../../replicant/utils/time';

export const WALLET_TIMEOUT_ERROR = 'WALLET_TIMEOUT_ERROR';
export const POOL_INTERVAL = 500;
export const WAIT_WALLET_ADDRESS_TIMEOUT = 4; //2s
export const PROXY_TON_ADDRESS =
  'EQBnGWMCf3-FZZq1W4IWcWiGAc3PHuZ0_H-7sad2oY00o83S';
export const DEMO_DEX_TOKEN_ADDRESS =
  'EQApfjfy0Uuy-ixrFMrVWSEL4WMOsGDcTsha9i5lZUR5wMS2';
export const STONFI_ROUTER_ADDRESS = Address.parse(
  'EQDx--jUU9PUtHltPYZX7wdzIi0SPY3KZ8nvOs0iZvQJd6Ql',
);
export const STONFI_PTON_ADDRESS = Address.parse(
  'EQBnGWMCf3-FZZq1W4IWcWiGAc3PHuZ0_H-7sad2oY00o83S',
);

export const WALLET_TIMEOUT = MIN_IN_MS * 1.5;
