import './GiftImage.scss';
import { assets } from '../../../assets/assets';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';

interface GiftImageProps {
  src: string;
  size: number;
  useBadge?: boolean;
}

export const GiftImage = ({ src, size, useBadge }: GiftImageProps) => {
  const { t } = useTranslation();

  if (useBadge === undefined) {
    useBadge = true;
  }

  const imgRef = useRef<any>();
  const [verticalAspectRatio, setVerticalAspectRatio] = useState(false);

  useEffect(() => {
    if (src) {
      if (imgRef.current) {
        const isVertical =
          imgRef.current.naturalWidth < imgRef.current.naturalHeight;
        setVerticalAspectRatio(isVertical);
      }
    }
  }, [src]);

  const innerMargin = size * 0.065;

  return (
    <div
      className="gift-image-circle"
      style={{ width: `${size}px`, height: `${size}px`, position: 'relative' }}
    >
      <img src={assets.gift_circle_frame} />

      <div
        className="gift-image-circle-inner"
        style={{
          width: `${size - innerMargin}px`,
          height: `${size - innerMargin}px`,
          position: 'relative',
        }}
      >
        <div className="gift-image">
          <img
            ref={imgRef}
            src={src || assets.telegram_star}
            style={{
              width: verticalAspectRatio ? '100%' : 'unset',
              height: verticalAspectRatio ? 'unset' : '100%',
            }}
          />
        </div>
        {useBadge && (
          <div className="badge">
            <img src={assets.gift_circle_icon} alt="Badge" />
          </div>
        )}
      </div>
    </div>
  );
};

// ===================================================================================
