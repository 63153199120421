import { useTranslation } from 'react-i18next';
import { app } from '../../../../../data/Controllers/AppController';
import { getPct } from '../../../../../data/utils';
import { CurrencyType } from '../../../../../replicant/features/tradingMeme/types';
import { assets } from '../../../../../assets/assets';
import { useState } from 'react';

// note: this is used also from DrawerTradingTransactionConfirm
export const TradingTokenFooterFeeMessage = ({
  currencyType,
  tx,
}: {
  currencyType?: CurrencyType;
  tx: typeof app.memes.trading.tx;
}) => {
  const { t } = useTranslation();

  // get the fee pct relative to 100% with no decimal places
  // const fee = app.memes.trading.getTxFee();
  // const feePct = getPct(100 * fee.feePercentage, 100, 0);

  if (!currencyType) currencyType = 'points';

  const onTapInfo = () => {
    setShowTooltip(true);
  };

  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className="trading-token-footer-fee-message-container">
      <div className="btn trading-token-footer-fee-message" onClick={onTapInfo}>
        <div className="label icon">
          <img src={assets.icon_info_dex} />
        </div>
        <div className="label blue">
          {t('trading_token_footer_transaction_send')}
        </div>
        <div className="label grey">{tx?.finalTonAmount} TON</div>
      </div>
      {showTooltip && (
        <TooltipFees
          slippage={tx?.slippage}
          fee={tx?.fee}
          onClose={() => setShowTooltip(false)}
        />
      )}
    </div>
  );

  // return (
  //   <div className="trading-token-footer-fee-message-container">
  //     <div className="percent">~{feePct}</div>
  //     <div className="transaction-fee">
  //       {t('trading_token_footer_transaction_fee')}
  //     </div>
  //   </div>
  // );
};

const TooltipFees = ({
  slippage,
  fee = '0.0000',
  onClose,
}: {
  onClose: () => void;
  slippage?: string;
  fee?: string;
}) => {
  const { t } = useTranslation();

  return (
    <div className="tooltip-fees">
      <div
        className="btn tooltip-fees-close"
        onClick={() => onClose && onClose()}
      >
        <img src={assets.button_x_dark} width={20} height={20} />
      </div>

      <div className="tooltip-tail" />

      <div className="tooltip-fees-content">
        <div className="tooltip-fees-label">
          {t('trading_token_footer_transaction_network')}: {fee} TON
        </div>
        {slippage && (
          <div className="tooltip-fees-label">
            {t('trading_token_footer_transaction_wallet')}: {slippage} TON
          </div>
        )}
        {/* <div className="tooltip-fees-label">
          {t('trading_token_footer_transaction_platform')}: 0.0000 TON
        </div> */}
      </div>
    </div>
  );
};
