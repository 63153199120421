import './DrawerMigration.scss';
import { useTranslation } from 'react-i18next';
import { assets } from '../../../assets/assets';
import { app } from '../../../data/Controllers/AppController';
import { useEffect, useState } from 'react';
import { motion } from 'motion/react';
import { t } from 'i18next';
import { formatPrice } from '../../../replicant/utils/numbers';

// ===================================================================================
// #region FTUE Share

export const DrawerMigration = () => {
  const { t } = useTranslation();

  // should we display progress bars or final screen?
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isLoading) {
      app.track('LoadAccountMigrationCheck', app.state.season2Compensations);
    } else {
      app.track('FinishAccountMigrationCheck', app.state.season2Compensations);
    }
  }, [isLoading]);

  if (isLoading) {
    return <LoadingScreen onComplete={() => setIsLoading(false)} />;
  } else {
    return <ClaimScreen />;
  }
};

// ===================================================================================
// #region Loading

const LoadingScreen = ({ onComplete }: { onComplete: () => void }) => {
  const duration = 0.7;
  // const delay = 3;

  // const m = 0.35;

  const [isComplete, setIsComplete] = useState(false);

  return (
    <div className="drawer-migration">
      <motion.div
        className="drawer-migration-loading-container"
        initial={{ opacity: 0, transform: 'translateY(40px)' }}
        animate={{
          opacity: isComplete ? 0 : 1,
          transform: isComplete ? 'translateY(-40px)' : 'translateY(0px)',
        }}
        transition={{ duration: 0.5, delay: 0.25 }}

        // onTransitionEnd={(hello) => {S
        //   console.warn('>>> onTransitionEnd', hello, isComplete);
        //   if (isComplete) onComplete();
        // }}
      >
        {/* <div className="drawer-migration-loading-container"> */}
        <div className="drawer-migration-loading">
          <div className="drawer-migration-checking">
            {t('migrate_login_checking')}
          </div>
          <div className="drawer-migration-loading-bars">
            <ProgressBar
              caption={t('migrate_login_bar1')}
              color={'yellow'}
              duration={duration}
              delay={0.5}
            />
            <ProgressBar
              caption={t('migrate_login_bar2')}
              color={'yellow'}
              duration={duration}
              delay={0.5 + duration * 1 - 0.2}
            />
            <ProgressBar
              caption={t('migrate_login_bar3')}
              color={'yellow'}
              duration={duration}
              delay={0.5 + duration * 2 - 0.4}
            />
            <ProgressBar
              caption={t('migrate_login_bar4')}
              color={'yellow'}
              duration={duration * 2.5}
              delay={0.5 + duration * 2.5 - 0.4}
              onComplete={() => {
                setTimeout(() => {
                  setIsComplete(true);
                  setTimeout(() => onComplete(), 350);
                }, 350);
              }}
            />
          </div>
        </div>
        {/* </div> */}
      </motion.div>
    </div>
  );
};

// ===================================================================================
// #region ClaimScreen

const ClaimScreen = () => {
  const [confettiShown, setConfettiShown] = useState(false);
  const [claimEnabled, setClaimEnabled] = useState(false);

  useEffect(() => {
    if (!confettiShown) {
      app.track('S3MigrationExplainerLoad', app.state.season2Compensations);
      app.ui.confetti.show();
      setConfettiShown(true);
    }

    const timer = setTimeout(() => {
      setClaimEnabled(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, [confettiShown]);

  const onClaim = () => {
    app.track('S3MigrationClaim', app.state.season2Compensations);
    app.ui.confetti.hide();
    app.ui.drawer.close();
  };

  return (
    <div
      className="drawer-migration-claim-container"
      style={{
        backgroundImage: `url(${assets.gradient_red})`,
      }}
    >
      <img
        src={assets.airdrop}
        alt="airdrop"
        className="drawer-migration-claim-image"
      />

      <div className="drawer-migration-claim-section">
        <div className="drawer-migration-claim-heading">
          {t('migrate_login_change_title')}
        </div>
        <div className="drawer-migration-claim-text">
          {t('migrate_login_change_description')}
        </div>
      </div>

      <div className="drawer-migration-claim-section">
        <div className="drawer-migration-claim-heading">
          {t('migrate_login_rewards_safe_title')}
        </div>
        <div className="drawer-migration-claim-text">
          {t('migrate_login_mining_comp_description')}
        </div>
        <div className="drawer-migration-claim-text">
          {t('migrate_login_meme_comp_description')}
        </div>
      </div>

      <div className="drawer-migration-claim-section-2">
        <div className="drawer-migration-claim-heading">
          {t('migrate_login_meme_what_next_title')}
        </div>
        <div className="drawer-migration-claim-text-2">
          {t('migrate_login_meme_what_next_description')}
        </div>
      </div>

      <div className="drawer-migration-claim-rewards-title">
        {t('migrate_login_meme_your_rewards')}
      </div>
      <div className="drawer-migration-claim-rewards-amount">
        {formatPrice(app.state.seasonScores[1] ?? 0)}
      </div>

      <button
        className="btn btn-normal drawer-migration-claim-button"
        disabled={!claimEnabled}
        onClick={onClaim}
      >
        {t('migrate_login_claim')}
      </button>
    </div>
  );
};

// ===================================================================================
// #region Progress Bar

interface ProgresBarProps {
  caption: string;
  color: string;
  duration: number;
  delay: number;
  onComplete?: () => void;
}

const ProgressBar = ({
  caption,
  color,
  duration,
  delay,
  onComplete,
}: ProgresBarProps) => {
  const { t } = useTranslation();

  // start

  const [hasStarted, setHasStarted] = useState(false);

  useEffect(() => {
    if (!hasStarted) {
      setTimeout(() => setHasStarted(true), delay * 1000);
    }
  }, []);

  // progress

  const [currentFrame, setCurrentFrame] = useState(0);
  const [percentageComplete, setPercentageComplete] = useState(0);

  useEffect(() => {
    if (!hasStarted) return;

    // progress interval
    const fps = 30;
    const maxFrames = duration * fps;
    const interval = setInterval(() => {
      setCurrentFrame((prevFrame) => prevFrame + 1);
      setPercentageComplete(Math.floor((currentFrame / maxFrames) * 100));
    }, fps);

    // progress end
    if (currentFrame > maxFrames) {
      setPercentageComplete(100);
      clearInterval(interval);
      onComplete && onComplete();
    }

    return () => clearInterval(interval);
  }, [currentFrame, hasStarted]);

  // console.warn('>>>', percentageComplete);

  if (!hasStarted) {
    return null;
  }

  // render

  return (
    <div className="progress-bar-container-v">
      {/* message */}
      <div className="progress-bar-text">{caption}</div>
      {/* progress bar horizontal container (bar + icon) */}
      <div className="progress-bar-container-h">
        {/* progress bar */}
        <div className="progress-bar">
          <div className="progress-bar-bg" />
          <div
            className={`progress-bar-value ${color}`}
            style={{ width: `${percentageComplete}%` }}
          ></div>
        </div>
        {/* progress bar icon*/}
        {percentageComplete >= 100 && (
          <motion.div
            className={`progress-bar-icon`}
            initial={{ opacity: 0, transform: 'translateX(30px)' }}
            animate={{ opacity: 1, transform: 'translateX(0px)' }}
            transition={{ duration: 0.25, delay: 0 }}
          >
            <img src={assets.ftue_icon_check} />
          </motion.div>
        )}
      </div>
    </div>
  );
};

// ===================================================================================
