import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Chart.scss';
import { app } from '../../../../data/Controllers/AppController';
import { Meme } from '../../../../replicant/features/tradingMeme/tradingMeme.getters';
import {
  LightweightChartComponent,
  GraphPoint,
} from '../../TradingPage/LightweightChartComponent/LightweightChartComponent';
import {
  SliceConfig,
  tokenPriceSliceConfigs,
} from '../../../../replicant/features/tradingMeme/tradingMeme.ruleset';
import { TimeIntervalButtons } from './TimeIntervalButtons';
import { DAY_IN_MS, HOUR_IN_MS } from '../../../../replicant/utils/time';

export interface FixedTimeInterval {
  sliceConfig: SliceConfig;
  text: string;
  textP?: Record<string, any>;
}

export type TimeInterval = Pick<FixedTimeInterval, 'text' | 'textP'> & {
  sliceConfig: SliceConfig | null;
};

const fixedTimeIntervals: FixedTimeInterval[] = [
  {
    sliceConfig: tokenPriceSliceConfigs.hour24,
    text: 'trading_chart_1_day',
  },
  {
    sliceConfig: tokenPriceSliceConfigs.day7,
    text: 'trading_chart_x_days',
    textP: { days: 7 },
  },
  {
    sliceConfig: tokenPriceSliceConfigs.day30,
    text: 'trading_chart_x_days',
    textP: { days: 30 },
  },
];

const allTimeInterval = {
  sliceConfig: null,
  text: 'trading_chart_all_time',
};

const intervals: TimeInterval[] = [...fixedTimeIntervals, allTimeInterval];

interface Props {
  meme: Meme;
  height: number;
}

export const TradingChart = ({ meme, height }: Props) => {
  // If you are translating within the component use `i18n.t()` not the imported `t()`
  const { t } = useTranslation();

  const [priceData, setPriceData] = useState<GraphPoint[]>([]);
  const [selectedInterval, setSelectedInterval] = useState(
    allTimeInterval as TimeInterval,
  );

  // select the smallest interval
  const autoSelectInterval = () => {
    const memeAge = app.now() - meme.createdAt;

    // find biggest interval whose dimension is greater than the age of the meme
    let lastTimeIntervalIdx = fixedTimeIntervals.length - 1;
    while (
      memeAge < fixedTimeIntervals[lastTimeIntervalIdx].sliceConfig.window
    ) {
      lastTimeIntervalIdx -= 1;

      if (lastTimeIntervalIdx < 0) {
        // return the all time interval
        return allTimeInterval;
      }
    }

    return fixedTimeIntervals[lastTimeIntervalIdx];
  };

  const manuallySelectInterval = (interval: TimeInterval) => {
    const memeAge = app.now() - meme.createdAt;
    if (interval.sliceConfig) {
      if (interval.sliceConfig.window > memeAge) {
        // return all time interval if selected interval time window is greater than age of the meme
        setSelectedInterval(allTimeInterval);
        return;
      }
    }

    setSelectedInterval(interval);
  };

  useEffect(() => {
    setSelectedInterval(autoSelectInterval());
  }, [meme.id]);

  useEffect(() => {
    const pricePoints = app.memes.trading.getOnchainTokenPricePoints(
      meme,
      selectedInterval.sliceConfig,
    );

    setPriceData(pricePoints);
  }, [meme.id, selectedInterval]);

  // bottom gradient green
  // const colors = {
  //   backgroundColor: 'transparent',
  //   lineColor: 'rgba(0, 150, 0, 1)', // #2962FF',
  //   textColor: '#777', //'white',
  //   areaTopColor: 'rgba(0, 150, 0, 1)', // '#2962FF',
  //   areaBottomColor: 'rgba(0, 150, 0, 0.0)', // 'rgba(41, 98, 255, 0.28)',
  // };

  // blue line
  const colors = {
    backgroundColor: 'transparent',
    lineColor: '#0098EA',
    textColor: '#777',
    areaTopColor: 'transparent',
    areaBottomColor: 'transparent',
  };

  return (
    <div className="trading-chart">
      <TimeIntervalButtons
        renderType="dropdown"
        firstInterval={selectedInterval}
        intervals={intervals}
        onSetInterval={(interval) => manuallySelectInterval(interval)}
      />

      <LightweightChartComponent
        data={priceData}
        colors={colors}
        height={height}
        margin={0}
        lineWidth={4}
        multiplier={1}
      />
    </div>
  );
};
