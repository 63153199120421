import { Dictionary, Cell } from '@ton/core';
import { sha256_sync } from '@ton/crypto';
import { waitFor } from '../utils';
import { Optional } from '../types';
import {
  storeJettonDeploy,
  storeAdminCallTransferJetton,
  storeAdminCallSellJetton,
  storeAdminCallClaimDexJetton,
  storeAdminCallDailyClaimDexJetton,
} from '../../replicant/features/tradingMeme/tact_Administrator';
import {
  sha256,
  SNAKE_PREFIX,
} from '../../replicant/features/tradingMeme/tradingMeme.getters.ton';

export type {
  JettonDeploy,
  AdminCallTransferJetton,
  AdminCallSellJetton,
} from '../../replicant/features/tradingMeme/tact_Administrator';

export const storeBuilders = {
  createJettonContract: storeJettonDeploy,
  buyToken: storeAdminCallTransferJetton,
  sellToken: storeAdminCallSellJetton,
  claimDexToken: storeAdminCallClaimDexJetton,
  claimDailyDexToken: storeAdminCallDailyClaimDexJetton,
};

const KNOWN_KEYS = [
  'name',
  'symbol',
  'description',
  'image',
  'decimals',
  'extra',
];

// Precompute a map of hash -> original string
const KEY_MAP: Record<string, string> = {};
for (const k of KNOWN_KEYS) {
  const hashHex = sha256(k).toString('hex');
  // '3976890847a...'
  KEY_MAP[hashHex] = k;
}

export function readOnchainMetadata(cell: Cell): Record<string, string> {
  try {
    // 1) Create a Slice
    const slice = cell.beginParse();

    // 2) Read the 8-bit prefix
    const prefix = slice.loadUint(8);
    console.error('prefix', prefix);
    // if (prefix !== ONCHAIN_CONTENT_PREFIX) {
    //   return {};
    //     // throw new Error(`Unexpected prefix: ${prefix}, expected ${ONCHAIN_CONTENT_PREFIX}`);
    // }

    // 3) Load the dictionary: (uint256) => cell
    const dict = slice.loadDict(
      Dictionary.Keys.BigUint(256),
      Dictionary.Values.Cell(),
    );

    // 4) Iterate all entries and parse them
    const result: Record<string, string> = {};
    for (const [keyBigUint, valCell] of dict) {
      // Convert your biguint key back to the original string key
      const bigIntHex = keyBigUint.toString(16).padStart(64, '0');
      const keyStr = KEY_MAP[bigIntHex];

      // 5) Parse the 'snake' cell to get the entire UTF-8 text
      const buffer = readSnakeCell(valCell);
      result[keyStr] = buffer.toString('utf8');
    }

    return result;
  } catch (e) {
    console.error('Error with readOnchainMetadata:', e);
    return {};
  }
}

// readSnakeCell reverses the logic of makeSnakeCell.
export function readSnakeCell(cell: Cell): Buffer {
  let result = Buffer.alloc(0);
  let current: Cell | null = cell;
  let isFirstCell = true;

  while (current) {
    // Create a parsing slice from the current cell
    const slice = current.beginParse();

    // If it's the first cell, load and verify the SNAKE_PREFIX (8 bits)
    if (isFirstCell) {
      const prefix = slice.loadUint(8);
      if (prefix !== SNAKE_PREFIX) {
        throw new Error(
          `Invalid snake prefix: got ${prefix}, expected ${SNAKE_PREFIX}`,
        );
      }
      isFirstCell = false;
    }

    // The rest of the bits in this cell are the chunk
    const bytesInThisCell = slice.remainingBits / 8;
    const chunk = slice.loadBuffer(bytesInThisCell);
    result = Buffer.concat([result, chunk]);

    // Move to the next reference if present
    if (current.refs.length > 0) {
      current = current.refs[0];
    } else {
      current = null;
    }
  }

  return result;
}

export function stringToBigInt(input: string): bigint {
  // Hash the string using SHA-256
  const hash = sha256_sync(input);

  // Convert the hash to a bigint
  return BigInt('0x' + hash.toString('hex'));
}

interface WithRetryOpts {
  maxRetries: number;
  interval?: number;
}
export const withRetry = async <T>(
  promise: () => Promise<T>,
  opts: Optional<WithRetryOpts> = {
    maxRetries: 10,
    interval: 1000,
  },
  retry = 0,
): Promise<T> => {
  const { maxRetries, interval = 1000 } = opts;
  if (retry === maxRetries) {
    throw new Error('TIMEOUT');
  }
  try {
    console.log('withRetry', { promise, retry });
    const value = await promise();
    console.log('withRetry', { value, retry });
    if (value) {
      return value;
    }
    await waitFor(interval);
    return withRetry(promise, opts, ++retry);
  } catch (e) {
    throw e;
  }
};

function calculatePrice(
  currentSupply: bigint,
  initialPrice: bigint,
  maxTargetPrice: bigint,
  totalSupply: bigint,
): bigint {
  /**
   * Calculate the market price at a given current supply.
   *
   * @param currentSupply Current token supply.
   * @param initialPrice Initial price of the token (in nanoton).
   * @param maxTargetPrice Maximum target price of the token (in nanoton).
   * @param totalSupply Maximum total token supply.
   * @return Market price as an integer (in nanoton).
   */
  const price =
    ((maxTargetPrice - initialPrice) * currentSupply ** BigInt(2)) /
      totalSupply ** BigInt(2) +
    initialPrice;
  return price; // Integer in nanoton
}

/**
 * Integer floor of the sqrt for a BigInt `x >= BigInt(0)`.
 */
function bigIntSqrtFloor(x: bigint): bigint {
  if (x < BigInt(0)) {
    throw new Error('bigIntSqrtFloor: negative input');
  }
  if (x < BigInt(2)) {
    return x; // sqrt(0)=0, sqrt(1)=1
  }

  // Binary search for floor(sqrt(x)).
  let left = BigInt(1);
  let right = x >> BigInt(1); // x/2 as a start

  while (left <= right) {
    const mid = (left + right) >> BigInt(1);
    const sq = mid * mid;
    if (sq === x) {
      return mid;
    } else if (sq < x) {
      left = mid + BigInt(1);
    } else {
      right = mid - BigInt(1);
    }
  }
  return right;
}

/**
 * Computes F(m) = sum_{k=0..m} floor( (c*k^2) / D ).
 *
 * Uses a "two-pointer" or "interval-jump" approach:
 *   - For each k, define v = floor((c*k^2)/D).
 *   - All consecutive k in [currentK .. nextK] share the same value v.
 *   - We can add v * (block length) in one step, then jump.
 *
 * Complexity ~ O(sqrt(m)) in practice, not O(m).
 */
function partialSumFloorQuadratic(c: bigint, D: bigint, m: bigint): bigint {
  if (m < BigInt(0)) {
    return BigInt(0);
  }
  if (c === BigInt(0)) {
    return BigInt(0);
  }

  // Quick check: if c*m^2 < D, then floor((c*k^2)/D)=0 for all k <= m => sum=0.
  // (That is because for any 0 <= k <= m, c*k^2 <= c*m^2 < D => floor(...)=0)
  if (c * (m * m) < D) {
    return BigInt(0); // everything is zero
  }

  let total = BigInt(0);
  let currentK = BigInt(0);

  while (currentK <= m) {
    // v = floor( (c*currentK^2)/D )
    const v = (c * (currentK * currentK)) / D;

    // We want the largest K' >= currentK such that floor((c*K'^2)/D) = v.
    // That condition means:
    //   v <= (c*K'^2)/D < v+1
    // => v*D <= c*K'^2 < (v+1)*D
    // => K'^2 < ((v+1)*D)/c
    // => K' < sqrt( ((v+1)*D - 1 ) / c ), to stay strictly below (v+1).
    //
    // So nextK = floor( sqrt( (((v+1)*D) - 1) // c ) ).
    // We'll clamp nextK <= m.
    const numerator = (v + BigInt(1)) * D - BigInt(1); // might be < 0 if v+1 < 1 => check
    if (numerator < BigInt(0)) {
      // If we get here, it means v+1=0 => v=-1 => not possible
      // or some edge case. Let's just break out. (Shouldn't happen normally.)
      break;
    }
    const radicand = numerator / c; // integer division
    if (radicand < BigInt(0)) {
      // means no further k satisfies this (should be impossible if c>0)
      break;
    }
    const maxKforV = bigIntSqrtFloor(radicand);

    // clamp it to m
    const nextK = maxKforV > m ? m : maxKforV;

    if (nextK < currentK) {
      // No progress => break to avoid infinite loop
      break;
    }

    const blockLen = nextK - currentK + BigInt(1);
    total += v * blockLen;

    currentK = nextK + BigInt(1);
  }

  return total;
}

/**
 * Sums floor((c*k^2)/D) for k in [start..end], inclusive,
 * by using partialSumFloorQuadratic for 0..end minus 0..(start-1).
 */
function sumOfFloorsQuadraticRange(
  c: bigint,
  D: bigint,
  start: bigint,
  end: bigint,
): bigint {
  if (start > end) return BigInt(0);
  // F(x) = partialSumFloorQuadratic(c,D,x)
  // sum_{k=start..end} = F(end) - F(start-1)
  const Fend = partialSumFloorQuadratic(c, D, end);
  const FstartMinus1 = partialSumFloorQuadratic(c, D, start - BigInt(1));
  return Fend - FstartMinus1;
}

/**
 * Exact cost for buying `n` tokens starting at supply `s`, with:
 *   price(k) = floor( (c*k^2)/D ) + initialPrice,
 * where c = maxTargetPrice - initialPrice,
 *       D = (totalSupply^2).
 *
 * => totalCost(n) = sum_{k=s..s+n-1} floor((c*k^2)/D)  +  n*initialPrice
 */
function exactTotalCost(
  n: bigint,
  s: bigint, // currentSupply
  c: bigint, // (maxTargetPrice - initialPrice)
  D: bigint, // totalSupply^2
  initialPrice: bigint,
): bigint {
  if (n === BigInt(0)) return BigInt(0);
  // sum of floors:
  const floorsPart = sumOfFloorsQuadraticRange(c, D, s, s + n - BigInt(1));
  return floorsPart + n * initialPrice;
}

/**
 * Find the maximum `n` such that:
 *   exactTotalCost(n, currentSupply, c, D, initialPrice) <= tonPaid
 *
 * Uses a standard binary search in [0..(maxPossibleTokens)].
 */
function exactMaxTokensBuyable(
  currentSupply: bigint,
  tonPaid: bigint,
  initialPrice: bigint,
  maxTargetPrice: bigint,
  totalSupply: bigint,
): bigint {
  const c = maxTargetPrice - initialPrice; // c in floor((c*k^2)/D)
  const D = totalSupply * totalSupply; // D = (totalSupply^2)
  const maxN = totalSupply - currentSupply; // can't exceed total supply

  let left = BigInt(0);
  let right = maxN;

  while (left < right) {
    // upper-mid to ensure progress
    const mid = (left + right + BigInt(1)) >> BigInt(1);
    const cost = exactTotalCost(mid, currentSupply, c, D, initialPrice);

    if (cost <= tonPaid) {
      left = mid; // can afford mid, try for more
    } else {
      right = mid - BigInt(1);
    }
  }

  return left;
}

// Estimate Token amount for Ton
export function calculateTokensReceived(
  currentSupply: bigint,
  tonPaid: bigint,
  initialPrice: bigint,
  maxTargetPrice: bigint,
  totalSupply: bigint,
): bigint {
  /**
   * Calculate the number of tokens received for a given TON paid.
   *
   * @param currentSupply Current token supply (S_start).
   * @param tonPaid TON the user will pay (in nanoton).
   * @param initialPrice Initial price of the token (in nanoton).
   * @param maxTargetPrice Maximum target price of the token (in nanoton).
   * @param totalSupply Maximum total token supply.
   * @return Number of tokens received.
   */

  // this is the super fast chatgpt-o1 optimized version
  return exactMaxTokensBuyable(
    currentSupply,
    tonPaid,
    initialPrice,
    maxTargetPrice,
    totalSupply,
  );

  // this is the naive loop version
  // let tokensToBuy = BigInt(0);
  // let totalCost = BigInt(0);

  // let iterations = 0;
  // while (totalCost < tonPaid) {
  //   iterations += 1;

  //   // Stop if current supply reaches total supply
  //   if (currentSupply >= totalSupply) {
  //     console.log('Reached maximum token supply.');
  //     break;
  //   }

  //   // Calculate the price of the next token
  //   const price = calculatePrice(
  //     currentSupply,
  //     initialPrice,
  //     maxTargetPrice,
  //     totalSupply,
  //   );

  //   if (totalCost + price > tonPaid) {
  //     break;
  //   }

  //   // Update totals
  //   totalCost += price;
  //   tokensToBuy += BigInt(1);
  //   currentSupply += BigInt(1);
  // }

  // return tokensToBuy;
}

/**
 * Estimates the number of tokens that can be received for a given amount of TON,
 * taking into account the bonding curve pricing model. Uses a step-based approach
 * for more efficient calculation with large numbers. Note, the smaller the step size,
 * the more precise the calculation, but the slower it will be.
 *
 * @param currentSupply - Current total supply of tokens in circulation
 * @param tonPaid - Amount of TON being paid (in nanotons)
 * @param initialPrice - Starting price of tokens (in nanotons)
 * @param maxTargetPrice - Maximum price cap for tokens (in nanotons)
 * @param totalSupply - Maximum possible token supply
 * @returns The estimated number of tokens that can be purchased
 */
export function estimateTokensReceived(
  currentSupply: bigint,
  tonPaid: bigint,
  initialPrice: bigint,
  maxTargetPrice: bigint,
  totalSupply: bigint,
): bigint {
  // Early exits
  if (currentSupply >= totalSupply || tonPaid <= BigInt(0)) {
    return BigInt(0);
  }

  // Use a step size to make the calculation more efficient
  const STEP_SIZE = BigInt(1000); // Adjust this value based on precision needs
  let tokensToBuy = BigInt(0);
  let totalCost = BigInt(0);
  let remainingSupply = totalSupply - currentSupply;

  while (totalCost < tonPaid && currentSupply < totalSupply) {
    // Calculate how many tokens we can try to buy in this step
    let stepTokens = STEP_SIZE;
    if (tokensToBuy + stepTokens > remainingSupply) {
      stepTokens = remainingSupply - tokensToBuy;
    }

    // Calculate price for this batch
    const price = calculatePrice(
      currentSupply + tokensToBuy,
      initialPrice,
      maxTargetPrice,
      totalSupply,
    );

    if (price <= BigInt(0)) {
      break;
    }

    const batchCost = price * stepTokens;

    if (totalCost + batchCost > tonPaid) {
      // If we can't buy the full batch, calculate how many we can buy
      const remainingTon = tonPaid - totalCost;
      const tokensInLastBatch = remainingTon / price;
      tokensToBuy += tokensInLastBatch;
      break;
    }

    totalCost += batchCost;
    tokensToBuy += stepTokens;

    if (tokensToBuy >= remainingSupply) {
      break;
    }
  }

  return tokensToBuy;
}

function calculateDexSwap(
  sendAmount: bigint,
  sourcePoolAmount: bigint,
  targetPoolAmount: bigint,
): bigint {
  const FEE_NUMERATOR = BigInt(997);
  const FEE_DENOMINATOR = BigInt(1000);
  const numerator = targetPoolAmount * sendAmount * FEE_NUMERATOR;
  const denominator =
    sourcePoolAmount * FEE_DENOMINATOR + sendAmount * FEE_NUMERATOR;

  return numerator / denominator;
}

export function calculateTonToDexTokens(
  tonSendAmount: bigint,
  tonLiquidityPoolAmount: bigint,
  dexTokenLiquidityPoolAmount: bigint,
): string {
  const tokensReceived = calculateDexSwap(
    tonSendAmount,
    tonLiquidityPoolAmount,
    dexTokenLiquidityPoolAmount,
  );
  return tokensReceived.toString();
}

export function calculateDexTokensToTon(
  dexTokenSendAmount: bigint,
  tonLiquidityPoolAmount: bigint,
  dexTokenLiquidityPoolAmount: bigint,
): bigint {
  const tonReceived = calculateDexSwap(
    dexTokenSendAmount,
    dexTokenLiquidityPoolAmount,
    tonLiquidityPoolAmount,
  );
  return BigInt(tonReceived);
}

type CurveConfig = {
  maxTargetPrice: bigint;
  initialPrice: bigint;
  maxSupply: bigint;
};
// Estimate Ton for Token amount
export function calculateTotalMintPrice(
  currentSupply: bigint,
  tokensToBuyOrSell: bigint,
  curveConfig: CurveConfig,
  isBuy: boolean,
): bigint {
  /**
   * Calculate the total mint price for buying or selling tokens based on a curve.
   *
   * @param currentSupply Current token supply.
   * @param tokensToBuyOrSell Number of tokens to buy or sell.
   * @param curveConfig Curve configuration (maxTargetPrice, initialPrice, maxSupply).
   * @param isBuy Boolean indicating if it is a buy (true) or sell (false) operation.
   * @return Total mint price as an integer (in nanoton).
   */
  const b: bigint = curveConfig.maxTargetPrice - curveConfig.initialPrice;
  const F: bigint = curveConfig.maxSupply ** BigInt(2);
  const precisionFactor: bigint = BigInt(1_000_000_000); // Large factor to maintain precision

  const power = BigInt(3);

  let price: bigint;

  if (isBuy) {
    const buyTerm =
      (((currentSupply + tokensToBuyOrSell) ** power - currentSupply ** power) *
        b *
        precisionFactor) /
      (power * F);
    price =
      curveConfig.initialPrice * tokensToBuyOrSell * precisionFactor + buyTerm;
  } else {
    const sellTerm =
      ((currentSupply ** power - (currentSupply - tokensToBuyOrSell) ** power) *
        b *
        precisionFactor) /
      (power * F);
    price =
      curveConfig.initialPrice * tokensToBuyOrSell * precisionFactor + sellTerm;
  }

  // Divide by the precision factor to scale down to intended integer range
  return price / precisionFactor;
}
