import './DrawerFtueShare.scss';
import { useTranslation } from 'react-i18next';
import { assets } from '../../../assets/assets';
import { app } from '../../../data/Controllers/AppController';
import { useEffect, useState } from 'react';
import { largeIntegerToLetter } from '../../../replicant/utils/numbers';
import {
  getBuyTxEstimate,
  getFtueShareGateReward,
  Meme,
} from '../../../replicant/features/tradingMeme/tradingMeme.getters';

import { motion } from 'motion/react';
import { t } from 'i18next';
import { SEC_IN_MS } from '../../../replicant/utils/time';
import { memeGiftRuleset } from '../../../replicant/features/tradingMeme/tradingMeme.ruleset';
import { HP } from '../../../replicant/lib/HighPrecision';
import { MemeImage } from '../../shared/MemeImage/MemeImage';
import { GiftImage } from '../../shared/GiftImage/GiftImage';

// ===================================================================================
// #region FTUE Share

export const DrawerFtueShare = () => {
  const { t } = useTranslation();

  // get initial random meme asynchronously
  const [meme, setMeme] = useState<Meme>();
  useEffect(() => {
    if (!meme) {
      app.memes.getFtueShareInitialMeme().then(async (_memeId) => {
        if (!_memeId) {
          app.ui.drawer.close();
          return;
        }

        const _meme = await app.memes.getMeme(_memeId, 'forceFetch');

        if (_meme) {
          setMeme(_meme);
        } else {
          app.ui.drawer.close();
        }
      });
    }
  }, [meme]);

  // should we display progress bars or final screen?
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isLoading) {
      app.track('FTUEprofileAnalysisLoad');
    } else {
      app.track('FTUEprofileAnalysisComplete');
    }
  }, [isLoading]);

  if (isLoading) {
    return <LoadingScreen onComplete={() => setIsLoading(false)} />;
  } else {
    if (!meme) {
      return null;
    }
    return <SharingScreen meme={meme} />;
  }
};

// ===================================================================================
// #region Loading

const LoadingScreen = ({ onComplete }: { onComplete: () => void }) => {
  const duration = 0.7;
  // const delay = 3;

  // const m = 0.35;

  const [isComplete, setIsComplete] = useState(false);

  return (
    <div className="drawer-ftue-share">
      <motion.div
        className="drawer-ftue-share-loading-container"
        initial={{ opacity: 0, transform: 'translateY(40px)' }}
        animate={{
          opacity: isComplete ? 0 : 1,
          transform: isComplete ? 'translateY(-40px)' : 'translateY(0px)',
        }}
        transition={{ duration: 0.5, delay: 0.25 }}

        // onTransitionEnd={(hello) => {S
        //   console.warn('>>> onTransitionEnd', hello, isComplete);
        //   if (isComplete) onComplete();
        // }}
      >
        {/* <div className="drawer-ftue-share-loading-container"> */}
        <div className="drawer-ftue-share-loading">
          <div className="drawer-ftue-share-checking">{t('ftue_checking')}</div>
          <div className="drawer-ftue-share-loading-bars">
            <ProgressBar
              caption={t('ftue_loading_bar1')}
              color={'yellow'}
              duration={duration}
              delay={0.5}
            />
            <ProgressBar
              caption={t('ftue_loading_bar2')}
              color={'yellow'}
              duration={duration}
              delay={0.5 + duration * 1 - 0.2}
            />
            <ProgressBar
              caption={t('ftue_loading_bar3')}
              color={'yellow'}
              duration={duration}
              delay={0.5 + duration * 2 - 0.4}
            />
            <ProgressBar
              caption={t('ftue_loading_bar4')}
              color={'yellow'}
              duration={duration * 2.5}
              delay={0.5 + duration * 2.5 - 0.4}
              onComplete={() => {
                setTimeout(() => {
                  setIsComplete(true);
                  setTimeout(() => onComplete(), 350);
                }, 350);
              }}
            />
          </div>
        </div>
        {/* </div> */}
      </motion.div>
    </div>
  );
};

// ===================================================================================
// #region Sharing

const SharingScreen = ({ meme }: { meme: Meme }) => {
  const instantReward = getBuyTxEstimate({
    currentSupply: HP(meme.pointSupply),
    currencyAmount: HP(getFtueShareGateReward(app.state)),
  });

  const instantRewardTxt = largeIntegerToLetter(instantReward.toNumber(), 4);

  const supply = HP(meme?.pointSupply ?? 0);
  const giftCoinEquivalent = memeGiftRuleset.giftReward;
  const giftPoints = getBuyTxEstimate({
    currentSupply: supply,
    currencyAmount: HP(giftCoinEquivalent),
  });

  const onTapShareButton = async () => {
    await app.memes.sendGift(meme, 'ftue_share_gate');

    app.invoke.grantFtueShareReward({ memeId: meme.id });

    app.nav.goToTiktokFeed(meme.id);

    setTimeout(() => {
      app.ui.drawer.close();
    }, 5 * SEC_IN_MS);
  };

  useEffect(() => {
    if (meme) {
      app.track('FTUEmemeGiftPageLoad', {
        MemeId: meme.id,
        MemeTicker: meme.ticker,
        pointAmount: instantReward.toString(),
        giveShareAmount: giftPoints.toString(),
      });
    }
  }, [meme]);

  if (!meme) {
    return;
  }

  const memeImage = meme.image;
  // const memeImage = assets.mockup_slide; // testing locally

  return (
    <>
      <div className="drawer-ftue-share">
        {/* top header - empty */}
        <motion.div
          className="drawer-ftue-share-header"
          initial={{ opacity: 0, transform: 'translateY(40px)' }}
          animate={{ opacity: 1, transform: 'translateY(0px)' }}
          transition={{ duration: 0.75, delay: 0 }}
        >
          <div className="drawer-ftue-share-header-title">
            {t('share_meme_gift_title')}
          </div>
        </motion.div>

        {/* centered content */}

        {/* big image */}
        <motion.div
          className="drawer-ftue-share-content image"
          initial={{ opacity: 0, transform: 'translateY(40px)' }}
          animate={{ opacity: 1, transform: 'translateY(0px)' }}
          transition={{ duration: 0.55, delay: 0.2 }}
        >
          <GiftImage
            src={memeImage}
            size={window.innerHeight * 0.33}
            useBadge={false}
          />
        </motion.div>

        {/* meme info */}
        <motion.div
          className="drawer-ftue-share-content"
          initial={{ opacity: 0, transform: 'translateY(40px)' }}
          animate={{ opacity: 1, transform: 'translateY(0px)' }}
          transition={{ duration: 0.45, delay: 0.3 }}
        >
          <div className="drawer-ftue-share-meme-info">
            <div className="value">{instantRewardTxt}</div>

            <div className="row">
              <MemeImage src={memeImage} size={24} />
              <div className="currency">${meme.ticker} Points</div>
            </div>
          </div>
        </motion.div>

        {/* swap info */}
        <motion.div
          className="drawer-ftue-share-content"
          initial={{ opacity: 0, transform: 'translateY(40px)' }}
          animate={{ opacity: 1, transform: 'translateY(0px)' }}
          transition={{ duration: 0.45, delay: 0.3 }}
        >
          <div className="drawer-ftue-share-swap-info">
            <div className="swap-container">
              <div className="swap-label">{t('share_meme_gift_give')}</div>
              <div className="swap-value">
                {largeIntegerToLetter(giftPoints.toNumber(), 3)} Points
              </div>
            </div>
            <img src={assets.icon_swap} width={40} height={40} />
            <div className="swap-container">
              <div className="swap-label">{t('share_meme_gift_get')}</div>
              <div className="swap-value">
                {largeIntegerToLetter(giftPoints.toNumber(), 3)} Points
              </div>
            </div>
          </div>
        </motion.div>

        {/* bottom buttons  */}
      </div>

      <motion.div
        className="drawer-ftue-share-buttons"
        initial={{ opacity: 0, transform: 'translateY(40px)' }}
        animate={{ opacity: 1, transform: 'translateY(0px)' }}
        transition={{ duration: 0.25, delay: 0.5 }}
      >
        <div
          className="btn btn-normal drawer-ftue-share-button"
          onClick={onTapShareButton}
        >
          {t('generic_share')}
        </div>
      </motion.div>
    </>
  );
};

// ===================================================================================
// #region Progress Bar

interface ProgresBarProps {
  caption: string;
  color: string;
  duration: number;
  delay: number;
  onComplete?: () => void;
}

const ProgressBar = ({
  caption,
  color,
  duration,
  delay,
  onComplete,
}: ProgresBarProps) => {
  const { t } = useTranslation();

  // start

  const [hasStarted, setHasStarted] = useState(false);

  useEffect(() => {
    if (!hasStarted) {
      setTimeout(() => setHasStarted(true), delay * 1000);
    }
  }, []);

  // progress

  const [currentFrame, setCurrentFrame] = useState(0);
  const [percentageComplete, setPercentageComplete] = useState(0);

  useEffect(() => {
    if (!hasStarted) return;

    // progress interval
    const fps = 30;
    const maxFrames = duration * fps;
    const interval = setInterval(() => {
      setCurrentFrame((prevFrame) => prevFrame + 1);
      setPercentageComplete(Math.floor((currentFrame / maxFrames) * 100));
    }, fps);

    // progress end
    if (currentFrame > maxFrames) {
      setPercentageComplete(100);
      clearInterval(interval);
      onComplete && onComplete();
    }

    return () => clearInterval(interval);
  }, [currentFrame, hasStarted]);

  // console.warn('>>>', percentageComplete);

  if (!hasStarted) {
    return null;
  }

  // render

  return (
    <div className="progress-bar-container-v">
      {/* message */}
      <div className="progress-bar-text">{caption}</div>
      {/* progress bar horizontal container (bar + icon) */}
      <div className="progress-bar-container-h">
        {/* progress bar */}
        <div className="progress-bar">
          <div className="progress-bar-bg" />
          <div
            className={`progress-bar-value ${color}`}
            style={{ width: `${percentageComplete}%` }}
          ></div>
        </div>
        {/* progress bar icon*/}
        {percentageComplete >= 100 && (
          <motion.div
            className={`progress-bar-icon`}
            initial={{ opacity: 0, transform: 'translateX(30px)' }}
            animate={{ opacity: 1, transform: 'translateX(0px)' }}
            transition={{ duration: 0.25, delay: 0 }}
          >
            <img src={assets.ftue_icon_check} />
          </motion.div>
        )}
      </div>
    </div>
  );
};

// ===================================================================================
