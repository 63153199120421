import { AnalyticsPayload, ReplicantEventHandlerAPI } from '@play-co/replicant';
import { updateBotMenu } from '../../chatbot';
import { ReplicantServer } from '../../config';
import { MutableState, State } from '../../schema';
import { numberToShortString } from '../../utils/numbers';
import config, { stage } from '../game/game.config';
import {
  BotActions,
  callToActions,
  CmdOpts,
  cmdReplyCaptions,
  howToPlayGuideUrl,
  maxNotifDelays,
  specialCmdWhitelist,
  termsOfServiceUrl,
} from './chatbot.ruleset';
import { TelegramMessage } from './chatbot.schema';
import templates from './chatbot.templates';
import {
  generateUserPayloadKey,
  getFriendReferralRewards,
} from '../game/game.getters';
import { RESERVED_REFERRAL_ID } from '../game/ruleset/referrals';
import { HOUR_IN_MS, MIN_IN_MS, YEAR_IN_MS } from '../../utils/time';
import { tests } from '../../ruleset';
import {
  createIAPLink,
  getChat,
  getChatBotUserId,
  pinMessage,
  sendMessage,
  unpinMessage,
} from './chatbot.api';
import { League } from '../game/ruleset/league';
import { getReceiverBucket } from '../game/abtest.getters';
import { sendStartCmdMessage, sendTelegramMessage } from './chatbot.modifiers';
import { getChatbotPayload } from './chatbot.getters';

function getInviteText(
  withPrefix = true,
  { regular, premium }: { regular: number; premium: number },
) {
  const bonusRegular = numberToShortString(regular);
  const bonusPremium = numberToShortString(premium);
  const prefix = '\nPlay Gemzcoin with me and earn Gemz points!\n';
  return `${
    withPrefix ? prefix : ''
  }\n🏆+${bonusRegular} Gemz Points as a first-time bonus\n💎+${bonusPremium} Gemz Points if you have Telegram Premium\n`;
}

function randomlyUpperCaseLetter(input: string): string {
  const isAlpha = (char: string) => /[a-zA-Z]/.test(char);
  const letterIndices = input
    .split('')
    .map((char, index) => (isAlpha(char) ? index : -1))
    .filter((index) => index !== -1);

  if (letterIndices.length === 0) return input;

  const randomIndex =
    letterIndices[Math.floor(Math.random() * letterIndices.length)];

  return input
    .split('')
    .map((char, index) => (index === randomIndex ? char.toUpperCase() : char))
    .join('');
}

export function getReferralUrl(payloadKey: string, botName?: string): string {
  if (payloadKey.length > 64) {
    throw Error(
      `Bot link start parameter ${payloadKey} exceeds Telegram limit of 64 characters`,
    );
  }

  const randomlyUpperCaseBotName = randomlyUpperCaseLetter(
    botName || (config.botName as string),
  );

  const appName = 'tap';

  return `https://t.me/${randomlyUpperCaseBotName}/${appName}?startapp=${payloadKey}`;
}

function getInviteDeeplink(
  payloadKey: string,
  urlOnly = false,
  reward: { regular: number; premium: number },
) {
  const msg = getInviteText(true, reward);
  const text = encodeURIComponent(msg);
  const url = encodeURIComponent(getReferralUrl(payloadKey));

  // Sending text works as expected from desktop but wont work for mobile
  if (urlOnly) {
    return `tg://msg_url?url=${url}`; //&text=${text}`;
  }

  return `tg://msg_url?url=${url}&text=${text}`;
}

const onStart = async (
  state: MutableState,
  api: ReplicantEventHandlerAPI<ReplicantServer>,
  message: TelegramMessage,
  cmdOpts?: CmdOpts,
) => {
  const isCreateTeam = Boolean(cmdOpts?.create);
  if (isCreateTeam) {
    state.team_creation_start_time = api.date.now();

    await sendMessage({
      chatId: message.chat.id,
      text: `Specify the public channel in the format:
      <i>@Telegram</i>`,
      parseMode: 'HTML',
      fetch: api.fetch,
    });
    return;
  }

  const fromUser = message.from;
  if (!fromUser?.id) {
    return;
  }

  if (!config.playUrl) {
    throw Error(`Missing playUrl configuration for stage ${process.env.STAGE}`);
  }

  // On referral link click user sends the bot a `/start` message with a `ref_<encoded payload key>` parameter.
  // This logic extracts the payload key from the `/start` message and uses it to populate the `?payload=...`
  // query parameter in the web app link that is included in the bot's reply message (the `open game` button).
  const referralPayloadKey = cmdOpts?.ref;

  sendStartCmdMessage(state, api, referralPayloadKey);

  // const telegramPinnedMsgBody = {
  //   chatId: message.chat.id,
  //   text: `💎Get gemz\nJust for you, a game that lets you earn while having fun. Play gemz and prepare for the future of crypto.`,
  //   replyMarkup: {
  //     inline_keyboard: [[createPlayButtonContent('Play Now', payload)]],
  //   },
  // };

  // send the message we're gonna pin
  // const sentMessage = await sendMessage({
  //   ...telegramPinnedMsgBody,
  //   fetch: api.fetch,
  // });

  // pin process starts after sending the real welcome message

  // const sentMessageId = sentMessage.message_id;

  // get chat info to get current pinned message (if any)
  // const chatInfo = await getChat(message.chat.id as any, api.fetch);

  // // just assume that if pinned message is from bot, it's from our chatbot
  // if (chatInfo.pinned_message?.from?.is_bot) {
  //   // unpin our message
  //   await unpinMessage({
  //     chatId: message.chat.id,
  //     messageId: chatInfo.pinned_message.message_id,
  //     fetch: api.fetch,
  //   });
  // }

  // // pin the newly sent one
  // await pinMessage({
  //   chatId: message.chat.id,
  //   messageId: sentMessageId,
  //   fetch: api.fetch,
  //   disableNotification: true,
  // });

  // original way with payload. keeping here to remember the analytics payload
  // sendTelegramMessage(state, api, {
  //   chatId: message.chat.id,
  //   message: templates.startCmdPrivate({
  //     args: {
  //       caption: cmdReplyCaptions.startCmdPrivate.replace(
  //         '{username}',
  //         state.username,
  //       ),
  //       btns,
  //     },
  //     payload: getChatbotPayload({
  //       feature: 'bot',
  //       $subFeature: 'bot_start',
  //     }),
  //   }),
  //   receiverId: state.id,
  // });
};

export const onInvite = async (
  state: MutableState,
  api: ReplicantEventHandlerAPI<ReplicantServer>,
  message: TelegramMessage,
  cmdOpts?: CmdOpts,
) => {
  if (!message.from?.id) {
    return;
  }

  const reward = getFriendReferralRewards(
    state,
    api.date.now(),
    League.league1,
  );

  sendTelegramMessage(state, api, {
    chatId: message.chat.id,
    message: templates.inviteCmd({
      args: {
        caption: getInviteText(false, reward),
        callToAction: callToActions.inviteCmd,
        referralLink: getInviteDeeplink(
          message.from.id.toString(),
          true,
          reward,
        ),
      },
      payload: getChatbotPayload(
        {
          feature: 'invite',
          $subFeature: 'bot_invite_cmd',
        },
        {
          originFeature: 'bot',
        },
      ),
    }),
    receiverId: state.id,
    maxDelayMs: 5 * MIN_IN_MS,
    priority: 'high',
  });
};

export const onHelp = async (
  state: MutableState,
  api: ReplicantEventHandlerAPI<ReplicantServer>,
  message: TelegramMessage,
  cmdOpts?: CmdOpts,
) => {
  if (!message.from?.id) {
    return;
  }

  sendTelegramMessage(state, api, {
    chatId: message.chat.id,
    message: templates.howToPlayCmd({
      args: {
        caption: cmdReplyCaptions.howToPlayCmd,
        callToAction: callToActions.howToPlayCmd,
        url: howToPlayGuideUrl,
      },
      payload: getChatbotPayload({
        feature: 'howtoplay',
        $subFeature: 'howtoplay_guide',
      }),
    }),
    receiverId: state.id,
    maxDelayMs: 5 * MIN_IN_MS,
    priority: 'high',
  });
};

const localProxies = process.env.LOCAL_PROXIES
  ? JSON.parse(process.env.LOCAL_PROXIES)
  : undefined;

export const onLocal = async (
  state: MutableState,
  api: ReplicantEventHandlerAPI<ReplicantServer>,
  message: TelegramMessage,
  cmdOpts?: CmdOpts,
) => {
  if (!localProxies) {
    return;
  }

  const localProxy = localProxies[state.id];
  if (!localProxy) {
    return;
  }

  const urlLocal = new URL(localProxy);
  urlLocal.searchParams.set('uid', state.id);

  const urlDeployedReplicant = new URL(localProxy);
  urlDeployedReplicant.searchParams.set('replicant', stage ?? '');

  sendTelegramMessage(state, api, {
    chatId: message.chat.id,
    message: templates.localCmd({
      args: {
        caption: cmdReplyCaptions.localCmd.replace('{proxy}', localProxy),
        buttons: [
          {
            callToAction: callToActions.localCmd,
            url: urlLocal.toString(),
          },
          {
            callToAction: `${callToActions.localCmd} (replicant = ${stage})`,
            url: urlDeployedReplicant.toString(),
          },
        ],
      },
      payload: getChatbotPayload({
        feature: 'local',
        $subFeature: 'local_testing',
      }),
    }),
    receiverId: state.id,
    maxDelayMs: 5 * MIN_IN_MS,
    priority: 'high',
  });
};

export const onUpdateBotMenu = async (
  state: MutableState,
  api: ReplicantEventHandlerAPI<ReplicantServer>,
  message: TelegramMessage,
  cmdOpts?: CmdOpts,
) => {
  if (!specialCmdWhitelist.includes(state.id)) {
    return;
  }

  sendMessage({
    chatId: message.chat.id,
    text: 'Updating the bot menu.\nThe process might take a few minutes...',
    fetch: api.fetch,
  });

  await updateBotMenu(api.fetch);

  sendMessage({
    chatId: message.chat.id,
    text: 'Bot Menu Updated!\nIt might take another few minutes for the change to take effect',
    fetch: api.fetch,
  });
};

export const onGeneratePayload = async (
  state: MutableState,
  api: ReplicantEventHandlerAPI<ReplicantServer>,
  message: TelegramMessage,
  cmdOpts?: CmdOpts,
) => {
  if (!specialCmdWhitelist.includes(state.id)) {
    return;
  }

  const cmdOptsStr = JSON.stringify(cmdOpts);
  const dlOpts = JSON.parse(cmdOptsStr).dlOpts;
  const payload = {
    $channel: cmdOpts?.$channel,
    feature: cmdOpts?.feature,
    $subFeature: cmdOpts?.$subFeature,
    payload: {
      dlRoute: cmdOpts?.dlRoute,
      dlOpts: dlOpts ? JSON.parse(dlOpts) : undefined,
    },
  };

  const payloadKey = generateUserPayloadKey(RESERVED_REFERRAL_ID);
  await api.kvStore.send(payloadKey, JSON.stringify(payload), {
    expiresInMs: YEAR_IN_MS,
  });

  const oneTapLink = `https://t.me/${config.botName}/tap?startapp=${payloadKey}`;
  const botLink = `https://t.me/${config.botName}?start=ref_${payloadKey}`;
  sendMessage({
    chatId: message.chat.id,
    text: `Payload: ${JSON.stringify(
      payload,
      null,
      2,
    )}\n\nOne-Tap link:\n${oneTapLink}\nBot link:\n${botLink}`,
    fetch: api.fetch,
  });
};

export const onDevHelp = async (
  state: MutableState,
  api: ReplicantEventHandlerAPI<ReplicantServer>,
  message: TelegramMessage,
  cmdOpts?: CmdOpts,
) => {
  if (!specialCmdWhitelist.includes(state.id)) {
    return;
  }

  const chatBotUserId = getChatBotUserId();

  await sendMessage({
    chatId: message.chat.id,
    text: `ChatBot User ID: ${chatBotUserId}`,
    fetch: api.fetch,
  });
};

const expectedParams = ['sku', 'title', 'description', 'price', 'buyCTA'];
const missingParamError = `(${expectedParams.toString()})`;
const generateIAPLink = async (
  state: MutableState,
  api: ReplicantEventHandlerAPI<ReplicantServer>,
  message: TelegramMessage,
  cmdOpts?: CmdOpts,
) => {
  const text = message.text;

  // Validate params from input, expect comma separated; sku, title, description, price, buyCTA
  try {
    if (!text) {
      throw new Error(`Missing create link params ${missingParamError}`);
    }

    const params = text
      .replace('/cai', '')
      .split(',')
      .map((p) => p.trim());

    if (params.length !== expectedParams.length) {
      throw new Error(
        `Missing create or more link params ${missingParamError};`,
      );
    }

    const price = Number(params[3]);

    if (Number.isNaN(price)) {
      throw new Error(`Invalid 'price'. Cannot convert to number.`);
    }

    const linkProps = {
      sku: params[0],
      title: params[1],
      description: params[2],
      price: {
        amount: price,
        label: params[4],
      },
    };

    const response = await createIAPLink(linkProps, api.fetch);

    await sendMessage({
      chatId: message.chat.id,
      text: `Link created successfully:\n${response}`,
      fetch: api.fetch,
    });
  } catch (e: any) {
    await sendMessage({
      chatId: message.chat.id,
      text: e.message,
      fetch: api.fetch,
    });
  }
};

const onStartAI = async (
  state: MutableState,
  api: ReplicantEventHandlerAPI<ReplicantServer>,
  message: TelegramMessage,
  cmdOpts?: CmdOpts,
) => {
  const fromUser = message.from;
  if (!fromUser?.id) {
    return;
  }

  if (!config.playUrl) {
    throw Error(`Missing playUrl configuration for stage ${process.env.STAGE}`);
  }

  // On referral link click user sends the bot a `/start` message with a `ref_<encoded payload key>` parameter.
  // This logic extracts the payload key from the `/start` message and uses it to populate the `?payload=...`
  // query parameter in the web app link that is included in the bot's reply message (the `open game` button).
  const referralPayloadKey = cmdOpts?.ref;

  sendStartCmdMessage(state, api, referralPayloadKey, true);
};

export const privateActions: BotActions = {
  '/ai': onStartAI,
  '/start': onStart,
  '/invite': onInvite,
  '/help': onHelp,
  // @note: this command should simply not be configured in the prod chatbot
  '/local': onLocal,
  '/updateBotMenu': onUpdateBotMenu,
  '/generatePayload': onGeneratePayload,
  '/devHelp': onDevHelp,
  // @ts-ignore
  '/createIAP': generateIAPLink,
};
