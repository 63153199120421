import './TiktokItemTransactionBubbles.scss';
import { app } from '../../../../data/Controllers/AppController';
import {
  OffchainTx,
  OnchainTx,
  TxType,
} from '../../../../replicant/features/tradingMeme/tradingMeme.schema';
import { largeIntegerToLetter } from '../../../../replicant/utils/numbers';
import { Meme } from '../../../../replicant/features/tradingMeme/tradingMeme.getters';
import { useEffect, useRef, useState } from 'react';
import { resolveProfilePicture } from '../../../../replicant/features/game/game.getters';
import { getOnchainTransactionLabel } from '../../../../data/utils';
import { HP } from '../../../../replicant/lib/HighPrecision';

// =================================================
// Tiktok Item Transactions

interface TiktokItemTransactionBubblesProps {
  memeState: Meme | undefined;
  selected: boolean;
}

const maxRolloutCount = 13;

export const TiktokItemTransactionBubbles = ({
  memeState,
  selected,
}: TiktokItemTransactionBubblesProps) => {
  // displayed transactions will act as if happening in realtime
  const txDisplayCount = 1;

  const minDelay = 1000;
  let maxDelay = 2000;

  const lastOnchainTxs = memeState?.onchainTxs.slice(-maxRolloutCount) || [];
  const lastOffchainTxs = memeState?.offchainTxs.slice(-maxRolloutCount) || [];

  const allTxs: (OnchainTx | OffchainTx)[] = [
    ...lastOnchainTxs,
    ...lastOffchainTxs,
  ];

  allTxs.sort((a, b) => {
    return a.createdAt - b.createdAt;
  });

  const lastTx = allTxs[allTxs.length - 1];
  const performedLastTx = allTxs.length > 0 && lastTx.userId === app.state.id;
  const initialStartIndex = performedLastTx
    ? txDisplayCount
    : Math.min(maxRolloutCount, allTxs.length);

  const [startIndex, setStartIndex] = useState<number>(initialStartIndex);
  const timeoutRef = useRef<number | null>(null);

  // Reset startIndex when the component mounts or transactions change
  useEffect(() => {
    setStartIndex(initialStartIndex);
  }, [initialStartIndex]);

  useEffect(() => {
    if (!selected) {
      return;
    }

    // Function to schedule the next update with a random delay
    const scheduleNextUpdate = () => {
      // Generate a random delay between minDelay and maxDelay
      const delay = minDelay + Math.random() * (maxDelay - minDelay);

      maxDelay += 1000;

      timeoutRef.current = window.setTimeout(() => {
        setStartIndex((prevIndex) => {
          const newIndex = prevIndex - 1;
          return Math.max(newIndex, txDisplayCount);
        });

        if (startIndex === txDisplayCount) {
          // reached the end of the list of transactions
          // note that we are not refreshing to fetch latest transactions as those do not happen frequently enough
          return;
        }

        // Schedule the next update recursively
        scheduleNextUpdate();
      }, delay);
    };

    // Start the initial update
    scheduleNextUpdate();

    // Cleanup function to clear the timeout on component unmount
    return () => {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [initialStartIndex, lastTx?.createdAt, lastTx?.userId, selected]);

  const displayedTransactions = allTxs.slice(
    -startIndex,
    startIndex === txDisplayCount ? undefined : txDisplayCount - startIndex,
  );

  // 1. keep "final" array of React elements in a separate state,
  //    so we can control when they get replaced.
  const [renderedItems, setRenderedItems] = useState<JSX.Element[]>([]);

  // 2. also keep a small piece of state for which animation class is active
  const [animClass, setAnimClass] = useState<'slide-in' | 'slide-out'>(
    'slide-in',
  );

  // 3. build the new items array
  const newItems = displayedTransactions.map((tx, i) => {
    let opacity = 1;
    if (displayedTransactions.length > 1 && i === 1) opacity = 0.6;
    if (displayedTransactions.length > 2 && i === 0) opacity = 0.3;

    return (
      <TiktokItemTransactionButton
        key={`tx_${tx.createdAt}_${i}`}
        index={i}
        opacity={opacity}
        tx={tx}
        ticker={memeState?.ticker}
      />
    );
  });

  // 4. whenever displayedTransactions changes, trigger the "slide out → replace items → slide in"
  useEffect(() => {
    // start by sliding out old items
    setAnimClass('slide-out');

    // wait for the slide-out animation to finish, e.g. 300 ms
    const timer = setTimeout(() => {
      // replace with the new items
      setRenderedItems(newItems);
      // slide them in
      setAnimClass('slide-in');
    }, 300);

    // cleanup
    return () => clearTimeout(timer);
  }, [memeState?.id, newItems[0]?.key, newItems[0]?.props.tx.txType]);

  // 5. on first mount, just set the items once
  useEffect(() => {
    setRenderedItems(newItems);
  }, [memeState?.id]);

  return (
    <div className="tiktok-item-transaction-bubbles">
      <div className={`tiktok-item-transaction-bubbles-buttons ${animClass}`}>
        {renderedItems}
      </div>
    </div>
  );
};

// =================================================
// Tiktok Transaction Item

interface TiktokItemTransactionButtonProps {
  index: number;
  opacity: number;
  tx: OnchainTx | OffchainTx;
  ticker: string | undefined;
}

export const TiktokItemTransactionButton = ({
  index,
  opacity,
  tx,
  ticker,
}: TiktokItemTransactionButtonProps) => {
  // console.warn('>>> tx', tx);

  // const onTapTransaction = () => {
  //   // open tiktok transactions drawer
  //   app.ui.drawer.show({
  //     id: 'drawerTiktokTransactions',
  //     hideClose: true,
  //     // noModal: true,
  //   });
  // };

  const tokenAmount = (tx as OnchainTx).tokenAmount;
  const pointAmount = (tx as OffchainTx).pointAmount;
  const isOnchain = tokenAmount ? true : false;

  const txAmountStr = isOnchain ? tokenAmount : pointAmount;

  const txType = tx.txType as TxType;
  const txAmount = largeIntegerToLetter(HP(txAmountStr).toNumber(), 4);
  const txAmountLabel = `${txAmount} $${ticker} ${isOnchain ? '' : 'points'}`;
  const txLabel = getOnchainTransactionLabel(txType);

  return (
    <div
      className={`btn tiktok-item-transaction-bubble-button ${txType}`}
      style={{ opacity: opacity.toString() }}
      // onClick={onTapTransaction}
    >
      {/* debug index */}
      {/* <div className={`label-index`}>{index}</div> */}

      <div className="tiktok-item-transaction-bubble-box">
        {/* token icon */}
        <div className="icon">
          <img src={resolveProfilePicture(tx.userId, tx.userImage)} />
        </div>
        <div className="info">
          {/* user name */}
          <div className="label name">{tx.userName}</div>

          <div className="tiktok-item-transaction-bubble-box">
            {/* transaction type */}
            <div className={`label type ${txLabel}`}>{txLabel}</div>

            {/* transaction value */}
            <div className="label coins">{txAmountLabel}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

// =================================================
