import {
  AnalyticsPayload,
  SB,
  TelegramMessage,
  renderTemplate,
  renderTemplatesWithAssets,
} from '@play-co/replicant';
import * as ruleset from './chatbot.ruleset';
import config from '../game/game.config';
import versionJson from './version.json';
import { booleanRoll } from '../../utils/numbers';
import { InlineKeyboardButton } from '../../telegram.bot.types';

export function createPlayButtonContent(
  callToAction: string,
  payload: AnalyticsPayload,
  userId?: string,
) {
  const url = new URL(config.playUrl);

  if (userId) {
    url.searchParams.set('uid', userId);
  }

  if (payload !== undefined) {
    url.searchParams.set('payload', JSON.stringify(payload));
  }

  // Set cache buster param
  if (versionJson) {
    url.searchParams.set('version', versionJson.version);
  }

  return {
    type: 'web_app',
    text: callToAction,
    web_app: { url: url.toString() },
  };
}

export function createPlayButton(
  callToAction: string,
  userId: string,
  payload: AnalyticsPayload,
) {
  return [[createPlayButtonContent(callToAction, payload, userId)]];
}

const webAppCallToAction = renderTemplate({
  args: SB.object({
    caption: SB.string(),
    callToAction: SB.string(),
    url: SB.string(),
  }),
  renderers: {
    telegram: ({ args }) => {
      const { caption, callToAction, url } = args;
      return {
        text: caption,
        reply_markup: {
          inline_keyboard: [
            [
              {
                text: callToAction,
                web_app: { url },
              },
            ],
          ],
        },
      };
    },
  },
});

const webAppCallsToAction = renderTemplate({
  args: SB.object({
    caption: SB.string(),
    buttons: SB.array(
      SB.object({
        callToAction: SB.string(),
        url: SB.string(),
      }),
    ),
  }),
  renderers: {
    telegram: ({ args }) => {
      const { caption, buttons } = args;
      return {
        text: caption,
        reply_markup: {
          inline_keyboard: buttons.map((button) => [
            {
              text: button.callToAction,
              web_app: { url: button.url },
            },
          ]),
        },
      };
    },
  },
});

function createTelegramMessage(options: {
  message: string;
  mediaUrl: string | undefined;
  markup: {
    inline_keyboard: InlineKeyboardButton[][];
  };
}) {
  const { message, mediaUrl, markup } = options;

  if (!mediaUrl) {
    return {
      text: message,
      reply_markup: markup,
    };
  }

  const extension = mediaUrl.split('.').pop();

  switch (extension) {
    case 'jpg':
    case 'jpeg':
    case 'webp':
    case 'png':
      return {
        photo: mediaUrl,
        caption: message,
        reply_markup: markup,
      };
    case 'gif':
      return {
        animation: mediaUrl,
        caption: message,
        reply_markup: markup,
      };
    case 'mp4':
    case 'video':
      return {
        video: mediaUrl,
        caption: message,
        reply_markup: markup,
      };
    default:
      throw new Error(
        `Unsupported extension: ${extension} for notification mediaUrl "${mediaUrl}"`,
      );
  }
}

function renderGenericTemplate<
  T extends { [token: string]: SB.Schema<string> },
>(key: ruleset.NotificationKey, tokens?: T) {
  const { message } = ruleset.notifications[key];

  return renderTemplate({
    args: SB.object({
      userId: SB.string(),
      tokens: SB.object(tokens || {}).optional(),
      mediaUrl: SB.string(),
    }),
    renderers: {
      telegram: ({ args, payload }) => {
        const { userId, tokens, mediaUrl } = args;

        const renderedMessage = tokens
          ? Object.entries(tokens).reduce(
              (acc, [key, value]) => acc.replace(`{${key}}`, value as string),
              message as string,
            )
          : message;

        const reply_markup = {
          inline_keyboard: createPlayButton(
            ruleset.callToActions.defaultPlayButton,
            userId,
            payload,
          ),
        };

        return createTelegramMessage({
          message: renderedMessage,
          mediaUrl,
          markup: reply_markup,
        });
      },
    },
  });
}

function renderFullGenericTemplate() {
  return renderTemplate({
    args: SB.object({
      userId: SB.string(),
      message: SB.string(),
      mediaUrl: SB.string(),
    }),
    renderers: {
      telegram: ({ args, payload }) => {
        const { userId, message, mediaUrl } = args;

        const reply_markup = {
          inline_keyboard: createPlayButton(
            ruleset.callToActions.defaultPlayButton,
            userId,
            payload,
          ),
        };

        return createTelegramMessage({
          message,
          mediaUrl,
          markup: reply_markup,
        });
      },
    },
  });
}

export const notifications = renderTemplatesWithAssets({}, {
  fullGeneric: renderFullGenericTemplate(),
  reengagement1: renderGenericTemplate('reengagement1'),
  reengagement2: renderGenericTemplate('reengagement2'),
  reengagement3: renderGenericTemplate('reengagement3'),
  reengagement4: renderGenericTemplate('reengagement4'),
  reengagement5: renderGenericTemplate('reengagement5'),
  reengagement6: renderGenericTemplate('reengagement6'),
  reengagement7: renderGenericTemplate('reengagement7'),
  reengagement8: renderGenericTemplate('reengagement8'),
  reengagement9: renderGenericTemplate('reengagement9'),
  energyRecharged: renderGenericTemplate('energyRecharged'),
  friendsJoined: renderGenericTemplate('friendsJoined', {
    friendName: SB.string(),
    friendCount: SB.string(),
    bonus: SB.string(),
  }),
  friendJoined: renderGenericTemplate('friendJoined', {
    friendName: SB.string(),
    bonus: SB.string(),
  }),
  friendIncrementalLeague: renderGenericTemplate('friendIncrementalLeague', {
    friendName: SB.string(),
    bonus: SB.string(),
    league: SB.string(),
  }),
  newQuest: renderGenericTemplate('newQuest'),
  teamRankUp: renderGenericTemplate('teamRankUp'),
  teamRankDown: renderGenericTemplate('teamRankDown'),
  autobotTimesUp: renderGenericTemplate('autobotTimesUp'),
  offchainTradingCardPriceUp: renderGenericTemplate(
    'offchainTradingCardPriceUp',
    {
      tokenName: SB.string(),
      priceChange: SB.string(),
      price: SB.string(),
    },
  ),
  offchainTradingCardPriceDown: renderGenericTemplate(
    'offchainTradingCardPriceDown',
    {
      tokenName: SB.string(),
      priceChange: SB.string(),
      price: SB.string(),
    },
  ),
  offchainTradingFomoRunaway: renderGenericTemplate(
    'offchainTradingFomoRunaway',
    {
      tokenName: SB.string(),
      tokenTicker: SB.string(),
      priceChange: SB.string(),
    },
  ),
  offchainTradingFomoPopular: renderGenericTemplate(
    'offchainTradingFomoPopular',
    {
      tokenName: SB.string(),
      tokenTicker: SB.string(),
      priceChange: SB.string(),
    },
  ),
  startCmdGroup: renderTemplate({
    args: SB.object({
      caption: SB.string(),
      callToAction: SB.string(),
      botLink: SB.string(),
    }),
    renderers: {
      telegram: ({ args }) => {
        const { caption, callToAction, botLink } = args;
        return {
          text: caption,
          reply_markup: {
            inline_keyboard: [
              [
                {
                  text: callToAction,
                  url: botLink,
                },
              ],
            ],
          },
        };
      },
    },
  }),
  startCmdPrivate: renderTemplate({
    args: SB.object({
      caption: SB.string(),
      mediaUrl: SB.string().optional(),
    }),
    renderers: {
      telegram: ({ args, payload }) => {
        const callToActions = ruleset.callToActions;
        const btnList = [
          [
            createPlayButtonContent(
              callToActions.startCmdPrivate.play,
              payload,
            ),
          ],
          [
            {
              text: callToActions.startCmdPrivate.community,
              url: ruleset.communityGroup,
            },
          ],
          [
            {
              text: callToActions.startCmdPrivate.howToPlay,
              web_app: { url: ruleset.howToPlayGuideUrl },
            },
          ],
          [
            {
              text: callToActions.startCmdPrivate.tos,
              url: ruleset.termsOfServiceUrl,
            },
          ],
        ];

        return createTelegramMessage({
          message: args.caption,
          mediaUrl: args.mediaUrl,
          markup: {
            inline_keyboard: btnList,
          },
        });
      },
    },
  }),
  startAICmdPrivate: renderTemplate({
    args: SB.object({
      caption: SB.string(),
      mediaUrl: SB.string().optional(),
    }),
    renderers: {
      telegram: ({ args, payload }) => {
        const callToActions = ruleset.callToActions;
        const btnList = [
          [createPlayButtonContent(callToActions.startAIPrivate, payload)],
        ];

        return createTelegramMessage({
          message: args.caption,
          mediaUrl: args.mediaUrl,
          markup: {
            inline_keyboard: btnList,
          },
        });
      },
    },
  }),
  inviteCmd: renderTemplate({
    args: SB.object({
      caption: SB.string(),
      callToAction: SB.string(),
      referralLink: SB.string(),
    }),
    renderers: {
      telegram: ({ args }) => {
        const { caption, callToAction, referralLink } = args;
        return {
          text: caption,
          reply_markup: {
            inline_keyboard: [
              [
                {
                  text: callToAction,
                  url: referralLink,
                },
              ],
            ],
          },
        };
      },
    },
  }),
  howToPlayCmd: webAppCallToAction,
  localCmd: webAppCallsToAction,
  giftMemeToken: renderGenericTemplate('giftMemeToken', {
    tokenName: SB.string(),
  }),
} satisfies { [key in ruleset.NotificationKey | ruleset.CmdReplyMessageTypes | 'fullGeneric']: any });

export const templates = { ...notifications };
export default templates;

export function extractCreativeAssetID(
  creativeUrl?: string,
  nonCuratedCreativeId?: string,
) {
  if (creativeUrl === undefined) {
    return nonCuratedCreativeId;
  }

  const isCuratedCreative = creativeUrl.includes(ruleset.notificationFolderUrl);
  const creativeId =
    isCuratedCreative || !nonCuratedCreativeId
      ? creativeUrl
      : nonCuratedCreativeId;

  const creativeIdBreakdown = creativeId.split('/');
  return creativeIdBreakdown[creativeIdBreakdown.length - 1];
}

export function getNotificationMedia(key: ruleset.NotificationKey) {
  const medias = ruleset.notifications[key].medias;
  const mediaIdx = Math.floor(Math.random() * medias.length);
  const media = medias[mediaIdx];
  return `${ruleset.notificationFolderUrl}${media}`;
}

export function getMediaUrl(
  key: ruleset.NotificationKey,
  customMediaUrLOpts?: {
    customUrl: string | undefined;
    odds: number;
  },
) {
  if (
    customMediaUrLOpts &&
    customMediaUrLOpts.customUrl &&
    booleanRoll(customMediaUrLOpts.odds)
  ) {
    return customMediaUrLOpts.customUrl;
  }

  return getNotificationMedia(key);
}
