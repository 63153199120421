import { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { assets } from '../../../../../assets/assets';
import {
  useAppUpdates,
  app,
} from '../../../../../data/Controllers/AppController';
import { MemesEvents } from '../../../../../data/Controllers/Memes/MemesController';
import {
  TxType,
  CurrencyType,
} from '../../../../../replicant/features/tradingMeme/types';
import { HP, HighPrecision } from '../../../../../replicant/lib/HighPrecision';
import {
  largeNumberToLetter,
  largeIntegerToLetter,
  roundDecimals,
} from '../../../../../replicant/utils/numbers';
import { DottedSlider } from '../../../../shared/DottedSlider/DottedSlider';
import { TradingInputNumeric } from '../../../TradingPage/TradingInputNumeric/TradingInputNumeric';
import { getCurrencyIcon, getCurrencyName } from '../helpers';
import { TradingTokenFooterButton } from './TTFBuySellBtn';
import { TradingTokenFooterCreateButton } from './TTFCreateBtn';
import { TradingTokenFooterFeeMessage } from './TTFFeeMessage';
import { TonEvents } from '../../../../../data/TonProvider/TonController';

interface PropsFooterForm {
  footerMode: 'normal' | 'create';
  txType: TxType;
  currencyType: CurrencyType;
}

interface BalanceDisplayOptions {
  balance: number;
  isBuy: boolean;
  isTxCoinCurrency: boolean;
}

const getDisplayBalance = ({
  balance,
  isBuy,
  isTxCoinCurrency,
}: BalanceDisplayOptions): string => {
  if (isBuy) {
    return isTxCoinCurrency
      ? largeIntegerToLetter(balance)
      : largeNumberToLetter(balance, 3);
  }
  return isTxCoinCurrency
    ? largeNumberToLetter(balance)
    : largeNumberToLetter(balance, 3);
};

export const TradingTokenFooterForm = ({
  footerMode,
  txType,
  currencyType,
}: PropsFooterForm) => {
  useAppUpdates({
    id: 'OffchainTokenFooterForm',
    listener: app.memes.attachEventListener(MemesEvents.TradingOnTxUpdate),
  });

  const { t } = useTranslation();

  const { trading, currentMeme } = app.memes;

  const [canShowSlider, setCanShowSlider] = useState(true);

  const [sliderValue, setSliderValue] = useState(0);

  const [balance, setBalance] = useState('0');

  const maxAmount = Number(balance);

  const isTxCoinCurrency = currencyType === 'points';

  // this prevents input from displaying decimals and keyboard from displaying dot key
  // basic rule:
  // - buy tokens can have decimals since you buy TON value,
  // - sell tokens cannot since you sell token units.
  // - buy sell points cannot either, since points are always units
  const isNumericInput =
    currencyType === 'points' ||
    (trading.txCurrency === 'tokens' && !trading.isBuy);

  const spendAmount = useMemo(() => {
    return trading.tx?.send.toNumber() ?? 0;
  }, [trading.tx?.send, trading.txCurrency, trading.isBuy]);

  const displayBalance = useMemo(
    () =>
      getDisplayBalance({
        balance: Number(balance),
        isBuy: trading.isBuy,
        isTxCoinCurrency,
      }),
    [balance, trading.isBuy, isTxCoinCurrency],
  );

  const updateBalance = useCallback(async () => {
    try {
      const isPointsCurrency = trading.txCurrency === 'points';

      if (trading.isBuy) {
        setBalance(
          isPointsCurrency ? app.state.balance.toString() : app.ton.balance,
        );
        return;
      }

      const meme = currentMeme.meme;
      if (!meme) {
        setBalance('0');
        return;
      }

      if (isPointsCurrency) {
        const memePointBalance = meme.id
          ? currentMeme.offchainHoldings?.pointAmount ?? '0'
          : '0';
        setBalance(memePointBalance);
      } else {
        if (meme.isMinted) {
          const tradingBalance = await trading.getBalance(meme.id ?? '');
          setBalance(tradingBalance.toString());
        } else {
          setBalance('0');
        }
      }
    } catch (error) {
      console.error('Failed to update balance:', error);
      setBalance('0'); // Fallback to 0 in case of error
    }
  }, [trading.txCurrency, trading.isBuy, currentMeme.meme?.id]);

  const updateTradingAmount = useCallback(
    (percentage: number) => {
      const clampedPercentage = Math.min(Math.max(percentage, 0), 1);
      const maxAmount =
        trading.txCurrency === 'points'
          ? Number(balance)
          : HP(balance).toNumber();

      const rawAmount =
        clampedPercentage >= 1.0 ? maxAmount : maxAmount * clampedPercentage;

      const finalAmount =
        trading.txCurrency === 'points'
          ? Math.floor(rawAmount)
          : trading.isBuy // tokens
          ? roundDecimals(rawAmount, 8) // buying with fractional ton
          : Math.floor(rawAmount); // selling whole tokens

      app.memes.trading.updateAmount(HP(finalAmount));
    },
    [balance, trading.txCurrency, trading.isBuy],
  );

  const getSpendAmountPercentage = (spendAmount: HighPrecision) => {
    const percentage =
      maxAmount > 0 ? spendAmount.div(maxAmount).toNumber() : 0;
    const clampedPercentage = Math.min(Math.max(percentage, 0), 1);
    return clampedPercentage;
  };

  const setSpendAmount = useCallback(
    (spendAmount: HighPrecision) => {
      const spendAmountPercentage = getSpendAmountPercentage(spendAmount);
      const isSliderValueChanged = sliderValue !== spendAmountPercentage;
      if (isSliderValueChanged) {
        setSliderValue(spendAmountPercentage);
      }
    },
    [maxAmount],
  );

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        await updateBalance();
      } catch (error) {
        console.error('Failed to fetch balance:', error);
      }
    };

    fetchBalance();
  }, [currentMeme.meme?.id, trading.txCurrency, updateBalance]);

  useEffect(() => {
    if (trading.tx?.send) {
      setSpendAmount(trading.tx.send);
    }
  }, [trading.tx?.send, setSpendAmount]);

  useAppUpdates({
    id: 'TonBalance',
    listener: () => {
      const balanceListener = app.ton.attachEventListener(
        TonEvents.OnBalanceUpdate,
      )(() => {
        setBalance(app.ton.balance);
      });

      return () => {
        balanceListener();
      };
    },
  });

  const onSliderChange = useCallback(
    (percentage: number) => {
      const clampedPercentage = Math.min(Math.max(percentage, 0), 1);
      setSliderValue(clampedPercentage);
      updateTradingAmount(clampedPercentage);
    },
    [updateTradingAmount],
  );

  const onInputFocus = (focused: boolean) => {
    setCanShowSlider(!focused);
  };

  const onInputUpdate = useCallback(
    (value: number) => {
      const spendAmountPercentage = getSpendAmountPercentage(HP(value));
      updateTradingAmount(spendAmountPercentage);
    },
    [getSpendAmountPercentage, updateTradingAmount],
  );

  const onTapPurchase = useCallback(() => {
    app.ui.drawer.close();
    app.memes.trading.goToTxConfirmation();
  }, []);

  if (!app.memes.trading.tx) {
    return null;
  }

  const showTradingFee =
    app.memes.trading.tx.finalTonAmount && app.memes.trading.tx.send.gt(0);

  // ==============================================================================
  // #region Form Elms
  return (
    <div className="trading-token-footer-form-area">
      {/* header */}
      <div className="buysell-header">
        {/* balance + slippage */}
        <div className="buysell-header-row">
          <div className="buysell-header-balance">
            <div className="buysell-header-balance-label">
              {t('buysell_available_balance')}
            </div>
            <div className="buysell-header-balance-value">{displayBalance}</div>
            <div className="buysell-header-balance-icon">
              <img
                src={getCurrencyIcon(currencyType, trading.txMemeImage, txType)}
              />
            </div>
          </div>
          <div className="buysell-header-slippage">{'Set max slippage'}</div>
        </div>

        {/* ticker or coin icon */}
        <div className={`trading-token-footer-form`}>
          {/* numeric input */}
          <TradingInputNumeric
            type={isNumericInput ? 'numeric' : 'decimal'}
            value={spendAmount}
            maxValue={maxAmount}
            icon={assets.button_x}
            pushContentUp={280}
            onInputFocus={onInputFocus}
            onAmountChange={onInputUpdate}
            integerOnly={isNumericInput}
            decimalPlaces={isNumericInput ? 0 : 8}
          />

          {/* ton | points */}
          <div className="currency-type">
            <div className="currency-type-label">
              {getCurrencyName(currencyType, trading.txMemeTicker, txType)}
            </div>
            <div className="currency-type-icon">
              <img
                src={getCurrencyIcon(currencyType, trading.txMemeImage, txType)}
              />
            </div>
          </div>
        </div>
      </div>

      {canShowSlider && (
        <DottedSlider
          initialValue={sliderValue}
          labels={['0%', '25%', '50%', '75%', '100%']}
          onSliderChange={onSliderChange}
        />
      )}

      {showTradingFee && (
        <div className={`trading-token-footer-fees centered`}>
          <TradingTokenFooterFeeMessage
            currencyType={currencyType}
            tx={app.memes.trading.tx}
          />
        </div>
      )}

      {footerMode === 'normal' ? (
        <TradingTokenFooterButton
          footerMode={footerMode}
          txType={txType}
          currencyType={currencyType}
          onClick={onTapPurchase}
        />
      ) : (
        <TradingTokenFooterCreateButton />
      )}
    </div>
  );
};
