import { useState, ChangeEvent } from 'react';
import { app } from '../data/Controllers/AppController';
import { Address } from '@ton/core';

const style = {
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    padding: '20px',
    flexDirection: 'column',
  },
  input: {},
  btn: {},
  apiKey: {
    textDecoration: 'underline',
  },
  info: {
    fontSize: 10,
  },
} as const;

const safeStrToAddress = (maybeAddress: string) => {
  try {
    const address = Address.parse(maybeAddress);
    return address;
  } catch {
    return maybeAddress;
  }
};

export const AiApp = () => {
  const apiKey = app.state.ai?.apiKey;

  const [walletAddress, setWalletAddress] = useState(
    app.state.wallet[0]?.address ?? '',
  );

  const maybeAddress = safeStrToAddress(walletAddress);
  const isValidAddress = Address.isAddress(maybeAddress);

  const sureAddress = isValidAddress ? Address.parse(walletAddress) : null;
  const address = sureAddress ? sureAddress.toRawString() : null;
  const prettyAddress = sureAddress ? sureAddress.toString() : walletAddress;

  const hasPreviousWalletAddress = Boolean(app.state.wallet[0]?.address);
  const isDirty = app.state.wallet[0]?.address !== address;

  const onInputChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setWalletAddress(evt.target.value);
  };

  const forceUpdate = () => {
    const value = walletAddress;
    setWalletAddress(walletAddress + ' ');
    setTimeout(() => {
      setWalletAddress(value);
    });
  };

  const getWalleInfo = () => {
    if (!address) {
      return;
    }
    return {
      app_name: 'gemz-ai-wallet',
      address,
    };
  };

  const onGenerateAPIKey = async () => {
    const walletInfo = getWalleInfo();
    if (walletInfo) {
      app.invoke.generateApiKey(walletInfo).then(forceUpdate);
    }
  };

  const onUpdateWallet = () => {
    const walletInfo = getWalleInfo();
    if (walletInfo) {
      app.replicant.invoke.setWalletInfo(walletInfo).then(forceUpdate);
    }
  };

  return (
    <div style={style.container}>
      <h1>Register AI</h1>
      <label>Wallet Address</label>
      <input
        value={prettyAddress}
        onChange={onInputChange}
        style={style.input}
      />
      <p style={style.info}>
        <i>
          <b>
            (This has to match the same wallet as the mnemonic used in the
            plugin config)
          </b>
        </i>
      </p>
      {hasPreviousWalletAddress && (
        <button
          style={style.btn}
          onClick={onUpdateWallet}
          disabled={!isValidAddress || !isDirty}
        >
          Update Wallet Address
        </button>
      )}
      <br />
      <h3>API KEY:</h3>
      {apiKey ? (
        <p style={style.apiKey}>{apiKey}</p>
      ) : (
        <button
          style={style.btn}
          onClick={onGenerateAPIKey}
          disabled={!isValidAddress}
        >
          Generate API Key
        </button>
      )}
    </div>
  );
};
