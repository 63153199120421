import { HP } from '../../lib/HighPrecision';
import { DAY_IN_MS, HOUR_IN_MS, MIN_IN_MS, SEC_IN_MS } from '../../utils/time';
import { stage } from '../game/game.config';
import { OTTG } from '../game/player.schema';
import { toNano } from './utils';
// import bondingCurveCfgs from '../powerups/airtable/offchainMemeTradingBondingCurve';

// const [bondingCurveCfg] = bondingCurveCfgs;

export const REPLICANT_UPLOAD_LENGTH_LIMIT = 940000;

export const claimDayDuration = stage === 'prod' ? DAY_IN_MS : 3 * MIN_IN_MS;
export const claimLockDuration =
  stage === 'prod' ? 12 * HOUR_IN_MS : 2 * MIN_IN_MS;

export const minTxVerificationDelayMs = 30_000;

export const memeSearchCount = 10;
export const MemeNotifDelay = 2000;

// maximum duration it will take for a transaction happening outside the app to show inside the app
export const memeTxUpdateMinDelay =
  stage === 'prod' ? 120 * SEC_IN_MS : 15 * SEC_IN_MS;

export const pointEpsilon = 1;

// @WARNING: DO NOT MODIFY WITHOUT VERSIONING THE REPLICANT MESSAGES WHERE THEY ARE USED!
const maxOnchainSupply = BigInt(1_000_000_000);
export const onchainCurveConfig = {
  maxTargetPrice: BigInt(10_000),
  initialPrice: BigInt(1),
  tonGraduationAmount: BigInt(1_000),
  maxSupply: maxOnchainSupply,
  graduationSupplyThreshold:
    stage === 'prod' ? maxOnchainSupply : BigInt(25_000_000),
  gemzOwnerSupply: stage === 'prod' ? BigInt(300_000_000) : BigInt(3_000_000),
};

export const initialTokenPrice = HP(onchainCurveConfig.initialPrice)
  .div(1_000_000_000)
  .toString();

export const totalDailyTokenAllocation = HP(
  (Number(onchainCurveConfig.maxSupply) / 90) * 0.05,
);

export const totalGradTokenAllocation = HP(
  Number(onchainCurveConfig.maxSupply) * 0.05,
);

export const txFees = {
  slippageMultiplier: 1.025,
  buyFee: toNano(0.3),
  sellFee: toNano(0.25),
  claimDexToken: toNano(0.25),
};

// @WARNING: DO NOT MODIFY WITHOUT VERSIONING THE REPLICANT MESSAGES WHERE THEY ARE USED!
export const curveConfig = {
  exponent: HP(0.08 / 1_000_000), // highPrecision(bondingCurveCfg.exponent),
  startPrice: HP(1_000_000), // highPrecision(bondingCurveCfg.initial_price),
  stepSize: 1, // highPrecision(bondingCurveCfg.step_size),
  // microModifier: highPrecision(0.000000001),
};

// @WARNING: DO NOT MODIFY WITHOUT VERSIONING THE REPLICANT MESSAGES WHERE THEY ARE USED!
export const txConfig = {
  fee: 0.01, // 1%
  defaultMaxSlippage: HP(1.02), // 2%,
  clientSlippage: 1.001, // 0.1%
};

// @WARNING: DO NOT MODIFY WITHOUT VERSIONING THE REPLICANT MESSAGES WHERE THEY ARE USED!
export const pointTxConfig = {
  maxModifier: 1,
  modifierSlope: 3,
};

export const maxOffchainTxCount = 100;
export const maxOnchainTxCount = 100;

export const maxTokenAllTimeSliceCount = 500;
export const maxPortfolioAllTimeSliceCount = 500;

export const fixedSliceTimeWindows = ['hour24', 'day7', 'day30'] as const;
export type FixedSliceTimeWindows = (typeof fixedSliceTimeWindows)[number];
export type SliceTimeWindows = FixedSliceTimeWindows | 'allTime';

export interface SliceConfig {
  windowId: SliceTimeWindows;
  interval: number;
  window: number;
}

export const tokenPriceSliceConfigs: Record<
  FixedSliceTimeWindows,
  SliceConfig
> = {
  hour24: {
    windowId: 'hour24',
    interval: 5 * MIN_IN_MS, // 288 intervals for 1 day
    window: 1 * DAY_IN_MS,
  },
  day7: {
    windowId: 'day7',
    interval: 30 * MIN_IN_MS, // 336 intervals for 7 days
    window: 7 * DAY_IN_MS,
  },
  day30: {
    windowId: 'day30',
    interval: 120 * MIN_IN_MS, // 360 intervals for 30 days
    window: 30 * DAY_IN_MS,
  },
};

export const fixedSliceStatsTimeWindows = ['hour24'] as const;
export type FixedSliceStatsTimeWindows =
  (typeof fixedSliceStatsTimeWindows)[number];

export const tokenStatsSliceConfigs: Record<
  FixedSliceStatsTimeWindows,
  SliceConfig
> = {
  hour24: {
    windowId: 'hour24',
    interval: 15 * MIN_IN_MS, // 96 intervals for 1 day
    window: 1 * DAY_IN_MS,
  },
};

export const portfolioPriceSliceConfigs: Record<
  FixedSliceTimeWindows,
  SliceConfig
> = {
  hour24: {
    windowId: 'hour24',
    interval: 30 * MIN_IN_MS, // 48 intervals for 1 day
    window: 1 * DAY_IN_MS,
  },
  day7: {
    windowId: 'day7',
    interval: 3 * HOUR_IN_MS, // 56 intervals for 7 days
    window: 7 * DAY_IN_MS,
  },
  day30: {
    windowId: 'day30',
    interval: 12 * HOUR_IN_MS, // 60 intervals for 30 days
    window: 30 * DAY_IN_MS,
  },
};

export const shortestPortofolioUpdateInterval =
  portfolioPriceSliceConfigs.hour24.interval;
export const longestPortofolioUpdateInterval =
  portfolioPriceSliceConfigs.day30.window;

export const RESET_SEASON_2_LABEL = 'RESET_SEASON_2_LABEL';

export const COMPLETE_SEASON_2_LABEL = 'COMPLETE_SEASON_2_LABEL';

export const season2StartTime = 1724994000839; // Fri Aug 30 2024 14:00:00 GMT+0900 (Japan Standard Time)

export const failedTxLifespan = MIN_IN_MS;
export const txVerificationTimeout = 10 * SEC_IN_MS;

export const DEFAULT_FLAGGED_IMAGE =
  'https://notgemz.cms.gemz.fun/media/flagged.png';

export const maxFollowingCount = 200;

export const IAP_TG_STARS_TIMEOUT_MS = 60 * SEC_IN_MS;

export const MEMES_UTC_EPOCH = 1729555200000; // 10/22/2024, 00:00:00 UTC

// ====================================================
// ==================== TAP GAME ======================
// ====================================================
const farmingPointsPerHour = 3600;

const maxFarmingPoints = 40_000;

const tappingMaxTickets = 3;

const initialTokenState: OTTG = {
  tickets: tappingMaxTickets,
  allTimeReferralKickBack: '0',
  dailyScore: 0,
};

const farmingDuration = HOUR_IN_MS * 8;

export const tmgRuleset = {
  // limits
  farmingLimit: 10, // up to 100 max
  creationLimit: 900,
  tappingLimit: 2000,
  holdingLimit: 2000,

  // farming
  farmingDuration,
  farmingPointsPerMs: maxFarmingPoints / farmingDuration,
  // energy
  energyMaxRefills: 3,
  energyRefillCooldown: HOUR_IN_MS * 8,
  energyMaxTotal: 1000,
  // tapping
  tappingScorePerTap: 35,
  tappingOverlayCooldown: SEC_IN_MS * 2,
  tappingMaxTickets,
  tappingMaxScore: 30_000,
  // initial state
  initialTokenState,
  MEMES_UTC_EPOCH,
};

export const giftTokenCoinAmount = 100_000;

export const memeGiftRuleset = {
  giftReward: 1_250_000,
  consolationCoinReward: 5_000,
};

export const creatorGraduationTonReward = 15;
