import './DrawerProfileSeasonInfo.scss';
import { assets } from '../../../assets/assets';
import { useTranslation } from 'react-i18next';
import { formatPrice } from '../../../replicant/utils/numbers';
import {
  getUserStartingSeason,
  getSeasonReward,
} from '../../../replicant/features/game/game.getters';
import { app } from '../../../data/Controllers/AppController';
import { getWalletConnected } from '../../../data/TonProvider/hooks';
import { ButtonWallet } from '../../pages/ProfilePage/ProfilePage';

export const DrawerProfileSeasonInfo = () => {
  const { t } = useTranslation();

  // players that started playing on s1 or s2 will be shown rewards
  const startingSeason = getUserStartingSeason(app.state);
  const showRewards = startingSeason <= 2;

  const isConnected = getWalletConnected();

  return (
    <div className="drawer-profile-season-info">
      {showRewards && (
        <div className="drawer-layout profile-season-info">
          <div className="drawer-header">
            {/* image */}
            <div className="drawer-header-icon">
              <img src={assets.coins_500k} height={'140px'} />
            </div>

            {/* title */}
            <div className="profile-season-info-title">
              {t('og_player_info_title')}
            </div>

            {/* season rows */}
            <div className="profile-season-info-rows">
              {startingSeason <= 1 && <SeasonInfoRow season={1} />}
              {startingSeason <= 2 && <SeasonInfoRow season={2} />}
            </div>
          </div>
        </div>
      )}

      <div className="profile-season-wallet">
        <div className="wallet-label">
          {isConnected ? 'Tap to disconnect wallet' : 'Tap to connect wallet'}
        </div>
        <ButtonWallet onClick={() => app.ui.drawer.close()} />
      </div>
    </div>
  );
};

const SeasonInfoRow = ({ season }: { season: number }) => {
  const { t } = useTranslation();

  const reward = getSeasonReward(app.state, season);

  return (
    <div className="profile-season-info-row">
      <div className="left">
        {t('og_player_info_season')} {season}
      </div>

      <div className="right">
        <div className="text">{formatPrice(reward)}</div>
        <img src={assets.coin} />
      </div>
    </div>
  );
};
