import { t } from 'i18next';

const style = {
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
export const RegionBanned = () => {
  return <div style={style}>{t('region_banned')}</div>;
};
