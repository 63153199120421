import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  app,
  useAppUpdates,
} from '../../../../../data/Controllers/AppController';
import {
  TxType,
  CurrencyType,
} from '../../../../../replicant/features/tradingMeme/types';
import { largeIntegerToLetter } from '../../../../../replicant/utils/numbers';
import { getCurrencyIcon, getCurrencyName } from '../helpers';
import { getWalletConnected } from '../../../../../data/TonProvider/hooks';
import { HP } from '../../../../../replicant/lib/HighPrecision';
import { UIEvents } from '../../../../../data/Controllers/UIController/UITypes';

export interface PropsFooterBuySellButton {
  footerMode: 'normal' | 'create';
  txType: TxType;
  currencyType: CurrencyType;
  onClick: () => void;
}

// note: this is used also from DrawerTradingTransactionConfirm
export const TradingTokenFooterButton = ({
  txType,
  currencyType,
  onClick,
}: PropsFooterBuySellButton) => {
  useAppUpdates({
    id: 'TradingTokenFooterButton',
    listener: app.ui.attachEventListener(UIEvents.OnGraduateUpdate),
  });

  const { tx, txCurrency, txMeme, txMemeTicker, txMemeImage } =
    app.memes.trading;

  const { t } = useTranslation();

  const [receive, setEstimate] = useState(HP(0));

  const receiveStr = tx?.receive.toString();

  const contractAddress = txMeme?.jettonContractAddress;

  useEffect(() => {
    app.memes.trading.getTxEstimate().then((newEstimate) => {
      setEstimate(newEstimate);
    });
  }, [contractAddress, receiveStr]);

  if (!tx) {
    return null;
  }

  const { isValid } = tx;

  const isConnected = getWalletConnected();

  let isSeeding = false;
  if (txMeme) {
    const isGraduated = txMeme.isGraduated && txMeme.dexContractAddress;
    if (isGraduated) {
      app.ui.removeGraduateMeme(txMeme.id);
    }

    isSeeding = app.ui.getIsGraduating(txMeme.id);
  }

  const isDisabled = isSeeding || !isValid || receive.lte(0);
  const isBuying = !isSeeding && txType.includes('buy');
  const isSelling = !isSeeding && txType.includes('sell');

  let amount = '-';

  if (receive.gte(0)) {
    amount = receive.toString();
    if (txType === 'buy') {
      amount = largeIntegerToLetter(receive.toNumber());
    } else {
      if (txCurrency === 'points') {
        amount = largeIntegerToLetter(receive.toNumber());
      } else {
        const usdValue = app.ton.getTonToUSDSync(receive.toString());
        amount = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(usdValue);
      }
    }
  }
  const onConnectWallet = () => {
    app.ton.connect();
  };

  const isUsdPreferred = true;
  const currencyName = getCurrencyName(
    currencyType,
    txMemeTicker,
    txType,
    'receive',
    isUsdPreferred,
  );
  const currencyIcon = getCurrencyIcon(
    currencyType,
    txMemeImage,
    txType,
    'receive',
    isUsdPreferred,
  );

  if (!isConnected && txCurrency === 'tokens') {
    return (
      <div
        className="btn btn-normal profile-button-wallet connect-wallet"
        onClick={onConnectWallet}
      >
        <div className="column">{'Connect Wallet'}</div>
      </div>
    );
  }

  return (
    <div
      className={`btn btn-normal button-buy ${isDisabled && 'disabled'}`}
      onClick={onClick}
    >
      <div className="content-buy">
        {isSeeding && (
          <>
            <div className="label">{`${t('trade_token_button_seeding')}`}</div>
          </>
        )}
        {isBuying && (
          <>
            <div className="label">{`${t('trade_token_button_buy')}`}</div>
            <div
              className="value"
              dangerouslySetInnerHTML={{ __html: amount }}
            ></div>
            <div className="label">{currencyName}</div>

            <div className="icon">
              <img src={currencyIcon} />
            </div>
          </>
        )}

        {isSelling && (
          <>
            <div className="label">{`${t('trade_token_button_sell_for')}`}</div>
            <div className="value">{amount} </div>
            <div className="label">{currencyName}</div>
            {currencyIcon && (
              <div className="icon">
                <img src={currencyIcon} />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
