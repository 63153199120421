import { useTranslation } from 'react-i18next';
import { assets } from '../../../assets/assets';
import { app, useAppUpdates } from '../../../data/Controllers/AppController';
import {
  FarmingSearchResult,
  ProfileEvents,
} from '../../../data/Controllers/ProfileController';
import { TradingSearchResult } from '../../../replicant/features/tradingMeme/tradingMeme.properties';
import {
  floorToSignificantDigits,
  formatPrice,
  largeNumberToLetter,
} from '../../../replicant/utils/numbers';
import { Tabs, TabStyle } from '../../shared/Tabs/Tabs';
import { Page } from '../Page';
import { ProfileItem } from './ProfileItem/ProfileItem';
import './ProfilePage.scss';
import { TonController } from '../../../data/TonProvider/TonController';
import { CreatorImage } from '../../shared/CreatorImage/CreatorImage';
import { getUserStartingSeason } from '../../../replicant/features/game/game.getters';
import { getWalletConnected } from '../../../data/TonProvider/hooks';
import { shortenString } from '../../../replicant/utils/strings';
import { ProfilePendingTx } from './ProfileItem/ProfilePendingTx';
import { UnconfirmedTx } from '../../../replicant/features/game/player.schema';
import { useEffect, useState } from 'react';
import { isTelegramWebview } from '../../../data/device';

export type ProfileTabV2 = 'Created' | 'Wallet' | 'Points';

// -----------------------------------------------------------------------------
// This method is used in order to set special responsive dimensions
// of tabs container, for Profile only

function getSectionHeightRelativeToScreen(
  margin: number = 0,
  extra: number = 0,
) {
  var style = getComputedStyle(document.body);
  const prop = style.getPropertyValue('--safe-height');

  let h = '100svh';
  if (prop) h = `calc(${prop} - 28px)`; // mobile has an extra of 28px

  return `calc(${h} - ${margin}px - 274px - 56px + ${extra}px)`;
}

// ====================================================================
// #region Page

export const ProfilePage = () => {
  const { t } = useTranslation();

  useAppUpdates({
    id: 'ProfilePage',
    listener: app.views.ProfilePage.attachEventListener(),
  });

  const { visible, hide } = app.views.ProfilePage;

  useAppUpdates({
    id: 'ProfilePage/ProfileEvents.OnUpdate',
    listener: app.profile.attachEventListener(ProfileEvents.OnUpdate),
    dep: visible,
  });

  const selectTab = (cat: ProfileTabV2) => {
    // record profile tab state in ui controller
    app.track('profile_tab_switch', {
      tab_name: cat,
    });
  };

  // get current profile
  const { current } = app.profile;

  // console.warn('>>> current profile', current);

  // is this our own profile or someone else's?
  const isSelf = current?.isSelf || false;
  const isConnected = getWalletConnected();
  const showWalletButton = isSelf && !isConnected;

  // get height of tabs content
  const offset = showWalletButton ? 56 : 22;
  const sectionHeight = getSectionHeightRelativeToScreen(0, offset);

  if (!visible) {
    return null;
  }

  if (!current) {
    hide();
    return null;
  }

  return (
    <Page id="profile" visible={visible}>
      <ProfileHeader isSelf={isSelf} showWalletButton={showWalletButton} />

      {/* tabs */}
      <Tabs<ProfileTabV2>
        tabStyle={TabStyle.Underline}
        height={40}
        initial={'Created'}
        // autoSelected={tab}
        onTabClick={selectTab}
        tabs={[
          {
            id: 'Created' as ProfileTabV2,
            name: t('profile_tab_created'), // 'Created',
            component: (
              <ProfileTokenList
                tab="Created"
                list={current.tokensCreated}
                sectionHeight={sectionHeight}
                isSelf={isSelf}
              />
            ),
          },
          // todo carles: for now Tokens/Points tabs will both display 'owned' tokens
          {
            id: 'Wallet' as ProfileTabV2,
            name: 'Wallet', // t('profile_tab_owned'), // 'Owned',
            component: (
              <ProfileTokenList
                tab="Wallet"
                list={current.tokensOwned}
                sectionHeight={sectionHeight}
                isSelf={isSelf}
              />
            ),
          },
          {
            id: 'Points' as ProfileTabV2,
            name: 'Points', // t('profile_tab_owned'), // 'Owned',
            component: (
              <ProfileTokenList
                tab="Points"
                list={current.pointsOwned}
                sectionHeight={sectionHeight}
                isSelf={isSelf}
              />
            ),
          },
        ]}
      />
    </Page>
  );
};

// ====================================================================
// #region Header

const ProfileHeader = ({
  isSelf,
  showWalletButton,
}: {
  isSelf: boolean;
  showWalletButton: boolean;
}) => {
  const { share, current } = app.profile;

  if (!current) {
    return null;
  }

  // get portfolio value and percent diff from profile controller
  const portfolioValue = current.portfolio.value;
  const portfolioDisaplayValue =
    portfolioValue > 0
      ? largeNumberToLetter(portfolioValue, 3, '$', 2)
      : '$0.00';

  const portfolioChangePercentage = current.portfolio.diff;
  const portfolioValueBefore =
    portfolioValue / (1 + portfolioChangePercentage / 100);
  const portfolioChangeAmount = portfolioValue - portfolioValueBefore;

  // const isShowingPortfolioAmountChanged = true;
  const isShowingPortfolioAmountChanged =
    portfolioValue > 0 && portfolioChangeAmount !== 0;

  const gradientColor =
    portfolioChangePercentage === 0
      ? 'white'
      : portfolioChangePercentage > 0
      ? 'green'
      : 'red';

  const onTapTonTest = () => {
    TonController.TestUIEnabled = true;
    app.tempReloadApp();
  };

  const onTapInfo = () => {
    app.track('profileInfoButton', {
      userId: current.id,
    });

    // open drawer that display user rewards for s1 and s2
    app.ui.drawer.show({
      id: 'drawerProfileSeasonInfo',
    });
  };

  return (
    <>
      {/* GRADIENT */}
      <div
        className={`profile-header-gradient ${gradientColor} ${
          isTelegramWebview() ? 'telegram' : ''
        }`}
      />

      <div className="profile-header">
        {/* NAME */}
        <div
          className={`profile-header-name-row ${isSelf ? 'with-carrot' : ''}`}
        >
          <div className={`profile-header-name`}>{current?.name || ''}</div>
          {isSelf && (
            <div className="btn profile-header-carrot" onClick={onTapInfo}>
              <img src={assets.button_profile_carrot} />
            </div>
          )}
        </div>

        {/* IMAGE */}
        <div className="profile-header-image-row">
          {/* creator image */}
          <CreatorImage src={current?.picture || ''} size={56} />

          {/* share button */}
          <div className="btn profile-header-share" onClick={share}>
            <img src={assets.icon_sidebar_share} />
          </div>
        </div>

        {/* connect/disconnect button */}
        {showWalletButton && <ButtonWallet />}

        {/* portfolio value */}
        {!showWalletButton && (
          <div className="profile-header-value-container">
            <div className="profile-header-value">{portfolioDisaplayValue}</div>
            {isShowingPortfolioAmountChanged ? (
              <LabelValueChange
                value={portfolioChangeAmount}
                percent={portfolioChangePercentage}
              />
            ) : (
              <div className="label-value-change-empty" />
            )}
          </div>
        )}
      </div>
    </>
  );
};

// ====================================================================
// #region Wallet
// wallet button with connect/disconnect states

export const ButtonWallet = ({ onClick }: { onClick?: () => void }) => {
  // does the use have a connected wallet
  const isConnected = getWalletConnected();

  const onConnectWallet = async () => {
    if (app.ton.tonConnectUI.connected) {
      return;
    }

    console.warn('>>> Connect wallet');
    await app.ton.tonConnectUI.openModal();

    // rerender here and if the value from tonConnectUi is updated the button state will reflect it
    app.views.ProfilePage.rerender();

    onClick && onClick();
  };

  const onDisconnectWallet = async () => {
    if (!app.ton.tonConnectUI.connected) {
      return;
    }

    // disconnect wallet
    console.warn('>>> Disconnect wallet');
    await app.ton.tonConnectUI.disconnect();

    // rerender here and if the value from tonConnectUi is updated the button state will reflect it
    app.views.ProfilePage.rerender();

    onClick && onClick();
  };

  return (
    <div className="profile-wallet">
      {isConnected ? (
        <div
          className="btn btn-normal profile-button-wallet disconnect-wallet"
          onClick={onDisconnectWallet}
        >
          <div className="column">
            {shortenString(app.profile.getNormalizedWalletAddress(), 4)}
          </div>
          <div className="separator" />
          <div className="column">
            <img src={assets.icon_link_off_black} />
          </div>
        </div>
      ) : (
        <div
          className="btn btn-normal profile-button-wallet connect-wallet"
          onClick={onConnectWallet}
        >
          <div className="column">{'Connect Wallet'}</div>
        </div>
      )}
    </div>
  );
};

// ====================================================================
// #region Change

const LabelValueChange = ({
  value,
  percent,
}: {
  value: number;
  percent?: number;
}) => {
  if (!percent) percent = 0;

  const color = percent === 0 ? 'white' : percent > 0 ? 'green' : 'red';
  const sign = percent === 0 ? '' : percent > 0 ? '+' : '';

  return (
    <div className="label-value-change">
      {/* value */}
      <div className={`value ${color}`}>
        {largeNumberToLetter(value, 4, '$', 2)}
      </div>

      {/* percent */}
      <>
        <div className={`value solid ${color}`}>
          {sign}
          {formatPrice(Math.round(percent * 10) / 10)}%{' '}
        </div>
      </>
    </div>
  );
};

// ====================================================================
// #region TokenList

interface ProfileTokenListProps {
  tab: ProfileTabV2;
  list: (FarmingSearchResult | TradingSearchResult)[];
  sectionHeight: string;
  isSelf: boolean;
}

export const ProfileTokenList = ({
  tab,
  list,
  sectionHeight,
  isSelf,
}: ProfileTokenListProps) => {
  // console.warn('>>> ProfileTokenList', list);
  const [pendingTxs, setPendingTxs] = useState(
    tab === 'Wallet' ? app.profile.getPendingTxs() : [],
  );
  const [listOmitPending, setListOmitPending] = useState(list);

  const isConnected = getWalletConnected();
  const isEmpty = list.length === 0 && pendingTxs.length === 0;

  useEffect(() => {
    if (tab !== 'Wallet') {
      setPendingTxs([]);
      return;
    }

    setPendingTxs(app.profile.getPendingTxs());

    const intervalId = setInterval(() => {
      const txs = app.profile.getPendingTxs();
      setPendingTxs(txs);
      setListOmitPending(
        list.filter((i) => !txs.find((tx) => tx.memeId === i.id)),
      );

      if (txs.length === 0) {
        clearInterval(intervalId);
      }
      // poll every 1 second
    }, 1000);

    // cleanup on component unmount
    return () => clearInterval(intervalId);
  }, [tab, pendingTxs.length]);

  return (
    <div>
      {isEmpty && (
        <ProfileEmptyList
          tab={tab}
          disconnected={!isConnected}
          sectionHeight={sectionHeight}
          isSelf={isSelf}
        />
      )}

      <div
        className="profile-items-list-container"
        style={{ height: sectionHeight }}
      >
        {pendingTxs.length > 0 && (
          <div className="profile-items-list">
            {pendingTxs.map((pendingTx, index) => {
              return (
                <ProfilePendingTx
                  key={pendingTx.txHash}
                  pendingTx={pendingTx}
                />
              );
            })}
          </div>
        )}

        {!isEmpty && (
          <div className="profile-items-list">
            {list.map((item, index) => {
              return (
                <ProfileItem
                  category={tab}
                  key={index + '_' + item.id}
                  meme={item}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

// ====================================================================
// #region EmptyList

interface ProfileEmptyProps {
  tab: ProfileTabV2;
  sectionHeight: string;
  disconnected: boolean;
  isSelf: boolean;
}

export const ProfileEmptyList = ({
  tab,
  sectionHeight,
  disconnected,
  isSelf,
}: ProfileEmptyProps) => {
  const { t } = useTranslation();

  function getCaptions() {
    const mode = isSelf ? 'self' : 'others';
    switch (tab) {
      case 'Created':
        return {
          title: t(`profile_${mode}_empty_created_title`),
          message: t(`profile_${mode}_empty_created_subtitle`),
          button: t(`profile_empty_created_cta`),
          onClick: () => app.memes.factory.createNewToken(),
        };
      case 'Wallet':
        if (isSelf && disconnected) {
          return {
            title: t(`profile_${mode}_empty_wallet_disconnected_title`),
            message: t(`profile_${mode}_empty_wallet_disconnected_subtitle`),
          };
        }
        return {
          title: t(`profile_${mode}_empty_wallet_connected_title`),
          message: t(`profile_${mode}_empty_wallet_connected_subtitle`),
          button: t(`profile_empty_wallet_connected_cta`),
          onClick: async () => await app.nav.goToTiktokFeed(undefined, 'Hot'),
        };
      case 'Points':
        return {
          title: t(`profile_${mode}_empty_points_title`),
          message: t(`profile_${mode}_empty_points_subtitle`),
          button: t(`profile_empty_points_cta`),
          onClick: async () => await app.nav.goToTiktokFeed(undefined, 'Hot'),
        };
    }
  }

  const data = getCaptions();

  return (
    <div className="profile-empty" style={{ height: sectionHeight }}>
      <div className="profile-empty-info">
        <div className="profile-empty-title">{data.title}</div>
        <div className="profile-empty-message">{data.message}</div>
      </div>
      {data.button && (
        <div
          className="btn btn-normal profile-empty-button"
          onClick={data.onClick}
        >
          {data.button}
        </div>
      )}
    </div>
  );
};
