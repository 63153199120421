import { Address, beginCell, Cell, fromNano, SenderArguments } from '@ton/core';
import {
  calculateDexTokensToTon,
  calculateTokensReceived,
  calculateTonToDexTokens,
  calculateTotalMintPrice,
  estimateTokensReceived,
  storeBuilders,
} from './utils';
import { CHAIN } from '@tonconnect/sdk';
import {
  BuyTokenTxInput,
  ClaimDexTokenInput,
  ClaimType,
  SellTokenInput,
  Signature,
} from './types';
// @TODO: Ideally remove these dependencies to make the Provider "pure"
import { MIN_IN_MS } from '../../replicant/utils/time';
import { apiRequest } from '../api';
import { ErrorCode } from '../../replicant/response';
import {
  DAILY_CHECKIN_CONTRACT_ADDRESS,
  DAILY_CHECKIN_CONTRACT_LABEL,
  DAILY_CHECKIN_CONTRACT_TRANSFER_VALUE,
} from '../../replicant/features/game/ruleset/contract';
import TonWeb from 'tonweb';
import { JettonWallet, TonClient } from '@ton/ton';
import { GemzJetton } from './tact_GemzJetton';
import {
  AdminCallTransferJetton,
  Administrator,
  loadAdminCallTransferJetton,
} from '../../replicant/features/tradingMeme/tact_Administrator';
import { cai } from '../utils';
import { TxType } from '../../replicant/features/tradingMeme/types';
// import { TransactionWatcher } from './TransactionParser';
import {
  onchainCurveConfig,
  txFees,
} from '../../replicant/features/tradingMeme/tradingMeme.ruleset';
import { HP } from '../../replicant/lib/HighPrecision';
import { DEX } from '@ston-fi/sdk';
import {
  adminContractAddresses,
  buildOnchainMetadata,
  ContractMetadata,
  EnvCode,
  TransactionWatcher,
} from '../../replicant/features/tradingMeme/tradingMeme.getters.ton';
import { retry } from '../../replicant/lib/async';
import { toNano } from '../../replicant/features/tradingMeme/utils';
import { getBuyTxAmount } from '../../replicant/features/tradingMeme/tradingMeme.getters';
import { replicantEnv } from '../config';

const ORACLE_SIGNER_URL = 'https://oracle.pnk.one/sign';
const ORACLE_CLAIM_TX_URL = 'https://oracle.pnk.one/getClaimTx';
const GRADUATION_TRIGGER_URL = 'https://watcher.pnk.one/graduate';

const TONCENTER_TEMP_API_KEY =
  '170d64f612e0e84b147c309ffad9d973d5a37869614c212eb59a981302ba2c11';
const TONCENTER_RPC_URL = 'https://toncenter.com/api/v2/jsonRPC';
const TONCENTER_PREVIEW_API_URL = 'https://preview.toncenter.com/api/v3/events';

const TONAPI_JETTONS_URL = 'https://tonapi.io/v2/jettons';
const WALLETBOT_PRICE_API_URL =
  'https://walletbot.me/api/v1/transfers/price_for_fiat';

// const FORCE_WALLET = 'UQAjBoPgAJsLGcUli15j5jKvz9DebmXwciSMYCY5qww6giAp';
const FORCE_WALLET = undefined;

const STONFI_ROUTER_ADDRESS = Address.parse(
  'EQDx--jUU9PUtHltPYZX7wdzIi0SPY3KZ8nvOs0iZvQJd6Ql',
);
const STONFI_PTON_ADDRESS = Address.parse(
  'EQBnGWMCf3-FZZq1W4IWcWiGAc3PHuZ0_H-7sad2oY00o83S',
);
const DEMO_DEX_TOKEN_ADDRESS =
  'EQApfjfy0Uuy-ixrFMrVWSEL4WMOsGDcTsha9i5lZUR5wMS2';

interface Tx {
  validUntil: number;
  messages: { address: string; amount: string; payload?: string }[];
  network: CHAIN.MAINNET;
}

export interface TxOrder {
  tx: Tx;
  inputTonAmount: string;
  fees: {
    slippage?: string;
    network: string;
  };
  finalTonAmount: string;
}

export class TonProvider {
  public static config = {
    jetton: {
      deployContractFee: 0.01,
    },
  };

  public static adminContractAddress = adminContractAddresses[0];

  private _walletAddress?: string = FORCE_WALLET;

  public get walletAddress() {
    return this._walletAddress;
  }

  /**
   * @lazy use pubClient instead
   */
  private _pubClient?: TonWeb;
  // Lazy instantiate the first time we use the pubClient
  private get pubClient(): TonWeb {
    if (!this._pubClient) {
      this._pubClient = new TonWeb(new TonWeb.HttpProvider(TONCENTER_RPC_URL));
    }
    return this._pubClient;
  }

  public client = new TonClient({
    endpoint: TONCENTER_RPC_URL,
    apiKey: TONCENTER_TEMP_API_KEY,
  });

  public setWalletAddress = (walletAddress?: string) => {
    cai('setWalletAddress', { walletAddress });
    if (!FORCE_WALLET) {
      this._walletAddress = walletAddress;
    }
  };

  private onError = (msg: any, code: ErrorCode) => {
    console.error(msg);
    return new Error(code);
  };

  // 5min
  private getExpiry = (now: number, ttl = 5 * MIN_IN_MS) => {
    return now + ttl;
  };

  private sanitizeDataToSign = (
    data: Record<string, any>,
  ): Record<string, string> => {
    return Object.keys(data).reduce((res, cur) => {
      res[cur] = data[cur].toString();
      return res;
    }, {} as Record<string, string>);
  };

  private getSignature = async (
    method: 'buyToken' | 'sellToken',
    data: Record<string, any>,
  ): Promise<Signature> => {
    try {
      const {
        result: { nonceB64, signatureB64 },
      } = await apiRequest<{
        result: {
          nonceB64: string;
          signatureB64: string;
          timestamp: string;
        };
      }>(ORACLE_SIGNER_URL).post({
        method,
        data: this.sanitizeDataToSign(data),
      });

      const bocBuffer = Buffer.from(nonceB64, 'base64');
      // Deserialize the Buffer to a Cell
      const nonce = Cell.fromBoc(bocBuffer)[0];

      const sigBuffer = Buffer.from(signatureB64, 'base64');
      const signature = beginCell().storeBuffer(sigBuffer).asSlice();

      return {
        nonce,
        signature,
      };
    } catch (err) {
      throw this.onError(err, ErrorCode.OC_TON_SIGNATURE_FAILED);
    }
  };

  private withOrder = (order: Omit<TxOrder, 'finalTonAmount'>): TxOrder => {
    return {
      ...order,
      finalTonAmount: order.tx.messages[0].amount,
    };
  };

  private getClaimTx = async (props: {
    playerId: string;
    memeId: string;
    tonAmount: string;
    env: string;
    claimType: ClaimType;
  }) => {
    try {
      return await retry(
        () =>
          apiRequest<{
            result: {
              validUntil: number;
              messages: { address: string; amount: string; payload: string }[];
              network: CHAIN;
            };
          }>(ORACLE_CLAIM_TX_URL).post({
            playerId: props.playerId,
            memeId: props.memeId,
            tonAmount: props.tonAmount,
            env: props.env,
            method: props.claimType,
          }),
        {
          attempts: 4,
          backoff: { linear: { delay: 2000 } },
        },
      );
    } catch (err) {
      throw this.onError(err, ErrorCode.OC_TON_SIGNATURE_FAILED);
    }
  };

  private formatTx = (
    { to, value, body }: SenderArguments,
    now: number,
  ): Tx => {
    const address = to.toString();
    const amount = value.toString();
    const payload = body?.toBoc().toString('base64');
    return {
      validUntil: this.getExpiry(now),
      messages: [{ address, amount, payload }],
      network: CHAIN.MAINNET,
    };
  };

  private getAdminSenderArgs = (
    body: Cell,
    value: number | string | bigint = 0.25,
  ): SenderArguments => {
    return {
      to: Address.parse(TonProvider.adminContractAddress),
      value: toNano(value),
      body,
    };
  };
  // @TODO: return HP
  public getBalance = () => {
    if (!this.walletAddress) {
      return '0'; // should we return something else to indicate not connected?
    }
    const walletAddress = Address.parse(this.walletAddress);
    return this.client.getBalance(walletAddress);
  };

  public getCheckInTx = (now: number) => {
    const args: SenderArguments = {
      to: Address.parse(DAILY_CHECKIN_CONTRACT_ADDRESS),
      value: toNano(DAILY_CHECKIN_CONTRACT_TRANSFER_VALUE),
      body: beginCell()
        .storeUint(0, 32)
        .storeStringTail(DAILY_CHECKIN_CONTRACT_LABEL)
        .endCell(),
    };

    const tx = this.formatTx(args, now);

    return tx;
  };

  public getBuyTokenTxOrder = async (
    input: BuyTokenTxInput,
    tonAmountNano: string,
    now: number,
  ) => {
    if (!this.walletAddress) {
      throw this.onError(
        new Error('Cannot get token tx without walletAddress'),
        ErrorCode.OC_WALLET_ADDRESS_MISSING,
      );
    }

    const { amount, firstBuy, memeId, ...metadataInput } = input;

    const sig = await this.getSignature('buyToken', {
      ownerAddress: Address.parse(this.walletAddress),
      memeId: BigInt(input.memeId),
      amount: input.amount,
    });

    let metadata: Cell;

    try {
      metadata = this.getContractMetada(metadataInput.metadata);
    } catch (e: any) {
      throw this.onError(e, ErrorCode.OC_JETTON_METADATA_FAILED);
    }

    const message = {
      $$type: 'AdminCallTransferJetton',
      queryId: BigInt(Date.now()),
      metadata,
      firstBuy,
      ...sig,
    } as const;

    const { tonAmount, slippagePct } = getBuyTxAmount(tonAmountNano);

    cai('getBuyTokenTx', {
      input,
      message,
      tonAmountNano,
      tonAmount,
    });

    const body = beginCell().store(storeBuilders.buyToken(message)).endCell();
    const args = this.getAdminSenderArgs(body, tonAmount);
    const tx = this.formatTx(args, now);
    return this.withOrder({
      tx,
      inputTonAmount: tonAmountNano,
      fees: { slippage: slippagePct, network: fromNano(txFees.buyFee) },
    });
  };

  public getSellTokenTx = async (input: SellTokenInput, now: number) => {
    const { metadata, ...sigInput } = input;

    const sig = await this.getSignature('sellToken', sigInput);

    const message = {
      $$type: 'AdminCallSellJetton',
      queryId: BigInt(Date.now()),
      ...sig,
      metadata: this.getContractMetada(metadata),
    } as const;

    const body = beginCell().store(storeBuilders.sellToken(message)).endCell();
    const args = this.getAdminSenderArgs(body);
    const tx = this.formatTx(args, now);
    return tx;
  };

  public claimDexTokenTx = async (
    memeId: string,
    userId: string,
    claimType: ClaimType,
  ) => {
    return await this.getClaimTx({
      memeId: memeId,
      playerId: userId,
      tonAmount: fromNano(txFees.claimDexToken),
      env: replicantEnv === 'prod' ? 'gemzcoin' : `gemzcoin-${replicantEnv}`,
      claimType: claimType,
    });
  };

  public triggerGraduation = async (data: { memeId: string; env: EnvCode }) => {
    try {
      await apiRequest(GRADUATION_TRIGGER_URL).post(data);
    } catch (err) {
      throw this.onError(err, ErrorCode.OC_TRIGGER_GRADUATION_FAILED);
    }
  };

  // ==============================================================
  // ====================== CONTRACT GETTERS ======================
  // ==============================================================

  private getAdminContract = () => {
    const adminContractAddress = Address.parse(
      TonProvider.adminContractAddress,
    );
    const adminContract = this.client.open(
      Administrator.fromAddress(adminContractAddress),
    );
    return adminContract;
  };

  private getJettonContract = (contractAddress: string) => {
    const jettonContractAddress = Address.parse(contractAddress);
    const jettonContract = this.client.open(
      GemzJetton.fromAddress(jettonContractAddress),
    );
    return jettonContract;
  };

  private getJettonWallet = async (contractAddress: string) => {
    const jettonWalletAddress = await this.getJettonWalletAddress(
      contractAddress,
    );
    if (!jettonWalletAddress) {
      return;
    }
    const jettonWallet = this.client.open(
      JettonWallet.create(jettonWalletAddress),
    );
    return jettonWallet;
  };

  getJettonContractAddressFromMetadata = async (metadata: ContractMetadata) => {
    try {
      const adminContract = this.getAdminContract();
      const contractAddress = await adminContract.getGetJettonContractAddress(
        this.getContractMetada(metadata),
      );
      console.log('getJettonContractAddressFromMetadata', { contractAddress });
      const address = Address.normalize(contractAddress);
      return address;
    } catch (error) {
      throw error;
    }
  };

  getJettonWalletAddress = async (contractAddress: string) => {
    if (!this.walletAddress) {
      return;
    }
    const userAddress = Address.parse(this.walletAddress);
    const jettonContract = this.getJettonContract(contractAddress);
    const jettonWalletAddress = await jettonContract.getGetWalletAddress(
      userAddress,
    );
    return jettonWalletAddress;
  };

  getJettonBalance = async (contractAddress: string) => {
    const jettonWallet = await this.getJettonWallet(contractAddress);
    if (!jettonWallet) {
      return BigInt(0);
    }
    const jettonBalance = await jettonWallet.getBalance();
    return jettonBalance;
  };

  private SUPPLY_CACHE_EXPIRY_MS = 5000;
  private supplyCache: Record<string, { value: bigint; timestamp: number }> =
    {};
  private supplyRequests: Record<string, Promise<bigint>> = {};

  getJettonSupply = async (contractAddress: string) => {
    const now = Date.now();
    const cached = this.supplyCache[contractAddress];
    cai('getJettonSupply', { time: now });

    // Return cached value if it exists and is not expired
    if (cached && now - cached.timestamp < this.SUPPLY_CACHE_EXPIRY_MS) {
      return cached.value;
    }

    // If there's already a request in progress for this address, return that promise
    if (contractAddress in this.supplyRequests) {
      return this.supplyRequests[contractAddress];
    }

    // Create new request promise and store it
    this.supplyRequests[contractAddress] = (async () => {
      try {
        const jettonContract = this.getJettonContract(contractAddress);
        const jettonSupply = await jettonContract.getTotalSupply();

        // Cache the new value
        this.supplyCache[contractAddress] = {
          value: jettonSupply,
          timestamp: Date.now(),
        };

        cai('getJettonSupply:response', { time: now });

        return jettonSupply;
      } finally {
        // Clean up the request promise when done
        delete this.supplyRequests[contractAddress];
      }
    })();

    return this.supplyRequests[contractAddress];
  };

  getJettonBuyPrice = async (contractAddress: string, amountNano: string) => {
    const jettonContractAddress = Address.parse(contractAddress);
    const gemzJettonContract = this.client.open(
      GemzJetton.fromAddress(jettonContractAddress),
    );
    const amount = toNano(amountNano);
    const price = await gemzJettonContract.getTokenBuyPrice(amount);
    return fromNano(price);
  };

  /**
   * @TODO figure out how to work out the transactions, will it even be needed?
   * @deprecated I don't think we need this anymore use getTransaction instead
   */
  getTransactions = async () => {
    if (!this.walletAddress) {
      return;
    }
    const userAddress = Address.parse(this.walletAddress);
    const txs = await this.client.getTransactions(userAddress, {
      limit: 1000,
      to_lt: '10/12/2024',
    });
    console.log({ txs });
  };

  getTx = (hash: string) => {
    if (!this.walletAddress) {
      return undefined;
    }
    const walletAddress = Address.parse(this.walletAddress);
    return this.client.getTransaction(walletAddress, '1', hash);
  };

  getTxWithHash = async (hash: string) => {
    const url = `${TONCENTER_PREVIEW_API_URL}?msg_hash=${hash}`;
    const response = await fetch(url);
    interface Response {
      events: [
        { actions: [{ success: boolean; details: { opcode: string } }] },
      ];
    }
    const data: Response = await response.json();
    const actions = data?.events?.[0]?.actions ?? [];

    // These are not strictly necessary to be successful
    const JETTON_NOTIFY_OPCODE = '0x7362d09c';

    const res = {
      raw: data,
      actions: actions.length,
      success: actions.every(
        (a) =>
          a.success ||
          (!a.success && a.details.opcode === JETTON_NOTIFY_OPCODE),
      ),
    };

    cai('getTxWithHash', { res });

    return res;
  };

  getHolders = async (contractAddress: string) => {
    // @TODO: Handle pagination
    const url = `${TONAPI_JETTONS_URL}/${contractAddress}/holders?limit=50&offset=0`;
    interface Response {
      addresses: [
        {
          address: string;
          owner: {
            address: string;
            is_scam: boolean;
            is_wallet: boolean;
          };
          balance: string;
        },
      ];
      total: number;
    }
    const data = await apiRequest<Response>(url).get();

    return {
      total: data.total,
      holders: data.addresses.reduce((res, cur) => {
        const address1 = Address.normalize(cur.address);
        const ownerAddress = Address.normalize(cur.owner.address);
        res[address1] = {
          address1,
          ownerAddress,
          balance: fromNano(cur.balance),
        };
        return res;
      }, {} as Record<string, { address1: string; ownerAddress: string; balance: string }>),
    };
  };

  getContractDetails = () => {
    // https://tonapi.io/v2/jettons/EQCk48QxlvtVZmxLvYPchPixejdPWYiQnV8m0xoj3-9FF7zY
  };

  private readonly RATE_CACHE_EXPIRY_MS = 10000;
  private rateCache = {
    value: 5.5,
    timestamp: 0,
  };
  private rateRequest: Promise<number> | null = null;

  getTonToUSD = async (tonAmount: string): Promise<number> => {
    const now = Date.now();

    // Check if we have a valid cached rate
    if (
      this.rateCache &&
      now - this.rateCache.timestamp < this.RATE_CACHE_EXPIRY_MS
    ) {
      return Number(tonAmount) * this.rateCache.value;
    }

    // If there's already a request in progress, wait for it and use its result
    if (this.rateRequest) {
      const rate = await this.rateRequest;
      return Number(tonAmount) * rate;
    }

    // Create new request promise and store it
    this.rateRequest = (async () => {
      try {
        interface Response {
          amount_from: number;
          currency_from: 'TON';
          currency_to: 'UDS';
          price: number;
          rate: number;
        }

        const url = `${WALLETBOT_PRICE_API_URL}/?crypto_currency=TON&local_currency=USD&amount=1`;
        const response = await apiRequest<Response>(url).get();

        // Cache the new rate
        this.rateCache = {
          value: response.rate,
          timestamp: now,
        };

        return response.rate;
      } finally {
        // Clean up the request promise when done
        this.rateRequest = null;
      }
    })();

    const rate = await this.rateRequest;
    return Number(tonAmount) * rate;
  };

  getTonToUSDSync = (tonAmount: string) => {
    return Number(tonAmount) * this.rateCache.value;
  };

  readonly curveConfig = onchainCurveConfig;

  private getTONToToken = (ton: string, supply: bigint) => {
    return calculateTokensReceived(
      supply,
      toNano(ton),
      this.curveConfig.initialPrice,
      this.curveConfig.maxTargetPrice,
      this.curveConfig.maxSupply,
    );
  };

  private getEstimatedTONToToken = (tonAmount: string, supply: bigint) => {
    try {
      const tonSendAmount = this.tonAmountToNano(tonAmount);

      return estimateTokensReceived(
        supply,
        tonSendAmount,
        this.curveConfig.initialPrice,
        this.curveConfig.maxTargetPrice,
        this.curveConfig.maxSupply,
      );
    } catch (error) {
      return BigInt(0);
    }
  };

  getTokenToTON = (tokens: bigint, supply: bigint, txType: TxType) => {
    const tonValue = calculateTotalMintPrice(
      supply,
      tokens,
      this.curveConfig,
      txType === 'buy',
    );

    return tonValue;
  };

  getListingBuyTokens = (ton: string) => {
    return this.getTONToToken(ton, this.curveConfig.gemzOwnerSupply);
  };

  getEstimatedListingBuyTokens = (ton: string) => {
    return this.getEstimatedTONToToken(ton, this.curveConfig.gemzOwnerSupply);
  };

  getBuyTokens = async (contractAddress: string, ton: string) => {
    cai('getBuyTokens', { time: Date.now() });
    const tokenSupply = await this.getJettonSupply(contractAddress);
    cai('getBuyTokens:tokenSupply', { time: Date.now(), tokenSupply });
    return this.getTONToToken(ton, tokenSupply);
  };

  getEstimatedBuyTokens = async (contractAddress: string, ton: string) => {
    const tokenSupply = await this.getJettonSupply(contractAddress);
    return this.getEstimatedTONToToken(ton, tokenSupply);
  };

  getJettonLiquidity = async (contractAddress: string) => {
    const supply = await this.getJettonSupply(contractAddress);
    const ton = this.getTokenToTON(supply, supply, 'sell');
    return this.getTonToUSD(fromNano(ton));
  };

  getSellTokens = async (contractAddress: string, tokens: bigint) => {
    const supply = await this.getJettonSupply(contractAddress);
    const ton = this.getTokenToTON(tokens, supply, 'sell');
    return ton;
  };

  getSeqno = async (index: number) => {
    return this.getAdminContract().getGetSeqNo(BigInt(index));
  };

  getContractMetada = (memeInfo: ContractMetadata) => {
    return buildOnchainMetadata(memeInfo);
  };

  getParseTransactions = async (addressFriendly: string) => {
    const txWacher = new TransactionWatcher(addressFriendly, false);
    const txs = await txWacher.run({ toLogicalTime: undefined });
    return txs;
  };

  getTransactionMetadata = async (jettonContractHash: string) => {
    const request = await fetch(
      `https://toncenter.com/api/v2/getTransactions?address=${jettonContractHash}&limit=50`,
    );

    const response = await request.json();
    if (!response.ok) {
      return [];
    }

    const transactions = response.result;

    return transactions.map((tx: any) => {
      // @note: NOT WORKING
      // something decoding missing prior to loadAdminCallTransferJetton?
      const base64Data = tx.data;
      const buffer = Buffer.from(base64Data, 'base64');
      const cells = Cell.fromBoc(buffer);
      const mainCell = cells[0];
      const decoded = loadAdminCallTransferJetton(mainCell.beginParse());
      return decoded;
    });
  };

  getDexTonToTokens = async (dexTokenAddress: string, tonAmount: string) => {
    const { tokenLiquidityPoolAmount, tonLiquidityPoolAmount } =
      await this.getLiquidityPoolData(dexTokenAddress);

    const tonSendAmount = this.tonAmountToNano(tonAmount);
    const tokensReceived = calculateTonToDexTokens(
      tonSendAmount,
      tonLiquidityPoolAmount,
      tokenLiquidityPoolAmount,
    );

    return tokensReceived;
  };

  getDexTokensToTon = async (dexTokenAddress: string, tokensAmount: string) => {
    const { tokenLiquidityPoolAmount, tonLiquidityPoolAmount } =
      await this.getLiquidityPoolData(dexTokenAddress);

    const tokenSendAmount = HP(tokensAmount).toBigInt();
    const tonReceived = calculateDexTokensToTon(
      tokenSendAmount,
      tonLiquidityPoolAmount,
      tokenLiquidityPoolAmount,
    );
    console.log('getDexTokensToTon', {
      dexTokenAddress,
      tokensAmount,
      tokenLiquidityPoolAmount,
      tonLiquidityPoolAmount,
      tonReceived,
    });
    return tonReceived;
  };

  private tonAmountToNano = (tonAmount: string) => {
    try {
      const decimalIndex = tonAmount.indexOf('.');
      if (decimalIndex === -1) {
        return toNano(tonAmount);
      }
      const truncated = tonAmount.slice(0, decimalIndex + 6);
      return toNano(truncated);
    } catch (e) {
      return HP(0).toBigInt();
    }
  };

  private readonly LP_CACHE_EXPIRY_MS = 5000;
  private lpCache: Record<
    string,
    {
      value: {
        tokenLiquidityPoolAmount: bigint;
        tonLiquidityPoolAmount: bigint;
      };
      timestamp: number;
    }
  > = {};
  private lpRequests: Record<
    string,
    Promise<{
      tokenLiquidityPoolAmount: bigint;
      tonLiquidityPoolAmount: bigint;
    }>
  > = {};

  getLiquidityPoolData = async (dexJettonContractAddress: string) => {
    const now = Date.now();
    const cached = this.lpCache[dexJettonContractAddress];

    // Return cached value if it exists and is not expired
    if (cached && now - cached.timestamp < this.LP_CACHE_EXPIRY_MS) {
      return cached.value;
    }

    // If there's already a request in progress for this address, return that promise
    if (dexJettonContractAddress in this.lpRequests) {
      return this.lpRequests[dexJettonContractAddress];
    }

    // Create new request promise and store it
    this.lpRequests[dexJettonContractAddress] = (async () => {
      try {
        const router = this.client.open(
          DEX.v2_1.Router.create(STONFI_ROUTER_ADDRESS),
        );

        const pool = this.client.open(
          await router.getPool({
            token0: STONFI_PTON_ADDRESS,
            token1: Address.parse(
              dexJettonContractAddress || DEMO_DEX_TOKEN_ADDRESS,
            ),
          }),
        );

        const poolData = await pool.getPoolData();
        const result = {
          tokenLiquidityPoolAmount: poolData?.reserve0 || BigInt(0),
          tonLiquidityPoolAmount: poolData?.reserve1 || BigInt(0),
        };

        // Cache the new value
        this.lpCache[dexJettonContractAddress] = {
          value: result,
          timestamp: Date.now(),
        };

        return result;
      } catch (e) {
        console.error('getLiquidityPoolData', e);
        return {
          tokenLiquidityPoolAmount: BigInt(0),
          tonLiquidityPoolAmount: BigInt(0),
        };
      } finally {
        // Clean up the request promise when done
        delete this.lpRequests[dexJettonContractAddress];
      }
    })();

    return this.lpRequests[dexJettonContractAddress];
  };
}
