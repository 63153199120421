import { ReplicantEventHandlerAPI } from '@play-co/replicant';
import { MutableState } from '../../schema';
import { ReplicantServer } from '../../config';
import { TelegramMessage } from './chatbot.schema';
import { stage } from '../game/game.config';
import botNotifManagerCfgs from '../powerups/airtable/botNotifManager';
import { HOUR_IN_MS, MIN_IN_MS } from '../../utils/time';

const [botNotifManagerCfg] = botNotifManagerCfgs;

export const creativeVersion = 1;

export const specialCmdWhitelist = [
  // brice
  '5796798150',
  // carles
  '6572248057',
  // santosh
  '6090664772',
  // cai
  '6540606306',
  // damon
  '6319323425',
  // ian
  '6170825979',
  // justin
  '2045194985',
  // diego
  '5639787089',
  // kanto
  '5460500341',
  // marc
  '6659276808',
];

// Total number of bot messages that can be sent in a day
export const maxBotNotifDailyVolume = botNotifManagerCfg.dailyLimitRate;

export const maxNotifsPerPlayerPerDay =
  stage === 'prod' ? botNotifManagerCfg.maxNotifsPerPlayerPerDay : 4;

export const notificationFolderUrl =
  'https://notgemz-game.s3.amazonaws.com/media/notifications/' as const;
export const communityGroup = `https://t.me/gemz_announcements` as const;
export const howToPlayGuideUrl =
  `https://www.notion.so/gemz/Gemzcoin-Guide-4b9bc2515c0c4360bcd485f8c4b1efed` as const;
export const gameImage =
  'https://notgemz-game.s3.amazonaws.com/media/cover-3.jpg' as const;
export const termsOfServiceUrl = 'http://legal.gemz.fun' as const;

export const teamCreationTtl = 1000 * 60 * 10;

// @note: super annoying stuff
// if we want to recompress the media and re-upload them we have to change their names
// because Telegram caches them and therefore the changes wont be effective unless the medias have different names!
const reengagementMedias = [
  'spongebob.mp4',
  'spock.gif',
  'pudgy.gif',
  'lookback meme.jpg',
  'gemz dance.mp4',
  'cartman.mp4',
] as const;

const energyRechargedMedias = [
  'recharged.gif',
  'dragonball 100K.gif',
  'charged up.gif',
  '8ns6t6.mp4',
] as const;

const friendJoinedMedias = [
  'slam dunk.mp4',
  'queen.mp4',
  'billboard.mp4',
] as const;

const friendLevelUpMedias = [
  'poitns.mp4',
  'lego.mp4',
  'dance party-n.mp4',
  'channel 5.mp4',
  'bleaaah.mp4',
] as const;

const levelUpMedias = [
  'poitns.mp4',
  'dance party-n.mp4',
  'gemz dance.mp4',
  'channel 5.mp4',
  'bleaaah.mp4',
] as const;

const teamRankMedias = [
  'red pill blue pill.jpg',
  'missing you.mp4',
  'I miss you.mp4',
  'goalie.mp4',
  'f1.jpg',
] as const;

const autobotMedias = [
  'pixel planet.gif',
  'mission accomplished.gif',
  'looney.gif',
  'happy end.mp4',
  'bender.mp4',
] as const;

const offchainTradingMedias = [
  'up_only.mp4',
  'pumpit.mp4',
  'pepe.mp4',
  'nyan_cat_moon.mp4',
  'mooning.mp4',
  'moon_rocket.mp4',
  'crusty_moon.mp4',
] as const;

export enum NotificationFeature {
  reengagement = 'reengagement',
  energyRecharged = 'energyRecharged',
  friendJoined = 'friendJoined',
}

export const notifFeaturePriorities: NotificationFeature[] = [
  NotificationFeature.friendJoined,
  NotificationFeature.energyRecharged,
  NotificationFeature.reengagement,
];

export type NotificationFeatureName = `${NotificationFeature}`;

// Maximum is 1 day:
export const maxNotifDelays = {
  startCmdPrivate: 60 * MIN_IN_MS,
  reengagement: 24 * HOUR_IN_MS,
  energyRecharged: 24 * HOUR_IN_MS,
  friendJoined: 24 * HOUR_IN_MS,
  offchainTradingFomoRunaway: 24 * HOUR_IN_MS,
  offchainTradingFomoPopular: 24 * HOUR_IN_MS,
  newQuest: 15 * MIN_IN_MS,
  offchainTradingCardPriceUp: 24 * HOUR_IN_MS,
  offchainTradingCardPriceDown: 24 * HOUR_IN_MS,
  giftMemeToken: 24 * HOUR_IN_MS,
} as const;

export const highPriorityNotifs: Partial<
  Record<
    NotificationKey | NotificationFeatureName | CmdReplyMessageTypes,
    'high'
  >
> = {
  // offchainTradingFomoRunaway: 'high',
  // offchainTradingFomoPopular: 'high',
  newQuest: 'high',
  startCmdPrivate: 'high',
};

export const notifications = {
  reengagement1: {
    message: `You're missing out on your coins!\nCome back to continue earning`,
    medias: reengagementMedias,
  },
  reengagement2: {
    message: 'Your coins are waiting for you!\nCome back to continue earning',
    medias: reengagementMedias,
  },
  reengagement3: {
    message:
      'You are dropping in the gemz coin leaderboards!\nCome back to continue earning',
    medias: reengagementMedias,
  },
  reengagement4: {
    message:
      "🏆 Don't miss out! Your Points are waiting. Jump back in to keep earning!",
    medias: reengagementMedias,
  },
  reengagement5: {
    message:
      "⏳ Time's ticking! The meme token market is heating up—earn more Points now!",
    medias: reengagementMedias,
  },
  reengagement6: {
    message:
      '🔥Get ready! Major updates are just around the corner. Keep playing and stay informed!',
    medias: reengagementMedias,
  },
  reengagement7: {
    message:
      '🎮 We miss you! Jump back into the game and continue your adventure.',
    medias: reengagementMedias,
  },
  reengagement8: {
    message:
      '💡 Insider tip: Rare meme tokens are up for grabs! Come back to claim yours',
    medias: reengagementMedias,
  },
  reengagement9: {
    message:
      '📉 You’re losing ground on the Gemz leaderboards! Log in now to reclaim your spot.',
    medias: reengagementMedias,
  },
  energyRecharged: {
    message: `Charged up.\nReady to earn.\nLet's tap!`,
    medias: energyRechargedMedias,
  },
  friendJoined: {
    message: `Your friend {friendName} joined gemz!\nHere's a bonus of {bonus} coins`,
    medias: friendJoinedMedias,
  },
  friendsJoined: {
    message: `Your friend {friendName} and {friendCount} others joined gemz!\nHere's a bonus of {bonus} coins`,
    medias: friendJoinedMedias,
  },
  friendIncrementalLeague: {
    message: `Your referred friend {friendName} just reached League {league}!\nHere's a bonus of {bonus} coins`,
    medias: friendLevelUpMedias,
  },
  teamRankUp: {
    message: `You're climbing up the leaderboard!\nKeep earning and carry on.`,
    medias: teamRankMedias,
  },
  teamRankDown: {
    message: `Defend your spot on the leaderboard!\nCome back and earn more coins.`,
    medias: teamRankMedias,
  },
  autobotTimesUp: {
    message: `Your TapBot has finished earning for you.\nCome back and claim your coins before they are lost.`,
    medias: autobotMedias,
  },
  offchainTradingCardPriceUp: {
    message: `🔥 {tokenName} is on fire — up by {priceChange}%\nCurrent Price: 🪙{price}! ⏳\nAct now before the price shifts!`,
    medias: offchainTradingMedias,
  },
  offchainTradingCardPriceDown: {
    message: `🚨 Heads up! {tokenName} just dropped by {priceChange}%\nCurrent Price: 🪙{price}!\nCheck your portfolio and make your move`,
    medias: offchainTradingMedias,
  },
  newQuest: {
    message: `🔥 A new quest is live with a massive coin reward!\nDive back in now and grab your prize! 🎯`,
    medias: reengagementMedias,
  },
  offchainTradingFomoRunaway: {
    message: `✨ Fresh Listing Alert! {tokenName} ({tokenTicker}) is new on the market and has already surged {priceChange}% in the last hour! Don't miss out — get in early!`,
    medias: offchainTradingMedias,
  },
  offchainTradingFomoPopular: {
    message: `🌐 Join the Rush! {tokenName} ({tokenTicker}) is gaining new holders fast — its price is up by {priceChange}% in the last 24 hours! It could be your next big opportunity.`,
    medias: offchainTradingMedias,
  },
  giftMemeToken: {
    message: `🪙 Good News! You've Received a Meme Token Gift
A generous gift of 100K coins worth of {tokenName} is waiting for you. Trade it now or invest further in the meme market. Don't miss out—claim your tokens today!
`,
    medias: reengagementMedias,
  },
} as const;

export type NotificationKey = keyof typeof notifications;

export type CmdOpts = Record<string, string>;
export type Cmd =
  | '/start'
  | '/invite'
  | '/help'
  | '/local'
  | '/updateBotMenu'
  | '/generatePayload'
  | '/devHelp'
  | '/ai';
export type CmdHandler = <T>(
  state: MutableState,
  api: ReplicantEventHandlerAPI<ReplicantServer>,
  message: TelegramMessage,
  cmdOpts?: CmdOpts,
) => Promise<T | any>;
export type BotActions = Record<Cmd, CmdHandler>;

export type CmdReplyMessageTypes =
  | 'startCmdGroup'
  | 'startCmdPrivate'
  | 'localCmd'
  | 'inviteCmd'
  | 'howToPlayCmd'
  | 'startAICmdPrivate';

export const callToActions = {
  startCmdGroup: 'Play gemz!',
  startCmdPrivate: {
    play: `🕹️ Play now`,
    community: `🤙 gemz community`,
    howToPlay: `🎓 How to play`,
    invite: 'Invite Friend',
    tos: '📜 Terms of Service',
  },
  inviteCmd: 'Invite now!',
  howToPlayCmd: `🎓 How to play`,
  localCmd: '💻 Run local app!',
  defaultPlayButton: 'Play gemz!',
  menuPlayButton: 'Play Now',
  startAIPrivate: `Create AI`,
};

export const cmdReplyCaptions = {
  startCmdGroup: '💎Tap & Earn with gemz! Foo💎',
  startCmdPrivate: [
    `Hey {username}!`,
    `Welcome to Gemz! 🎉`,
    `Dive into the meme token market and trade unique tokens created by players like you! 💰`,
    `Invite friends to join, invest early, and watch your profits soar!🪽`,
  ].join('\n'),
  howToPlayCmd: 'Want to learn about gemz? Find all you need to know here!',
  localCmd: 'Proxy: {proxy}',
  startAICmdPrivate: [
    `Hey {username}!`,
    `Are you ready for your AI to start creating memes in Gemz?`,
    `Setup your AI Plugin here!`,
  ].join('\n'),
} satisfies Partial<Record<CmdReplyMessageTypes, any>>;

export const TEAM_RANK_UPDATE_DELAY = 1000 * 60 * 60 * 24; // 24 hours
