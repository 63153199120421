import { SB } from '@play-co/replicant';
import { BASE_ENERGY_500, initialScore } from './ruleset/player';
import { powerupSchema } from '../powerups/powerup.schema';
import { tmgRuleset, txConfig } from '../tradingMeme/tradingMeme.ruleset';
import { CURRENT_SOFT_MIGRATION } from '../../soft_migrations/softMigrator';
import { SEASON } from './ruleset/league';
import { txTypeSchema } from '../tradingMeme/tradingMeme.schema';

// @note: the legacy update_count property was used to avoid overwriting data, therefore it can be discarded whenever found
export const playerSchema = {
  // Whether the user has already interacted with the chatbot before
  first_interaction: SB.boolean().default(true),

  // Time user initiated team creation
  team_creation_start_time: SB.int(),

  // from the player DB (legacy table 'notgemz-prod-player')
  energy: SB.int().default(BASE_ENERGY_500),
  last_energy_refresh: SB.int().default(0), // Date.now()
  /**
   * @deprecated to be removed next migration
   * Record<entryPayloadKey, timestamp>;
   */
  last_energy_zero: SB.int().default(0), // Date.now()

  // BUFFS (free)
  free_rocketman_used: SB.array(SB.int()),
  free_energy_recharge_timestamp: SB.int(),

  // BOOSTERS
  // energy limit
  energy_limit_level: SB.int(),
  // energy recharge per second
  energy_recharge_level: SB.int(),
  // rocketman
  rocketman_used: SB.array(SB.int()),
  // extra taps
  tap_level: SB.int(),
  // Auto bot
  has_auto_tap: SB.boolean(),
  session_start_time: SB.int(),
  last_session_time: SB.int(), // actually corresponds to last session START TIME
  last_session_end_time: SB.int(), // used to determine how much rewards players could claim in-between sessions
  first_session: SB.boolean().default(true),

  // daily rewards
  streak_days: SB.int().default(0), // Number of days in current streak (max 10)
  consecutive_days: SB.int().default(0), // Number of consecutive days the user has logged in,
  unclaimed_rewards: SB.int().default(0), // unclaimed rewards for current streak
  last_reward_granted: SB.int(), // Date of the last daily reward was granted

  // referral rewards
  unclaimed_referral_rewards: SB.int().default(0),

  // @deprecated
  // ads removed
  last_ad_reward_time: SB.int().default(0),

  // from the player score DB (legacy table 'notgemz-prod-playerscore')

  // actual score
  score: SB.number().default(initialScore),

  season: SB.number().default(1),

  startingSeason: SB.int().default(SEASON),

  season2Compensations: SB.object({
    claimAmount: SB.int(),
    finalBalanceRedeemed: SB.int(),
    totalMiningCardsRedeemed: SB.int(),
    miningCardRedemptionAmount: SB.int(),
    redeemedPortfolioValue: SB.int(),
  }).optional(),

  seasonScores: SB.array(SB.int().optional()),

  seasonLeagues: SB.array(SB.string().optional()),

  // balance, displayed on the main clicker screen
  balance: SB.number().default(initialScore),

  taps: SB.number(),

  session_taps: SB.int(),

  // Team which the player is part of
  team_id: SB.string().optional(),

  // Score not sent to the team yet.
  unsynced_team_score: SB.int(),

  // invite friend drawer nudge last shown
  invite_friend_nudge_time: SB.int().optional(),

  // booster purchases
  boosterPurchases: SB.object({
    autoTaps: SB.int(),
    multiTaps: SB.int(),
    rechargeLimits: SB.int(),
    rechargeSpeeds: SB.int(),
  }),

  tutorials: SB.map(SB.number()),

  referrerContribution: SB.int(),

  friendCount: SB.int(),

  friendsSubStateId: SB.string().optional(),

  /**
   * Bringing this property back.
   * User's league goes up as balance goes up but doesn't go down when balance goes down.
   */
  league: SB.string(),
  /**
   * @deprecated Use `state.powerUps.daily` instead
   */
  daily_powerups: SB.object({
    timestamp: SB.int(),
    power_ups: SB.array(SB.string()),
  }).default({ timestamp: 0, power_ups: [] }),

  // wallet
  // @IMPORTANT: never remove wallets after they are added!
  wallet: SB.array(
    SB.object({
      app_name: SB.string(),
      address: SB.string(),
      lastConnectionTime: SB.int(),
    }),
  ),

  lastConnectedWallet: SB.string(),

  /**
   * @deprecated Use `state.powerUps.owned` instead
   */
  power_ups: SB.map(SB.object(powerupSchema)).default({}),

  // @deprecated
  friendIds: SB.array(SB.string()).optional(),

  // @deprecated
  // When the player state was migrated from the legacy backend
  migratedAt: SB.int().optional(),
  migrationForced: SB.boolean().optional(),
  // @deprecated
  referralFixed: SB.boolean().optional(),

  // @deprecated
  base_energy_override: SB.int(),

  // deprecated
  throttledNotifications: SB.object({
    // array of the timestamps
    // no more than 3 during 24 hours
    lastRechargedMessages: SB.array(SB.int()).default([]),
    firstRecharge: SB.int().default(0),
    firstUnnotifiedFriendJoined: SB.int().optional(),
  }).optional(),

  dailyCode: SB.object({
    timestamp: SB.int(),
    complete: SB.boolean(),
  }),

  banned: SB.object({
    reason: SB.string(),
    timestamp: SB.int(),
  }).optional(),

  softMigration: SB.int().default(CURRENT_SOFT_MIGRATION),
  tokenCreationCredits: SB.int().default(0),

  // use labels to hold any temporary condition checking
  labels: SB.array(SB.string()),

  followersCount: SB.int(),

  followingsCount: SB.int(),

  followingsSubStateId: SB.string().optional(),

  // daily smart contract checkin
  dailyContractCheckin: SB.int().default(0),

  bannedByRegion: SB.string().optional(), // store the region if banned

  isAI: SB.boolean().default(false),

  ai: SB.object({
    apiKey: SB.string(),
    lastRequest: SB.int().optional(),
  }).optional(),
};

const notificationBacklog = SB.object({
  reengagement: SB.object({
    triggerTime: SB.int(),
    payload: SB.object({
      iteration: SB.int(),
    }),
  }).optional(),
  energyRecharged: SB.object({
    triggerTime: SB.int(),
  }).optional(),
  friendJoined: SB.object({
    triggerTime: SB.int(),
    payload: SB.array(
      SB.object({
        friendName: SB.string(),
        bonus: SB.int(),
      }),
    ),
    friendCount: SB.int().optional(),
  }).optional(),
  // @deprecated
  tapBot: SB.object({
    triggerTime: SB.int(),
  }).optional(),
});

export type BotNotifBacklog = SB.ExtractType<typeof notificationBacklog>;

export const botNotifSchema = {
  lastNotifTime: SB.int(),
  lastNotifFeature: SB.string(),
  notificationBacklog,
};

export const botUserSchema = {
  // legacy table 'notgemz-prod-users',
  username: SB.string(),

  is_premium: SB.boolean().default(false),

  referrer_id: SB.string(),

  // version of the bot menu (bottom left in private bot chat)
  bot_menu_version: SB.string(),
};

export const profileSchema = {
  profile: SB.object({
    name: SB.string(),
    photo: SB.string(),
    photoLastUpdated: SB.int(),
    photoLastChecked: SB.int(),
  }),
};

export type PlayerProfile = SB.ExtractType<typeof profileSchema.profile>;

/**
 * Stores data at migration time.
 * Will be undefined if the player has not been migrated.
 */
export const migrationSchema = {
  migration: SB.object({
    score: SB.number(),
    balance: SB.number(),
  }).optional(),
};

const watchedVideoSchema = SB.object({
  id: SB.string(),
  timestamp: SB.int(),
  claimed: SB.boolean(),
});

const questSchema = SB.object({
  state: SB.tuple(['default', 'waiting', 'ready_to_claim', 'complete']).default(
    'default',
  ),
  firstCheckAt: SB.number().optional(),
  promoted: SB.boolean().optional(),
  notified: SB.boolean().optional(),
});

export type Quest = SB.ExtractType<typeof questSchema>;

export const earningsSchema = {
  followOnYoutubeTimestamp: SB.int().default(0),
  earnings: SB.object({
    tutorialRocketQuest: SB.boolean().default(false),
    followOnX: SB.boolean().default(false),
    followOnXJW: SB.boolean().default(false),
    joinCommunity: SB.boolean().default(false),
    inviteFriends2: SB.boolean().default(false),
    inviteFriends5: SB.boolean().default(false),
    inviteFriends10: SB.boolean().default(false),
    inviteFriends100: SB.boolean().default(false),
    walletConnect: SB.boolean().default(false),
    followOnYoutube: SB.boolean().default(false),
    joinAnnouncement: SB.boolean().default(false),
    joinInstagram: SB.boolean().default(false),
    joinTiktok: SB.boolean().default(false),
  }),
  quests: SB.map(questSchema),
  watchedVideos: SB.array(watchedVideoSchema).default([]),
};

/**
 * @deprecated
 */
// @todo: remove power ups from player schema once migration confirmed to work properly
export const powerUpSchema = {
  powerUps: SB.object({
    owned: SB.map(
      SB.object({
        level: SB.int(),
        giftsReceived: SB.int().optional(), // needed for leveling up of free gifts
        lastClaimed: SB.int().optional(),
      }),
    ).default({}),
    daily: SB.object({
      timestamp: SB.int(),
      power_ups: SB.array(SB.string()),
    }).default({ timestamp: 0, power_ups: [] }),
    last_claimed: SB.int(),
    conditions: SB.object({
      // SB.string() = {userId}:{timestamp}:[consumed]
      /**
       * @deprecated Using a type that doesnt comform to what we need to do
       */
      gift_01_airdrop: SB.array(SB.string()).default([]),
      // Record<day, Record<level, userId[]>>;
      gift_02_airdrop: SB.object({
        dailyGifts: SB.array(SB.string()).default([]),
        discountList: SB.array(SB.string()).default([]).maxLength(5),
      }),
      gift_daily_with_discount: SB.map(
        SB.object({
          dailyGifts: SB.array(SB.string()).default([]),
          discountList: SB.array(SB.string()).default([]).maxLength(5),
        }),
      ),
      gift_only: SB.map(
        SB.object({
          dailyGifts: SB.array(SB.string()).default([]),
        }),
      ),
      /**
       * @deprecated to be removed next migration
       * Record<entryPayloadKey, timestamp>;
       */
      consolation_prizes: SB.map(SB.number()),
    }),
    // Record<powerUpLevel, { friendCount: int, level: int }>
    specialsFriendBasis: SB.map(
      SB.object({
        friendCount: SB.int(),
        level: SB.int(),
      }),
    ),
    /**
     * @deprecated use migrationVersion instead
     */
    migrated: SB.boolean().default(false),
    // this is to avoid having multiple flags for different kinds of migrations
    migrationVersion: SB.int().default(0),
    /**
     * @deprecated to be removed next migration
     * Record<cardId, employeeId>
     */
    workers: SB.map(SB.string()),
    /**
     * @deprecated to be removed next migration
     * Record<cardId, employerId>
     */
    jobs: SB.map(SB.string()),
  }),
};

export const BadgeItemSchema = SB.object({
  id: SB.string(),
  startTime: SB.string(),
});

export const badgesSchema = {
  badgeControl: SB.object({
    cards: SB.array(BadgeItemSchema).default([]),
    boosters: SB.array(BadgeItemSchema).default([]),
    earn: SB.array(BadgeItemSchema).default([]),
    friends: SB.array(BadgeItemSchema).default([]),
    lastUpdated: SB.int().default(0),
    // the list of events that will be shown to new user
    // even if they started before the event
    // during his second session
    whitelisted: SB.array(SB.string()).default([]),
  }),
};

const trendSliceSchema = SB.object({
  price: SB.string(),
  time: SB.int(),
});

export type PriceSlice = SB.ExtractType<typeof trendSliceSchema>;

const trendSchema = SB.object({
  hour24: SB.array(trendSliceSchema),
  day7: SB.array(trendSliceSchema),
  day30: SB.array(trendSliceSchema),
  allTime: SB.array(trendSliceSchema),
});

export type PriceTrends = SB.ExtractType<typeof trendSchema>;

const offchainMemeSchema = SB.object({
  pointAmount: SB.string(),
  dailyPoints: SB.map(SB.string()).optional(),
  // this number never goes down
  pointsAccumulated: SB.string().default('0'),
  currencyInvested: SB.string().default('0'),
  // note that even though it's specified as optional, it should always be set from now on
  lastNotifPrice: SB.string().optional(),
  // set if card creator
  productId: SB.string().optional(),

  // daily points to token feature
  claimableDailyTokens: SB.string().optional(),
  convertedDailyPoints: SB.string().optional(),
  dailyPointsClaimOpStartTime: SB.int().optional(),

  // graduation points to token feature
  claimableGradTokens: SB.string().optional(),
  gradPointsClaimOpStartTime: SB.int().optional(),
  gradPointsClaimed: SB.boolean().optional(),
  convertedGradPoints: SB.string().optional(),

  // deprecated,
  lastNotifPriceChange: SB.string().optional(),
  purchasedAt: SB.int().optional(),
  supplyAtPurchase: SB.string().optional(),

  // @todo: delete after migrator 2 is released
  tokenAmount: SB.string().optional(),
});

// @todo: update offchain token schema once all players have migrated
// const offchainMemeSchema = SB.object({
//   pointAmount: SB.string(),
//   dailyPoints: SB.string().optional(),
//   // this number never goes down
//   pointsAccumulated: SB.string().default('0'),
//   currencyInvested: SB.string().default('0'),
//   lastNotifPrice: SB.string(),
//   // set if card creator
//   productId: SB.string().optional(),
//   // token allocation from graduation + daily allocations
//   claimableTokens: SB.string().default('0'),
// });

export type OwnedOffchainMeme = SB.ExtractType<typeof offchainMemeSchema>;

const offchainMemesSchema = SB.map(offchainMemeSchema);

const offchainTokenTapGameSchema = SB.object({
  // Timestamp set when mining starts
  miningStart: SB.int().optional(),
  // Tickets the user has for this token
  tickets: SB.int(),
  // This value is set via message when the user receives their referral tax
  allTimeReferralKickBack: SB.string().default('0'),
  // The sum of the scores (taps) for the server day
  dailyScore: SB.int(),
});

export type OTTG = SB.ExtractType<typeof offchainTokenTapGameSchema>;

const miniGameSchema = SB.object({
  tapping: SB.object({
    // The number of taps a user got during a game session (game session = 1 ticket use) [current game]
    sessionTaps: SB.int().default(0),
    // kickback data
    sessionKickbackTaps: SB.int(),
    sessionTokenId: SB.string().optional(),
    // Global tickets for all tokens
    tickets: SB.int().default(tmgRuleset.tappingMaxTickets),
    // Last timestamp that was used to refresh the tickets
    ticketTimestamp: SB.int().default(tmgRuleset.MEMES_UTC_EPOCH),
  }),
  state: SB.map(offchainTokenTapGameSchema),
});

export type MiniGame = SB.ExtractType<typeof miniGameSchema>;

const walletMemeHoldingsSchema = SB.object({
  jettonContractAddress: SB.string().optional(),
  dexContractAddress: SB.string().optional(),
  jettonTokenAmount: SB.string().optional(),
  dexTokenAmount: SB.string().optional(),
  tokenAmount: SB.string(),
  graduationClaimTime: SB.int().optional(),
});

export type WalletMemeHoldings = SB.ExtractType<
  typeof walletMemeHoldingsSchema
>;

export const unconfirmedTxSchema = SB.object({
  memeId: SB.string(),
  txType: txTypeSchema.optional(),
  txHash: SB.string(),
  createdAt: SB.int(),
  verifDelayMs: SB.int(),
});

export type UnconfirmedTx = SB.ExtractType<typeof unconfirmedTxSchema>;

const walletProfileSchema = SB.object({
  lastTxTimestamp: SB.int(),

  memeHoldings: SB.map(walletMemeHoldingsSchema),

  lastHoldingCheck: SB.int(),

  unconfirmedTxs: SB.array(unconfirmedTxSchema),

  // @todo: remove
  // currencySpent: SB.string().default('0'),
  // currencyRecovered: SB.string().default('0'),
  // portfolioTrends: trendSchema,
});

export type WalletProfile = SB.ExtractType<typeof walletProfileSchema>;

export const tradingSchema = {
  trading: SB.object({
    // @todo: remove hasSeenTokenDisplayChange
    hasSeenTokenDisplayChange: SB.boolean().optional(),
    // Record<CardId, ({level: number; createdAt: number})[]>
    offchainTokens: offchainMemesSchema,
    // @todo: remove offchainTokensLegacy once all players have migrated and no issue was found in migration
    offchainTokensLegacy: offchainMemesSchema.optional(),
    maxSlippage: SB.number().default(txConfig.defaultMaxSlippage.toNumber()),
    miniGames: miniGameSchema,
    referrerTokenId: SB.string().optional(),
    giftTokenId: SB.string().optional(),
    createdMemes: SB.boolean(),
    userMemeGiftsClaimed: SB.map(SB.int()),
    userMemeGiftsSent: SB.map(
      SB.object({
        reward: SB.number(),
        shareTime: SB.int(),
        claimed: SB.boolean(),
      }),
    ),
    lastTokenCreatedTimestamp: SB.int().optional(),
    lastTokenCreatedOSSync: SB.int().optional(),
    ftueShareGatePassed: SB.boolean().optional(),

    onchain: SB.object({
      wallets: SB.map(walletProfileSchema),
    }),

    offchain: SB.object({
      currencySpent: SB.string().default('0'),
      currencyRecovered: SB.string().default('0'),
      // @deprecated
      portfolioTrends: trendSchema.optional(),
    }),

    lastPointConversionDate: SB.int(),
  }),
};

// export type PlayerOffchainToken = SB.ExtractType<typeof tradingSchema>;
